import { SellernoteAppRegion } from "@sellernote/_shared/src/types/common/common";

/**
 * SG쉽다 의뢰의 경우 이메일이 영문으로 발송돼야함.
 * 프론트 값에 의존하기 때문에 각 Bid의 Locale(Region)값에 따라 국문/영문 구분
 */
const LOCALIZED_VESSEL_SCHEDULE_UPDATE_NOTICE: Record<
  string,
  Record<SellernoteAppRegion, string>
> = {
  "수출자 화물준비일정 지연": {
    KR: "수출자 화물준비일정 지연",
    SG: "Delay in seller's cargo preparation schedule",
  },
  "이전 항차로부터 누적된 딜레이로 인한 지연": {
    KR: "이전 항차로부터 누적된 딜레이로 인한 지연",
    SG: "Additional delay due to accumulated delays from the previous voyage",
  },
  "선적지 포트 혼잡으로 인한 출항 지연": {
    KR: "선적지 포트 혼잡으로 인한 출항 지연",
    SG: "Departure delay due to congestion at the loading port/airport",
  },
  "도착지 포트 혼잡으로 인한 입항 지연": {
    KR: "도착지 포트 혼잡으로 인한 입항 지연",
    SG: "Arrival delay due to congestion at the destination port/airport",
  },
  "환적 스케줄 변동": {
    KR: "환적 스케줄 변동",
    SG: "Changes in transshipment schedule",
  },
  "모선명(혹은 항차) 변경": {
    KR: "모선명(혹은 항차) 변경",
    SG: "Vessel(or voyage) change",
  },
};

export default LOCALIZED_VESSEL_SCHEDULE_UPDATE_NOTICE;
