import { useCallback, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import { red } from "@mui/material/colors";

import ADMIN_FILE_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_FILE_QUERY";
import {
  TrelloFileHistory,
  TrelloFileHistoryActionType,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import {
  changeFileAuthorityToKr,
  changeFileDocumentDomainToKr,
} from "@sellernote/_shared/src/utils/forwarding/trello";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";
import Table, {
  TableBodyRow,
  TableHeadCell,
} from "@sellernote/_shared-for-admin/src/components/Table";

type CellId = keyof TrelloFileHistory;

function FileHistoryModal({
  showsFileHistoryModal,
  handleFileHistoryModalClose,
  bidId,
}: {
  showsFileHistoryModal: boolean;
  handleFileHistoryModalClose: () => void;
  bidId: number;
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const { data: historyTableData } = ADMIN_FILE_QUERY.useGetFileHistory({
    bidId,
    page: 0,
    perPage: 100,
  });

  const HistoryActionType = useCallback(
    (actionType: TrelloFileHistoryActionType) => {
      if (actionType === "Delete") {
        return (
          <Typography sx={{ color: red[600] }} variant="body2" component="div">
            삭제
          </Typography>
        );
      }

      if (actionType === "Create") {
        return (
          <Typography variant="body2" component="div">
            생성
          </Typography>
        );
      }

      return (
        <Typography variant="body2" component="div">
          업데이트
        </Typography>
      );
    },
    []
  );

  const headCells: TableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "actionType",
        disablePadding: false,
        label: "활동",
        width: 100,
        verticalAlign: "baseline",
      },
      {
        id: "actorName",
        disablePadding: false,
        label: "변경자",
        width: 150,
        verticalAlign: "baseline",
      },
      {
        id: "domain",
        disablePadding: false,
        label: "구분",
        width: 150,
        verticalAlign: "baseline",
      },
      {
        id: "name",
        disablePadding: false,
        label: "파일 이름",
        width: 350,
        verticalAlign: "baseline",
      },
      {
        id: "createdAt",
        disablePadding: false,
        label: "수정 일시",
        width: 200,
        verticalAlign: "baseline",
      },
    ];
  }, []);

  const rows = useMemo(() => {
    if (!historyTableData?.list) return [];

    return (
      historyTableData?.list
        .map((v) => {
          const row: TableBodyRow<CellId> = {
            actionType: HistoryActionType(v.actionType),
            actorName: `${changeFileAuthorityToKr(v.actorType)} ${v.actorName}`,
            domain: changeFileDocumentDomainToKr(v.domain),
            name: v.name,
            createdAt: toFormattedDate(v.createdAt, "YYYY-MM-DD a hh:mm:ss"),
          };

          return row;
        })
        //TODO: 현재 파일 히스토리 API에서 페이지 필터가 안되는 상태라서 프론트에서 임시로 추가함
        .filter((v, i) => {
          return i >= currentPage * perPage && i <= currentPage * perPage + 9;
        })
    );
  }, [HistoryActionType, currentPage, historyTableData?.list, perPage]);

  return (
    <Modal
      handleClose={handleFileHistoryModalClose}
      isOpened={showsFileHistoryModal}
      modalBody={
        <Table
          title={"파일 히스토리"}
          headCells={headCells}
          rows={rows}
          pagination={{
            totalCount: historyTableData?.total || 0,
            perPage,
            setPerPage,
            currentPage,
            setCurrentPage,
          }}
        />
      }
    />
  );
}

export default FileHistoryModal;
