import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { message } from "antd";
import { Viewer } from "hwp.js";
import jsPDF from "jspdf";
import Tiff from "tiff.js";

import { checkIsOfficeFile } from "@sellernote/_shared/src/utils/common/etc";

export default function useFileUrl(s3Url: string, fileExtension: string) {
  const hwpRenderDivRef = useRef<HTMLDivElement>(null);

  const [fileUrl, setFileUrl] = useState("");

  const showHwpViewer = useCallback((file) => {
    const reader = new FileReader();

    reader.readAsBinaryString(file);

    reader.onloadend = (e) => {
      const fileReaderEventResult =
        e.target === null || e.target === undefined
          ? undefined
          : e.target.result;

      if (fileReaderEventResult) {
        new Viewer(
          hwpRenderDivRef.current as HTMLDivElement,
          fileReaderEventResult as Uint8Array
        );
      } else {
        message.warning("파일 읽기 중 오류가 발생했습니다.");
        return;
      }
    };
  }, []);

  useEffect(() => {
    if (
      fileExtension === "tiff" ||
      fileExtension === "tif" ||
      fileExtension === "TIF"
    ) {
      const xhr = new XMLHttpRequest();
      xhr.responseType = "arraybuffer";
      xhr.open("GET", s3Url);
      xhr.onload = function (e) {
        const doc = new jsPDF("p", "mm");
        const arrayBuffer = this.response;
        Tiff.initialize({
          TOTAL_MEMORY: 16777216 * 10,
        });
        const tiff = new Tiff({
          buffer: arrayBuffer,
        });
        for (let i = 0, length = tiff.countDirectory(); i < length; ++i) {
          tiff.setDirectory(i);
          const canvas = tiff.toCanvas();
          const tiffUrl = canvas.toDataURL();

          doc.addImage(tiffUrl, "PNG", 0, 0, 210, 297);
          if (i + 1 < length) {
            doc.addPage();
          }
        }
        const pdfUrl = doc.output("datauristring");
        setFileUrl(pdfUrl);
      };
      xhr.send();
    } else if (fileExtension === "hwp") {
      const xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.onload = function (event) {
        showHwpViewer(new File([xhr.response], "random"));
      };
      xhr.open("GET", s3Url);
      xhr.send();
    } else if (checkIsOfficeFile(fileExtension)) {
      //기존 Amazon s3Url 입력시 office에서 인식을 못해서 url를 변환
      const encodedUrl = encodeURIComponent(s3Url);
      if (encodedUrl === undefined) {
        return;
      }
      setFileUrl(encodedUrl);
    } else if (fileExtension !== undefined) {
      setFileUrl(s3Url);
    }

    return () => {
      setFileUrl("");
    };
  }, [fileExtension, s3Url, showHwpViewer]);

  const isPdf = useMemo(() => {
    if (
      fileExtension === "pdf" ||
      fileExtension === "PDF" ||
      fileExtension === "tiff" ||
      fileExtension === "tif" ||
      fileExtension === "TIF"
    ) {
      return true;
    } else {
      return false;
    }
  }, [fileExtension]);

  const isHwp = useMemo(() => {
    if (fileExtension === "hwp") {
      return true;
    } else {
      return false;
    }
  }, [fileExtension]);

  return {
    fileUrl,
    isPdf,
    isHwp,
    hwpRenderDivRef,
  };
}
