import { useCallback } from "react";
import { Grid, Typography } from "@mui/material";

import { Currency } from "@sellernote/_shared/src/types/common/common";
import { PartnerBusinessArea } from "@sellernote/_shared/src/types/forwarding/partner";
import { WithdrawalRequestDetail } from "@sellernote/_shared/src/types/forwarding/trello";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";

function PurchasePriceSummary({
  companyType,
  withdrawalData,
  currency,
}: {
  companyType: PartnerBusinessArea;
  withdrawalData: WithdrawalRequestDetail[];
  currency: Currency;
}) {
  const getPrice = useCallback(
    (type: "totalPrice" | "vatPrice" | "finalPrice") => {
      return withdrawalData?.reduce((acc, cur) => {
        acc += cur[type];

        return acc;
      }, 0);
    },
    [withdrawalData]
  );

  const getForeignCurrencyTotalPrice = useCallback(() => {
    return withdrawalData?.reduce((acc, cur) => {
      if (cur.currency === currency) {
        acc += cur.amount * cur.unitPrice;
      }

      return acc;
    }, 0);
  }, [currency, withdrawalData]);

  return (
    <Grid item container>
      {companyType === "foreign" && (
        <Grid
          item
          container
          columns={16}
          alignItems="center"
          sx={{ height: "32px" }}
        >
          <Grid item xs={companyType === "foreign" ? 2 : 7}>
            <Typography variant="body1" component="div">
              합계:
            </Typography>
          </Grid>

          {companyType === "foreign" && (
            <Grid item xs={5}>
              <Typography variant="body1" component="div">
                {currency}
              </Typography>
            </Grid>
          )}

          {companyType !== "foreign" && (
            <Grid item xs={2}>
              <Typography
                variant="body1"
                component="div"
                sx={{ textAlign: "right" }}
              >
                {toThousandUnitFormat(getPrice("totalPrice"))}
              </Typography>
            </Grid>
          )}

          {companyType !== "foreign" && (
            <Grid item xs={2}>
              <Typography
                variant="body1"
                component="div"
                sx={{ textAlign: "right" }}
              >
                {toThousandUnitFormat(getPrice("vatPrice"))}
              </Typography>
            </Grid>
          )}

          <Grid item xs={2}>
            <Typography
              variant="body1"
              component="div"
              sx={{ textAlign: "right" }}
            >
              {toThousandUnitFormat(getForeignCurrencyTotalPrice())}
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid
        item
        container
        columns={16}
        alignItems="center"
        sx={{ height: "32px" }}
      >
        <Grid item xs={companyType === "foreign" ? 2 : 7}>
          <Typography variant="body1" component="div">
            합계:
          </Typography>
        </Grid>

        {companyType === "foreign" && (
          <Grid item xs={5}>
            <Typography variant="body1" component="div">
              KRW
            </Typography>
          </Grid>
        )}

        {companyType !== "foreign" && (
          <Grid item xs={2}>
            <Typography
              variant="body1"
              component="div"
              sx={{ textAlign: "right" }}
            >
              {toThousandUnitFormat(getPrice("totalPrice"))}
            </Typography>
          </Grid>
        )}

        {companyType !== "foreign" && (
          <Grid item xs={2}>
            <Typography
              variant="body1"
              component="div"
              sx={{ textAlign: "right" }}
            >
              {toThousandUnitFormat(getPrice("vatPrice"))}
            </Typography>
          </Grid>
        )}

        <Grid item xs={2}>
          <Typography
            variant="body1"
            component="div"
            sx={{ textAlign: "right" }}
          >
            {toThousandUnitFormat(getPrice("finalPrice"))}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default PurchasePriceSummary;
