import React from "react";
import { Descriptions } from "antd";

import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";

interface Props {
  bidDetail: AdminBidDetail;
}

const WarehouseInfoDesc = ({ bidDetail }: { bidDetail: AdminBidDetail }) => {
  return (
    <Descriptions
      column={3}
      style={{ fontWeight: "bold" }}
      title={<div style={{ fontWeight: "bold", fontSize: 20 }}>창고 정보</div>}
    >
      <Descriptions.Item label="창고명" labelStyle={{ marginTop: 5 }}>
        <div style={{ marginTop: 5 }}>{bidDetail.wareHouse.name}</div>
      </Descriptions.Item>

      <Descriptions.Item label="계산 기준" span={2}>
        1CBM/{bidDetail.wareHouse.cbm}KG
      </Descriptions.Item>

      <Descriptions.Item label="창고 담당자명">
        {bidDetail.wareHouse.managerName}
      </Descriptions.Item>

      <Descriptions.Item label="연락처">
        {bidDetail.wareHouse.managerPhone}
      </Descriptions.Item>

      <Descriptions.Item label="이메일">
        {bidDetail.wareHouse.managerEmail}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default WarehouseInfoDesc;
