import styled, { css } from "styled-components";

import { setFontStyle } from "../../styles/typography";

import { BadgeColorInfo, BorderType } from ".";

const container = styled.div<{
  borderType: BorderType;
  colorInfo: BadgeColorInfo;
}>`
  border-radius: 4px;
  padding: 4px 8px;
  ${setFontStyle("Caption")};

  ${({ borderType, colorInfo }) => {
    if (borderType === "stroked")
      return css`
        border: 1px solid ${colorInfo.border};
        background-color: ${colorInfo.background};
        color: ${colorInfo.text};
      `;

    return css`
      background-color: ${colorInfo.background};
      color: ${colorInfo.text};
    `;
  }}
`;

export default {
  container,
};
