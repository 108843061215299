import { useCallback, useMemo } from "react";

import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { useTranslation } from "@sellernote/_shared-for-shipda/src/utils/i18n";

import { Coordinate, FreightType } from "../../types/common/common";
import { BidPortMap } from "../../types/forwarding/bid";
import { isEmptyObjectOrArray } from "../../utils/common/etc";

import {
  InfoWindow,
  SvgTypeIconInfo,
} from "../../components/googleMap/GoogleMapWithCluster";

import { GET_BID_TRACKING_SHIP_RES } from "../../api-interfaces/shipda-api/bid";
import { APP_CURRENT_LANGUAGE, APP_NAME } from "../../constants";

export default function useShipmentMapMarkerInfoList({
  trackingShipResult,
  freightType,
}: {
  trackingShipResult: GET_BID_TRACKING_SHIP_RES | undefined;
  freightType: FreightType | undefined;
}) {
  const { t } = useTranslation(["bid"]);

  function getBidMapPortCoordinate(port?: BidPortMap): Coordinate | undefined {
    if (!port || !port.lat || !port.lng) {
      return;
    }

    return {
      lat: port.lat,
      lng: port.lng,
      name:
        APP_CURRENT_LANGUAGE === "ko" ? port.name || port.nameEN : port.nameEN,
    };
  }

  /** 현재 운송 진행중 여부 */
  const hasMoving =
    trackingShipResult &&
    Boolean(trackingShipResult.lat) &&
    Boolean(trackingShipResult.lng);

  /** 맵의 센터 포지션 */
  const centerPosition = useMemo(() => {
    if (hasMoving) {
      return { lat: trackingShipResult.lat, lng: trackingShipResult.lng };
    }

    return { lat: 35, lng: 125 };
  }, [hasMoving, trackingShipResult]);

  /** 출발지 위도, 경도를 구하는 함수 */
  const getDeparture = useCallback((): Coordinate | undefined => {
    const targetPort = trackingShipResult?.startPort;

    if (!targetPort) return undefined;

    return getBidMapPortCoordinate(targetPort);
  }, [trackingShipResult?.startPort]);

  /** 도착지 위도, 경도를 구하는 함수 */
  const getDestination = useCallback((): Coordinate | undefined => {
    const targetPort = trackingShipResult?.endPort;

    if (!targetPort) return undefined;

    return getBidMapPortCoordinate(targetPort);
  }, [trackingShipResult?.endPort]);

  /** 경유지를 구하는 함수 */
  const getStopovers = useCallback((): Coordinate[] | undefined => {
    if (!trackingShipResult) {
      return;
    }

    if (isEmptyObjectOrArray(trackingShipResult)) {
      return;
    }

    return [
      {
        lat: trackingShipResult.lat,
        lng: trackingShipResult.lng,
      },
    ];
  }, [trackingShipResult]);

  /** 현재 이동중인 화물 위도, 경도 */
  const shipPosition = useMemo((): Coordinate | undefined => {
    const stopovers = getStopovers();
    if (!stopovers?.length) {
      return getDeparture();
    }

    return stopovers[0];
  }, [getDeparture, getStopovers]);

  const getMarkerInfoListTitleImg = useCallback(() => {
    const isShipda = APP_NAME === "shipda-kr" || APP_NAME === "shipda-sg";

    const planeIconUrl = isShipda
      ? "/assets/images/mypage/bid/marker-info-icon-plane.svg"
      : "/images/trello/marker-info-icon-plane.svg";

    const shipIconUrl = isShipda
      ? "/assets/images/mypage/bid/marker-info-icon-ship.svg"
      : "/images/trello/marker-info-icon-ship.svg";

    if (freightType === "AIR") {
      return `<img src=${planeIconUrl} alt="marker-info-plane">`;
    }
    return `<img src=${shipIconUrl} alt="marker-info-ship"/>`;
  }, [freightType]);

  const markerInfoList = useMemo(() => {
    if (!trackingShipResult) return;

    const departure = getDeparture();
    const destination = getDestination();

    const departureContent = `<div class="departure">
      <div class="title">
       ${getMarkerInfoListTitleImg()}

        <div> ${t("bid:운송관리_공통_TRACKING_MAP_출발지")} </div>
      </div>
        
      <div class="port">${departure?.name}</div>
      </div>`;

    const destinationContent = `<div class="departure">
      <div class="title">
       ${getMarkerInfoListTitleImg()}

        <div> ${t("bid:운송관리_공통_TRACKING_MAP_도착지")} </div>
      </div>
        
      <div class="port">${destination?.name}</div>
      </div>`;

    return [
      ...(departure
        ? [
            {
              ...departure,
              iconInfo: {
                type: "svg",
                path: "M8,4C8,6.20937,6.20937,8,4,8C1.79063,8,0,6.20937,0,4C0,1.79063,1.79063,0,4,0C6.20937,0,8,1.79063,8,4Z",
                strokeColor: COLOR.primary_600,
                strokeWeight: 8,
                scale: 0.03,
              } as SvgTypeIconInfo,
              infoWindowData: {
                content: departureContent,
                type: "visible",
              } as InfoWindow,
              title: "departure",
            },
          ]
        : []),

      ...(shipPosition?.lat && shipPosition?.lng
        ? [
            {
              ...shipPosition,
              iconInfo: {
                type: "svg",
                path: "M0.342425 17.6052L6 0.632456L11.6576 17.6052L6.08944 14.8211L6 14.7764L5.91056 14.8211L0.342425 17.6052Z",
                strokeColor: COLOR.grayScale_700,
                strokeWeight: 0.4,
                fillOpacity: 1.0,
                fillColor: COLOR.error_400,
                rotation: trackingShipResult.heading,
              } as SvgTypeIconInfo,
              title: "shipPosition",
            },
          ]
        : []),

      ...(destination
        ? [
            {
              ...destination,
              iconInfo: {
                type: "svg",
                path: "M8,4C8,6.20937,6.20937,8,4,8C1.79063,8,0,6.20937,0,4C0,1.79063,1.79063,0,4,0C6.20937,0,8,1.79063,8,4Z",
                strokeColor: COLOR.primary_600,
                strokeWeight: 8,
                scale: 0.03,
              } as SvgTypeIconInfo,
              infoWindowData: {
                content: destinationContent,
                type: "visible",
              } as InfoWindow,
              title: "destination",
            },
          ]
        : []),
    ];
  }, [
    getDeparture,
    getDestination,
    getMarkerInfoListTitleImg,
    shipPosition,
    t,
    trackingShipResult,
  ]);

  return {
    centerPosition,
    markerInfoList,
  };
}
