import { Grid, Typography } from "@mui/material";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import { AdminBidList } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { CustomsAdminBidList } from "@sellernote/_shared/src/types/forwarding/customsAdminBid";

function BidApplyInfoItemList(
  adminBidListData: AdminBidList | CustomsAdminBidList
) {
  const adminData = adminBidListData as AdminBidList;

  return (
    <Grid container alignItems={"center"} justifyContent={"center"}>
      <Grid item>
        <Typography>
          운임보유: {adminBidListData.isFixed ? "O" : "X"}/ 오션티켓:{" "}
          {adminBidListData.serviceType === "oceanTicket" ? "O" : "X"}
        </Typography>
      </Grid>

      <Grid item>
        {APP_NAME === "shipda-admin" && (
          <Typography>
            견적 지원 수: {adminData.quotationForwarders.length}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
export default BidApplyInfoItemList;
