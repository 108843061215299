import React from "react";

import Layout from "@sellernote/_shared-for-forwarding-admin/src/containers/Layout";
import withRequireAuth from "@sellernote/_shared-for-forwarding-admin/src/hocs/withRequireAuth";

function Home() {
  // const managerWorkerList = useRecoilValue(
  //   FULFILLMENT_COMMON_SELECTORS.MANAGER_WORKER_LIST
  // );

  // 토큰이 유효한지 확인하기위해 테스트로 인증이 필요한 아무 API나 호출해 봄
  // const { ResponseHandler: ResponseHandlerOfGetUserList } =
  //   USER_QUERY.useGetCustomerList();

  return (
    <Layout>
      <div>관세사 admin 메인페이지</div>

      {/* {managerWorkerList.map((v, i) => (
        <div key={i}>{v.name}</div>
      ))}

      {ResponseHandlerOfGetUserList} */}
    </Layout>
  );
}

export default withRequireAuth(Home);
