import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Grid,
  IconButton,
  Modal as MUIModal,
  SxProps,
  Theme,
} from "@mui/material";

const modalBoxStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //   width: 400,
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "4px",
  p: 4,
  maxHeight: "800px",
};

export default function Modal({
  isOpened,
  handleClose,
  modalBody,
  sx,
  disabledBackdropClick,
  className,
  showsCloseIcon,
}: {
  isOpened: boolean;
  handleClose: () => void;
  modalBody: React.ReactNode;
  sx?: SxProps<Theme>;
  disabledBackdropClick?: boolean;
  className?: string;
  showsCloseIcon?: boolean;
}) {
  return (
    <MUIModal
      open={isOpened}
      onClose={(e, reason) => {
        if (disabledBackdropClick && reason === "backdropClick") {
          return;
        }

        handleClose();
      }}
      className={className ? `modal ${className}` : ""}
      sx={sx}
      onBackdropClick={(e) => e.stopPropagation()}
    >
      <Box sx={modalBoxStyle} style={{ overflowY: "scroll" }}>
        {showsCloseIcon && (
          <Grid container justifyContent="flex-end">
            <IconButton onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Grid>
        )}

        {modalBody}
      </Box>
    </MUIModal>
  );
}
