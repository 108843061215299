import styled, { css } from "styled-components";

import { COLOR, TEXT_COLOR } from "../../../styles/colors";
import { setFontStyle } from "../../../styles/typography";

import { TableCellHorizontalAlign, TableCellVerticalAlign } from ".";

function setCellHorizontalAlign(horizontalAlign?: TableCellHorizontalAlign) {
  switch (horizontalAlign) {
    case "center": {
      return css`
        text-align: center;
        justify-content: center;
      `;
    }
    case "right": {
      return css`
        justify-content: flex-end;
        text-align: right;
      `;
    }
    default: {
      // left가 default
      return css`
        text-align: left;
        justify-content: flex-start;
      `;
    }
  }
}

function setCellVerticalAlign(verticalAlign?: TableCellVerticalAlign) {
  switch (verticalAlign) {
    case "center": {
      return css`
        text-align: center;
        align-items: center;
      `;
    }
    case "bottom": {
      return css`
        align-items: flex-end;
      `;
    }
    default: {
      // top이 default
      return css`
        align-items: flex-start;
      `;
    }
  }
}

const container = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
`;

const table = styled.table`
  position: relative;
  width: 100%;
`;

const tableHeader = styled.thead<{
  isStickyHeader?: boolean;
}>`
  position: ${({ isStickyHeader }) => (isStickyHeader ? "sticky" : "static")};
  z-index: 8;
  top: 0;
  left: 0;
`;

const tableBody = styled.tbody``;

const tableRow = styled.tr<{
  cellGridSizeList: { width?: string; ratio?: number; minWidth?: string }[];
  hasClickAction: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ cellGridSizeList }) =>
    cellGridSizeList
      ?.map((cellGridSize) => {
        if (cellGridSize.width) return cellGridSize.width;

        if (cellGridSize.minWidth)
          return `minmax(${cellGridSize.minWidth}, ${
            cellGridSize.ratio || 1
          }fr)`;

        if (cellGridSize.ratio) return `${cellGridSize.ratio}fr`;

        return "1fr";
      })
      .join(" ")};
  justify-items: center;

  background-color: ${COLOR.wh};

  // onRowClick에 함수를 전달한 경우에만 Hover 스타일 적용
  ${({ hasClickAction }) =>
    hasClickAction &&
    css`
      &:hover {
        background-color: ${COLOR.grayScale_50};
      }
    `}
`;

const tableHeaderCell = styled.th<{
  horizontalAlign?: TableCellHorizontalAlign;
  isSubPanelTable?: boolean;
}>`
  width: 100%;
  ${setFontStyle("SubHead2")};
  padding: 16px 8px 15px;
  color: ${TEXT_COLOR.black_2};
  background-color: ${COLOR.grayScale_300};
  border-bottom: 1px solid ${COLOR.grayScale_400};
  display: flex;

  ${({ horizontalAlign }) => setCellHorizontalAlign(horizontalAlign)}

  > .table-header-filter, .table-header-sort {
    margin-left: 4px;
  }

  > .table-header-tooltip {
    margin-left: 4px;
  }

  ${({ isSubPanelTable }) => {
    if (isSubPanelTable) {
      return css`
        width: 44px;
        padding: 13px 12px;
      `;
    }
  }};
`;

const tableDataCell = styled.td<{
  horizontalAlign?: TableCellHorizontalAlign;
  verticalAlign?: TableCellVerticalAlign;
  isSelected?: boolean;
  isDisabled?: boolean;
  isFirstAndSelectionCell: boolean;
  hasClickAction: boolean;
  rowBackgroundColor?: string;
  isSelectableTable?: boolean;
  isSubPanelToggle?: boolean;
  isSubPanelCell?: boolean;
  /** subPanel은 전체 행을 하나로 합쳐야 함 */
  totalColumnCount?: number;
}>`
  width: 100%;
  padding: 16px 8px 15px;
  ${setFontStyle("Body3")};
  color: ${TEXT_COLOR.black_1};
  border-bottom: 1px solid ${COLOR.grayScale_300};
  display: flex;

  ${({ hasClickAction, isDisabled }) => {
    if (hasClickAction && !isDisabled) {
      return css`
        cursor: pointer;
      `;
    }
  }}

  ${({ horizontalAlign }) => setCellHorizontalAlign(horizontalAlign)}

  ${({ verticalAlign }) => setCellVerticalAlign(verticalAlign)}

  ${({ rowBackgroundColor }) => {
    if (rowBackgroundColor) {
      return css`
        background-color: ${rowBackgroundColor};
      `;
    }
  }}

  ${({ isSelected, isFirstAndSelectionCell }) => {
    if (isFirstAndSelectionCell) {
      return isSelected
        ? css`
            background-color: ${COLOR.grayScale_100};
            box-shadow: inset 2px 0 0 0 ${COLOR.success_400};
          `
        : css`
            box-shadow: inset 2px 0 0 0 transparent;
          `;
    }

    if (isSelected) {
      return css`
        background-color: ${COLOR.grayScale_100};
      `;
    }
  }}

  ${({ totalColumnCount }) => {
    if (totalColumnCount) {
      return css`
        grid-column: 1 / ${totalColumnCount + 2};
      `;
    }
  }}

  ${({ isSubPanelToggle }) => {
    if (isSubPanelToggle) {
      return css`
        width: 44px;
        padding: 15px 12px 15px;
        cursor: pointer;
      `;
    }
  }};

  ${({ isSubPanelCell, isSelectableTable }) => {
    if (isSelectableTable && isSubPanelCell) {
      return css`
        padding: 16px 12px 15px 98px;
        background-color: ${COLOR.grayScale_100};
      `;
    }

    if (isSubPanelCell) {
      return css`
        padding: 16px 12px 15px 56px;
        background-color: ${COLOR.grayScale_100};
      `;
    }
  }};

  ${({ isDisabled, isSubPanelToggle }) => {
    if (isDisabled && !isSubPanelToggle) {
      return css`
        cursor: not-allowed;
        background-color: ${COLOR.wh};
        color: ${TEXT_COLOR.black_disabled};
      `;
    }
  }};
`;

const noData = styled.div`
  text-align: center;
  padding: 80px 0;
  ${setFontStyle("Body2")};
  color: ${TEXT_COLOR.black_3};
`;

export default {
  container,
  table,
  tableHeader,
  tableBody,
  tableRow,
  tableHeaderCell,
  tableDataCell,
  noData,
};
