import path from "path";

import NextI18Next from "next-i18next";

import { APP_REGION } from "@sellernote/_shared/src/constants";
import { ShipDaFeature } from "@sellernote/_shared/src/types/common/common";

const NextI18NextInstance = new NextI18Next({
  defaultLanguage: APP_REGION === "KR" ? "ko-KR" : "en-SG",
  otherLanguages: APP_REGION === "KR" ? ["en-SG"] : ["ko-KR"],

  localePath: path.resolve("../_shared/src/i18n/locales"),
  serverLanguageDetection: false,
  browserLanguageDetection: false, // TODO: i18n작업끝나고 true로 바꿀것
});

const { withTranslation, i18n, appWithTranslation, useTranslation, Trans } =
  NextI18NextInstance;

/**
 * 해당 국가가 현지국가인지 확인.
 * 국내인지 해외인지 구분이 필요할때 주로 사용한다.
 * @param countryCode 국가별코드 (ISO 3166-1 alpha-2 기준)
 */
const isLocalCountry = (countryCode?: string) => {
  if (!countryCode) {
    return false;
  }

  return countryCode === APP_REGION;
};

/**
 * 해당 서비스를 지원하는지 여부를 체크
 * @param feature
 */
const isAvailableFeature = (feature: ShipDaFeature): boolean => {
  const locale = APP_REGION;
  if (!feature || !locale) {
    return false;
  }

  switch (feature) {
    // 실시간 견적조회 > 내륙운송타입 선택 기능
    case "realtimeInlandCostOption": {
      if (locale === "KR") {
        return true;
      } else {
        return false;
      }
    }
    // 오션티켓 > 내륙운송타입 선택 기능
    case "oceanTicketInlandCostOption": {
      if (locale === "KR") {
        return true;
      } else {
        return false;
      }
    }

    case "consolidation": {
      if (locale === "KR") {
        return true;
      } else {
        return false;
      }
    }
    case "fba": {
      if (locale === "KR") {
        return true;
      } else {
        return false;
      }
    }
    case "guideBook": {
      if (locale === "KR") {
        return true;
      } else {
        return false;
      }
    }
    case "fulfillment": {
      if (locale === "KR") {
        return true;
      } else {
        return false;
      }
    }
    case "po": {
      if (locale === "KR") {
        return true;
      } else {
        return false;
      }
    }
    case "promotion": {
      if (locale === "KR") {
        return true;
      } else {
        return false;
      }
    }
    case "support": {
      if (locale === "KR") {
        return true;
      } else {
        return false;
      }
    }
    case "coupon": {
      if (locale === "KR") {
        return true;
      } else {
        return false;
      }
    }
    // resource 는 브링굿즈만
    case "resources": {
      if (locale !== "KR") {
        return true;
      } else {
        return false;
      }
    }
    default: {
      return false;
    }
  }
};

export {
  withTranslation,
  i18n,
  appWithTranslation,
  useTranslation,
  Trans,
  isLocalCountry,
  isAvailableFeature,
};
