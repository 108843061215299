import { GET_ADMIN_SHIPMENT_LIST_FILTER } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBid";

import { atomWithReset } from "jotai/utils";

const SHIPMENT_TABLE_FILTER_LIST =
  atomWithReset<GET_ADMIN_SHIPMENT_LIST_FILTER>({
    page: 0,
    perPage: 20,
    serviceType: undefined,
    status: undefined,
    projectStatus: undefined,
  });

export default {
  SHIPMENT_TABLE_FILTER_LIST,
};
