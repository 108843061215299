import { useCallback, useMemo, useState } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";

import { useDebounce } from "@sellernote/_shared/src/utils/common/hook";

import Styled from "./index.styles";

export interface TermSearchType<T extends string> {
  label: string;
  value: T;
  placeHolder?: string;
}

export default function useSearchWithTerm<T extends string>({
  termSearchTypeOptions,
}: {
  termSearchTypeOptions: TermSearchType<T>[];
}) {
  const [termSearchType, setTermSearchType] = useState<
    TermSearchType<T> | undefined
  >(termSearchTypeOptions[0]);

  const [searchTerm, setSearchTerm] = useState("");

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleTermSearchTypeChange = useCallback(
    (event: SelectChangeEvent) => {
      const target = termSearchTypeOptions.find(
        (st) => st.value === event.target.value
      );

      if (target) setTermSearchType(target);
    },
    [termSearchTypeOptions]
  );

  const hasValue = useMemo(() => {
    return !!searchTerm;
  }, [searchTerm]);

  const reset = useCallback(
    (e) => {
      e.stopPropagation();
      setSearchTerm("");
      setTermSearchType(termSearchTypeOptions[0]);
    },
    [termSearchTypeOptions]
  );

  const handleSearchTermChange = useCallback(
    (e) => setSearchTerm(e.target.value),
    []
  );

  const TermSearchPanel = useMemo(() => {
    return (
      <Styled.searchPanelWrapper>
        <Styled.searchPanel>
          <Styled.search>
            <FormControl className="search-type" variant="outlined">
              <InputLabel id="search-term-select-label">검색 대상</InputLabel>

              <Select
                labelId="search-term-select-label"
                id="search-term-select"
                value={termSearchType?.value}
                label="검색 대상"
                onChange={handleTermSearchTypeChange}
                size="small"
              >
                {termSearchTypeOptions.map((st, i) => (
                  <MenuItem value={st.value} key={i}>
                    {st.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label={termSearchType?.placeHolder || "검색어"}
              size="small"
              value={searchTerm}
              onChange={handleSearchTermChange}
            />

            {hasValue && (
              <Button
                variant="contained"
                sx={{
                  cursor: "pointer",
                  height: "40px",
                }}
                onClick={reset}
              >
                초기화
              </Button>
            )}
          </Styled.search>
        </Styled.searchPanel>
      </Styled.searchPanelWrapper>
    );
  }, [
    termSearchType?.value,
    termSearchType?.placeHolder,
    handleTermSearchTypeChange,
    termSearchTypeOptions,
    searchTerm,
    hasValue,
    handleSearchTermChange,
    reset,
  ]);

  return {
    TermSearchPanel,
    debouncedSearchTerm,
    termSearchType,
    reset,
  };
}
