import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";
import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";

export type AutoCompleteWithReactHookFormProps<
  T extends FieldValues,
  K extends string | { label: string; value: string | number }
> = Omit<
  AutocompleteProps<
    K,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  >,
  // renderInput을 받는 것보다 안에서 사용하는게 더 좋을 것 같다고 판단 사용 후 다른 의견 있을 시 수정
  "renderInput"
> & {
  label?: string;
  name: Path<T>;
  control?: Control<T>;
  options: K[];
  defaultValue?: PathValue<
    T,
    (string | { label: string; value: string | number } | undefined) & Path<T>
  >;
  required?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  handleEffectOnChange?: (
    data: K | NonNullable<string | K> | (string | K)[] | null
  ) => void;
};

export default function AutoCompleteWithReactHookForm<
  TFieldValues extends FieldValues,
  K extends string | { label: string; value: string | number }
>({
  label,
  name,
  options,
  control,
  defaultValue,
  errorMessage,
  disabled,
  required,
  handleEffectOnChange,
  ...props
}: AutoCompleteWithReactHookFormProps<TFieldValues, K>) {
  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={{
          required,
        }}
        render={({ field, fieldState: { error } }) => {
          return (
            <Autocomplete
              {...props}
              {...field}
              disabled={disabled}
              value={props.value ? props.value : field.value ?? null}
              onChange={(_, data) => {
                field.onChange(data);
                handleEffectOnChange && handleEffectOnChange(data);
              }}
              getOptionLabel={(option) => {
                return typeof option === "string" ? option : option.label;
              }}
              options={options}
              isOptionEqualToValue={(option, value) => {
                if (typeof option === "object" && typeof value === "object") {
                  return option.value === value.value;
                }

                return option === value;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={label}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    handleEffectOnChange &&
                      handleEffectOnChange(e.target.value);
                  }}
                  error={error !== undefined}
                  helperText={
                    error &&
                    (error.type === "required"
                      ? "필수 입력 사항입니다."
                      : errorMessage)
                  }
                />
              )}
            />
          );
        }}
      />
    </>
  );
}
