import React, { useCallback, useMemo } from "react";
import { Table } from "antd";

import {
  BidConsolidationSeller,
  BidConsolidationSellerItem,
} from "@sellernote/_shared/src/types/forwarding/bid";
import { ConsolidationOrderFormSellerCargoItem } from "@sellernote/_shared/src/types/forwarding/consolidation";
import { calculateConsolidationCbm } from "@sellernote/_shared/src/utils/forwarding/consolidation";

const ConsolidationItem = ({
  sellerData,
  bidId,
}: {
  sellerData: BidConsolidationSeller[];
  bidId: number;
}) => {
  const expandedRowRender = useCallback(
    (consolidationItemInfo: BidConsolidationSeller, index: number) => {
      const itemData = sellerData[index].consolidationItems;

      const columns = [
        {
          title: "ITEM",
          key: "name",
          dataIndex: "name",
          width: 100,
        },
        {
          title: "W",
          key: "horizontal",
          dataIndex: ["details", "horizontal"],
          width: 80,
          render: (horizontal: number) => {
            return horizontal;
          },
        },
        {
          title: "L",
          key: "vertical",
          dataIndex: ["details", "vertical"],
          width: 80,
          render: (vertical: number) => {
            return vertical;
          },
        },
        {
          title: "H",
          key: "height",
          dataIndex: ["details", "height"],
          width: 80,
          render: (height: number) => {
            return height;
          },
        },
        {
          title: "UNIT",
          key: "sizeUnit",
          dataIndex: ["details", "sizeUnit"],
          width: 100,
          render: (sizeUnit: number) => {
            return sizeUnit;
          },
        },
        {
          title: "WEIGHT",
          key: "weight",
          dataIndex: ["details", "weight"],
          width: 100,
          render: (weight: number) => {
            return weight;
          },
        },
        {
          title: "NUMBER OF BOXES",
          key: "quantity",
          dataIndex: ["details", "quantity"],
          width: 80,
        },
        {
          title: "CBM",
          key: "cbm",
          dataIndex: "details",
          width: 80,
          render: (details: ConsolidationOrderFormSellerCargoItem) => {
            return calculateConsolidationCbm(details);
          },
        },
        {
          title: "PACKING",
          key: "cbm",
          dataIndex: ["details", "packagingType"],
          width: 100,
        },
      ];

      return (
        <Table
          bordered
          rowKey={(record) => record.id}
          dataSource={itemData}
          columns={columns}
          pagination={false}
        />
      );
    },
    [sellerData]
  );

  const columns = useMemo(() => {
    return [
      {
        title: "SHIPPER",
        key: "shipperNumber",
        render: (
          text: BidConsolidationSeller,
          tableRecord: BidConsolidationSeller,
          index: number
        ) => {
          return `SHIPPER${index + 1}`;
        },
      },
      {
        title: "COMPANY NAME",
        dataIndex: ["exporterInfo", "companyName"],
        key: "companyName",
        render: (companyName: string) => {
          return companyName;
        },
      },
      {
        title: "NAME",
        dataIndex: ["exporterInfo", "personName"],
        key: "personName",
        render: (personName: string) => {
          return personName;
        },
      },
      {
        title: "EMAIL",
        dataIndex: ["exporterInfo", "personEmail"],
        key: "personEmail",
        render: (personEmail: string) => {
          return personEmail;
        },
      },
      {
        title: "TEL",
        dataIndex: ["exporterInfo", "personPhone"],
        key: "personPhone",
        render: (personPhone: string) => {
          return personPhone;
        },
      },
      {
        title: "ADDRESS",
        dataIndex: ["consolidationItems"],
        key: "address",
        render: (text: BidConsolidationSellerItem[]) => {
          return text[0].address;
        },
      },
    ];
  }, []);

  return (
    <Table
      columns={columns}
      defaultExpandAllRows={true}
      rowKey={(record: BidConsolidationSeller) => record.exporterInfo.id}
      expandable={{ expandedRowRender }}
      dataSource={sellerData}
      scroll={{ x: "max-content" }}
      pagination={false}
    />
  );
};

export default ConsolidationItem;
