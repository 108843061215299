import { useMemo } from "react";
import StarIcon from "@mui/icons-material/Star";
import { yellow } from "@mui/material/colors";

import { AdminBidTemplate } from "@sellernote/_shared/src/types/forwarding/adminTemplate";
import { TableHeadCell } from "@sellernote/_shared-for-admin/src/components/Table";

type CellId = keyof AdminBidTemplate | "delete";

export default function useTemplateTableHeadCells({
  FreightTypeFilterPanel,
  IncotermsFilterPanel,
}: {
  FreightTypeFilterPanel: JSX.Element;
  IncotermsFilterPanel: JSX.Element;
}) {
  const withdrawalHeadCells: TableHeadCell<CellId>[] = useMemo(() => {
    return [
      {
        id: "isFavorite",
        disablePadding: false,
        width: 80,
        label: <StarIcon sx={{ color: yellow[700] }} />,
      },
      {
        id: "name",
        disablePadding: false,
        label: "템플릿명",
        width: 180,
      },
      {
        id: "category",
        disablePadding: false,
        label: "구분",
        width: 120,
      },
      {
        id: "country",
        disablePadding: false,
        label: "국가",
        width: 80,
      },
      {
        id: "freightType",
        disablePadding: false,
        label: "타입",
        width: 80,
        filter: FreightTypeFilterPanel,
      },
      {
        id: "incoterms",
        disablePadding: false,
        label: "텀",
        width: 80,
        filter: IncotermsFilterPanel,
      },
      {
        id: "startPortId",
        disablePadding: false,
        width: 100,
        label: "POL",
      },
      {
        id: "endPortId",
        disablePadding: false,
        width: 100,
        label: "POD",
      },
      {
        id: "destination",
        disablePadding: false,
        width: 150,
        label: "dest.",
      },
      {
        id: "partnerName",
        disablePadding: false,
        label: "파트너명",
        width: 100,
      },
      {
        id: "companyName",
        disablePadding: false,
        label: "회사명",
        width: 100,
      },
      {
        id: "volume",
        disablePadding: false,
        label: "볼륨",
        width: 80,
      },
      {
        id: "administratorName",
        disablePadding: false,
        label: "등록자",
        width: 100,
      },
      {
        id: "delete",
        disablePadding: false,
        label: "삭제",
        width: 80,
      },
    ];
  }, [FreightTypeFilterPanel, IncotermsFilterPanel]);

  const applyHeadCells = useMemo(() => {
    return withdrawalHeadCells.filter((v) => {
      return v.id !== "partnerName";
    });
  }, [withdrawalHeadCells]);

  return {
    applyHeadCells,
    withdrawalHeadCells,
  };
}
