import GoogleMapWithCluster from "@sellernote/_shared/src/components/googleMap/GoogleMapWithCluster";
import useShipmentMapMarkerInfoList from "@sellernote/_shared/src/hooks/forwarding/useShipmentMapMarkerInfoList";
import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { FreightType } from "@sellernote/_shared/src/types/common/common";

import Styled from "./index.styles";

export default function Map({
  trelloId,
  freightType,
}: {
  trelloId: number;
  freightType: FreightType;
}) {
  const { data: trackingShipResult } =
    TRELLO_BID_QUERY.useGetTrelloTrackingShip({
      trelloId,
      enabled: Boolean(trelloId),
    });

  const { centerPosition, markerInfoList } = useShipmentMapMarkerInfoList({
    trackingShipResult,
    freightType,
  });

  return (
    <Styled.container>
      <GoogleMapWithCluster
        paddingTop={"400px"}
        zoom={5}
        centerPosition={centerPosition}
        markerInfoList={markerInfoList}
        isAutoZoom={true}
      />
    </Styled.container>
  );
}
