import styled from "styled-components";

const search = styled.div`
  position: relative;
  display: flex;
  gap: 4px;
`;

const searchPanelWrapper = styled.div``;

const searchPanel = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;

  > div {
    &:not(:last-child) {
      margin-right: 4px;
    }
  }
`;

export default {
  search,
  searchPanel,
  searchPanelWrapper,
};
