import { Cluster } from "@googlemaps/markerclusterer";
import { Wrapper } from "@googlemaps/react-wrapper";

import { GOOGLE_MAP_COMMON_BOOT_OPTION } from "@sellernote/_shared/src/services/googleMap";

import Map from "./Map";

export interface Position {
  lat: number;
  lng: number;
}

export interface InfoWindow {
  content: string; // mark up 요소를 string 으로 변환하여 사용.
  type: "click" | "visible";
}

export type ImageTypeIconInfo = {
  type: "image";
  url: string;
  width: number;
  height: number;
};

export type SvgTypeIconInfo = {
  type: "svg";
  path: string;
  /** 아이콘을 채우는 색 */
  fillColor: string;
  /** 채우기 색 투명도 */
  fillOpacity: number;
  /** 선 굵기 */
  strokeWeight: number;
  /** 선 컬러 */
  strokeColor: string;
  scale: number;
  /** 아이콘 회전 각도 */
  rotation: number;
};

export interface MarkerInfo extends Position {
  label?: string;
  infoWindowData?: InfoWindow;
  iconInfo?: ImageTypeIconInfo | SvgTypeIconInfo;
  zIndex?: number;
  pixelOffset?: google.maps.Size;
}

export interface MarkerClustererInfo {
  label: google.maps.ReadonlyMarkerLabel;
}

export default function GoogleMapWithCluster({
  height,
  paddingTop, // 지도를 비율에 맞춰 세로사이즈를 조정할 경우에 사용. height 값은 비우고 paddingTop 에만 퍼센테이지 값 string으로 전달. ex) "60%"
  borderRadius,
  zoom,
  centerPosition,
  markerInfoList,
  polylineInfo,
  setMarkerCluster,
  onGoogleApiLoaded,
  isAutoZoom,
}: {
  height?: string;
  paddingTop?: string;
  borderRadius?: string;
  zoom?: number;
  centerPosition: Position;
  markerInfoList?: MarkerInfo[];
  polylineInfo?: google.maps.PolylineOptions;
  setMarkerCluster?: (cluster: Cluster) => MarkerClustererInfo;
  onGoogleApiLoaded?: (maps: { map: any }) => void;
  isAutoZoom?: boolean;
}) {
  return (
    <Wrapper {...GOOGLE_MAP_COMMON_BOOT_OPTION}>
      <Map
        center={centerPosition}
        zoom={zoom}
        streetViewControl={false}
        fullscreenControl={false}
        mapTypeControl={false}
        style={{
          flexGrow: "1",
          height: height ?? "auto",
          paddingTop: paddingTop ?? "0",
          borderRadius: borderRadius ?? "0",
        }}
        markerInfoList={markerInfoList}
        polylineInfo={polylineInfo}
        setMarkerCluster={setMarkerCluster}
        onGoogleApiLoaded={onGoogleApiLoaded}
        isAutoZoom={isAutoZoom}
      />
    </Wrapper>
  );
}
