import React, { useCallback, useEffect } from "react";
import Router from "next/router";

import Styled from "./index.styles";

export interface Tab {
  title: string;
  desc?: React.ReactNode;
  url?: string;
  /**
   * 복수 url이 하나 Tab으로 대응되야될 case가 있을때 사용
   */
  subUrl?: string;
}

interface Props {
  selectedTabIndex: number;
  setSelectedTabIndex: (name: number) => void;
  tabList: Tab[];
  status?: string | string[] | undefined;
  asPath?: string;
  className?: string;
}

export default function TabLine({
  selectedTabIndex,
  setSelectedTabIndex,
  tabList,
  status,
  asPath,
  className,
}: Props) {
  const selectTab = useCallback(
    (i: number, url?: string) => {
      setSelectedTabIndex(i);

      if (url) Router.push(url);
    },
    [setSelectedTabIndex]
  );

  useEffect(() => {
    if (status) {
      selectTab(
        tabList.findIndex((tab) => tab.url === asPath || tab.subUrl === asPath)
      );
    }
  }, [asPath, selectTab, status, tabList]);

  return (
    <Styled.wrapper className={`${className || ""} tab-line`}>
      <Styled.tabList>
        {tabList.map((tab, i) => {
          const TabItem = (
            <li
              id={`tab${i}`}
              onClick={() => selectTab(i, tab.url)}
              className={i === selectedTabIndex ? "active" : ""}
            >
              {tab.title}
            </li>
          );

          return <React.Fragment key={i}>{TabItem}</React.Fragment>;
        })}
      </Styled.tabList>
    </Styled.wrapper>
  );
}
