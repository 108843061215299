import { Component, ErrorInfo, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { IS_UNDER_LOCAL_DEVELOPMENT } from "@sellernote/_shared/src/constants";

import ServerError from "../ServerError";

type Props = {
  children: ReactNode;
} & RouteComponentProps & {
    sentry?: any;
  };

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  state = { hasError: false };

  static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);

    if (this.props.sentry && !IS_UNDER_LOCAL_DEVELOPMENT) {
      this.props.sentry.captureException(error, { extra: errorInfo });
    }
  }

  resetErrorBoundary = () => {
    this.setState({ hasError: false });
  };

  goBackToMainPage = () => {
    this.props.history.push("/");
    this.resetErrorBoundary();
  };

  render() {
    return this.state.hasError ? (
      <ServerError goBackToMainPage={this.goBackToMainPage} />
    ) : (
      this.props.children
    );
  }
}

export default withRouter(ErrorBoundary);
