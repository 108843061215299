import React, { useCallback, useState } from "react";
import { Button, Input, Modal, Select } from "antd";

import ADMIN_SLACK_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_SLACK_QUERY";

const { Option } = Select;

const CancelWithdrawModal = ({
  setShowCancelWithdrawModal,
  showCancelWithdrawModal,
  partnerCompanyId,
  bidId,
}: {
  setShowCancelWithdrawModal: React.Dispatch<React.SetStateAction<boolean>>;
  showCancelWithdrawModal: boolean;
  partnerCompanyId: number;
  bidId: number;
}) => {
  const [cancelReason, setCancelReason] = useState("직접 입력");
  const [etcReason, setEtcReason] = useState("");

  const {
    mutate: cancelWithdraw,
    ResponseHandler: ResponseHandlerOfUseCancelWithdraw,
  } = ADMIN_SLACK_QUERY.useCancelWithdraw({
    successModalInfo: {
      handleConfirmSuccess: (initQuery) => {
        initQuery();
        setShowCancelWithdrawModal(false);
      },
      customizeMessage: () => ({
        title: "출금액 취소 슬랙 알람을 요청했습니다.",
      }),
    },
  });

  const handleModalClose = useCallback(() => {
    setShowCancelWithdrawModal(false);
  }, [setShowCancelWithdrawModal]);

  const handleEtcReasonInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEtcReason(e.target.value);
    },
    []
  );

  const handleCancelReasonChange = useCallback((e: string) => {
    setCancelReason(e);
  }, []);

  const handleCancelWithDrawClick = useCallback(() => {
    cancelWithdraw({
      bidId,
      partnerCompanyId,
      cancelReason: cancelReason === "직접 입력" ? etcReason : cancelReason,
    });
  }, [cancelWithdraw, bidId, partnerCompanyId, cancelReason, etcReason]);

  return (
    <Modal
      destroyOnClose={true}
      onCancel={handleModalClose}
      bodyStyle={{ padding: "12px 16px 40px" }}
      width={"20%"}
      title="출금요청 취소 사유입력"
      footer={null}
      visible={showCancelWithdrawModal}
    >
      <Select
        onChange={handleCancelReasonChange}
        value={cancelReason}
        style={{ width: "100%" }}
      >
        <Option value="직접 입력">{"직접 입력"}</Option>
        <Option value="항목 추가">{"항목 추가"}</Option>
        <Option value="항목 삭제">{"항목 삭제"}</Option>
        <Option value="출금요청 실수">{"출금요청 실수"}</Option>
      </Select>

      {cancelReason === "직접 입력" && (
        <Input
          placeholder="취소 요청 사유를 입력하세요"
          onChange={handleEtcReasonInputChange}
          value={etcReason}
        ></Input>
      )}

      <Button
        disabled={cancelReason === "직접 입력" && !etcReason}
        onClick={handleCancelWithDrawClick}
        style={{ marginTop: 16 }}
        type="primary"
      >
        요청하기
      </Button>

      {ResponseHandlerOfUseCancelWithdraw}
    </Modal>
  );
};

export default CancelWithdrawModal;
