import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useAtomValue } from "jotai";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import { ADMIN_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import ADMIN_BID_USER_QUERY, {
  ADMIN_BID_USER_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_USER_QUERY";
import ADMIN_ORDER_QUERY, {
  ADMIN_ORDER_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_ORDER_QUERY";
import ADMIN_TEAM_QUERY, {
  ADMIN_TEAM_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_TEAM_QUERY";
import { CUSTOMS_ADMIN_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/CUSTOMS_ADMIN_BID_QUERY";
import { CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN } from "@sellernote/_shared/src/queries/forwarding/CUSTOMS_ADMIN_TRELLO_BID_QUERY";
import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { CommentDataType } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { Comment } from "@sellernote/_shared/src/types/forwarding/trello";
import useUploadResponseSnackBar from "@sellernote/_shared-for-admin/src/hooks/common/useUploadResponseSnackBar";

import { FORWARDING_ADMIN_AUTH_SELECTORS } from "../../jotaiStates/auth";
import CommentDataList from "./CommentDataList";

export default function useCommentList({
  commentList,
  dataId,
  dataType,
}: {
  commentList: Comment[] | undefined;
  dataId: number | undefined;
  dataType: CommentDataType;
}) {
  const queryClient = useQueryClient();

  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const currentPartnerAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_PARTNER_ADMIN_AUTH_INFO
  );

  const [comment, setComment] = useState("");

  const { mutate: updateComment } = TRELLO_BID_QUERY.useUpdateComment();

  const { mutate: updateOrderComment } = ADMIN_ORDER_QUERY.useUpdateComment();

  const { mutate: updateTeamComment } = ADMIN_TEAM_QUERY.useUpdateTeamComment();

  const { mutate: updateUserComments } =
    ADMIN_BID_USER_QUERY.useUpdateComment();

  const {
    UploadResponseSnackBar,
    setShowsErrorSnackBar,
    setShowsSuccessSnackBar,
  } = useUploadResponseSnackBar();

  const commentData = useMemo(() => {
    if (!commentList) {
      return null;
    }

    return [...commentList].reverse();
  }, [commentList]);

  const handleCommentInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setComment(e.target.value);
    },
    []
  );

  const commentId = useMemo(() => {
    if (APP_NAME === "shipda-admin") {
      return currentAdminAuthInfo?.accountId || "-";
    }
    return currentPartnerAdminAuthInfo?.company || "-";
  }, [currentAdminAuthInfo?.accountId, currentPartnerAdminAuthInfo?.company]);

  const handleCommentUpdate = useCallback(() => {
    if (!dataId) {
      return;
    }

    if (dataType === "user") {
      updateUserComments(
        {
          pathParams: {
            userId: dataId,
          },
          id: commentId,
          comment,
          date: new Date().toISOString(),
        },
        {
          onSuccess: () => {
            setComment("");
            setShowsSuccessSnackBar(true);
            queryClient.invalidateQueries(
              ADMIN_BID_USER_QUERY_KEY_GEN.getAdminNewBidUserDetail(dataId)
            );
          },
          onError: () => {
            setShowsErrorSnackBar(true);
            return;
          },
        }
      );

      return;
    }

    if (dataType === "team") {
      updateTeamComment(
        {
          pathParams: {
            teamId: dataId,
          },
          id: commentId,
          comment,
          date: new Date().toISOString(),
        },
        {
          onSuccess: () => {
            setComment("");
            setShowsSuccessSnackBar(true);
            queryClient.invalidateQueries(
              ADMIN_TEAM_QUERY_KEY_GEN.getAdminTeamDetail(dataId)
            );
          },
          onError: () => {
            setShowsErrorSnackBar(true);
            return;
          },
        }
      );

      return;
    }

    if (dataType === "order") {
      updateOrderComment(
        {
          poId: Number(dataId),
          body: comment,
        },

        {
          onSuccess: () => {
            setComment("");
            setShowsSuccessSnackBar(true);
            queryClient.invalidateQueries(
              ADMIN_ORDER_QUERY_KEY_GEN.getAdminOrderDetail({
                id: dataId,
              })
            );
          },
          onError: () => {
            setShowsErrorSnackBar(true);
            return;
          },
        }
      );

      return;
    }

    updateComment(
      {
        pathParams: {
          bidId: dataId,
        },
        comment: {
          id: commentId,
          comment,
          date: new Date().toISOString(),
        },
      },
      {
        onSuccess: () => {
          setComment("");
          setShowsSuccessSnackBar(true);

          if (dataType === "trello") {
            if (APP_NAME === "shipda-admin") {
              queryClient.invalidateQueries(
                TRELLO_BID_QUERY_KEY_GEN.trelloDetail()
              );
            } else {
              queryClient.invalidateQueries(
                CUSTOMS_ADMIN_TRELLO_BID_QUERY_KEY_GEN.getCustomsTrelloBidDetail(
                  {
                    bidId: dataId,
                  }
                )
              );
            }
          }

          if (dataType === "bid") {
            if (APP_NAME === "shipda-admin") {
              queryClient.invalidateQueries(
                ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
                  bidId: dataId,
                })
              );
              return;
            }
            queryClient.invalidateQueries(
              CUSTOMS_ADMIN_BID_QUERY_KEY_GEN.getCustomsBidDetail({
                bidId: dataId,
              })
            );
          }
          return;
        },

        onError: () => {
          setShowsErrorSnackBar(true);
          return;
        },
      }
    );
  }, [
    dataId,
    dataType,
    updateComment,
    commentId,
    comment,
    updateUserComments,
    setShowsSuccessSnackBar,
    queryClient,
    setShowsErrorSnackBar,
    updateTeamComment,
    updateOrderComment,
  ]);

  const CommentList = useMemo(() => {
    return (
      <CommentDataList
        comment={comment}
        onCommentInputChange={handleCommentInputChange}
        onCommentUpdate={handleCommentUpdate}
        commentData={commentData}
        UploadResponseSnackBar={UploadResponseSnackBar}
      />
    );
  }, [
    UploadResponseSnackBar,
    comment,
    commentData,
    handleCommentInputChange,
    handleCommentUpdate,
  ]);

  return { CommentList };
}
