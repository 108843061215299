import { Card, Descriptions, Divider } from "antd";

import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";

const ContainerItemsDesc = ({ bidDetail }: { bidDetail: AdminBidDetail }) => {
  const { containersInfo } = bidDetail;

  return (
    <div>
      <Card
        type="inner"
        title={`총 FEU: ${bidDetail.supply}`}
        headStyle={{ color: "#1890FF" }}
      >
        {containersInfo?.map((v, index) => {
          return (
            <div key={index.toString()}>
              <Descriptions contentStyle={{ fontWeight: "bold" }}>
                <Descriptions.Item label="품명">{v.name}</Descriptions.Item>

                <Descriptions.Item label="컨테이너 타입">
                  {v.containerType}
                </Descriptions.Item>

                <Descriptions.Item label="컨테이너 수량">
                  {v.quantity}
                </Descriptions.Item>

                <Descriptions.Item label="위험물">
                  {v.isDangerous ? "예" : "아니오"}
                </Descriptions.Item>

                {bidDetail.containerAccessable === false && (
                  <Descriptions.Item label="CBM">{v.cbm}</Descriptions.Item>
                )}

                {bidDetail.containerAccessable === false && (
                  <Descriptions.Item label="중량">{v.weight}</Descriptions.Item>
                )}

                {bidDetail.containerAccessable === false && (
                  <Descriptions.Item label="CBM">
                    {v.canStack ? "예" : "아니오"}
                  </Descriptions.Item>
                )}
              </Descriptions>

              <Divider />
            </div>
          );
        })}
      </Card>
    </div>
  );
};

export default ContainerItemsDesc;
