import { useMemo } from "react";

import { GET_TRELLO_ATTACHMENTS_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/trello";
import { APP_NAME } from "@sellernote/_shared/src/constants";
import { TrelloUploadDocumentTableData } from "@sellernote/_shared/src/types/forwarding/trello";

export default function useTrelloUploadDocumentsTableData({
  isImport,
  attachments,
}: {
  isImport: boolean;
  attachments: GET_TRELLO_ATTACHMENTS_RES | undefined;
}) {
  const trelloUploadDocumentTableData = useMemo(() => {
    const uploadDocumentTableData: TrelloUploadDocumentTableData[] = [
      {
        domain: "CI_PL",
        attachments: [],
      },
      {
        domain: "BL",
        attachments: [],
      },
      {
        domain: "CO",
        attachments: [],
      },
      {
        domain: "brn",
        attachments: [],
      },
      { domain: "certificate", attachments: [] },
      { domain: "etc", attachments: [] },
      // 수입세금계산서는 수출에서는 사용하지 않음
      ...(isImport ? [{ domain: "importTax" as const, attachments: [] }] : []),
      // 수입신고필증은 수출일 때 수출신고필증으로 변경
      {
        domain: isImport ? "importDeclaration" : "exportDeclaration",
        attachments: [],
      },

      { domain: "houseDo", attachments: [] },
      {
        domain: "releaseRequest",
        attachments: [],
      },
      {
        domain: "parcelRequest",
        attachments: [],
      },
      {
        domain: "adminTemp",
        attachments: [],
      },
    ];

    if (!attachments) {
      return;
    }

    return attachments.reduce((arr, cur) => {
      arr.forEach((v) => {
        if (v.domain === cur.domain) {
          v.attachments = [...v.attachments, cur];
        }

        return;
      });

      return arr;
    }, uploadDocumentTableData);
  }, [attachments, isImport])?.filter((v) => {
    // 수출과 파트너 어드민에서는 필요 없음
    if (APP_NAME === "partner-admin" || !isImport) {
      if (
        v.domain === "parcelRequest" ||
        v.domain === "releaseRequest" ||
        v.domain === "houseDo" ||
        v.domain === "adminTemp"
      ) {
        return false;
      }
    }
    return true;
  });

  return { trelloUploadDocumentTableData };
}
