import {
  Currency,
  FreightType,
  ItemUnitMeasurement,
  Liner,
} from "../../types/common/common";
import {
  AdminBidContainerInfo,
  AdminBidDetail,
  ApplyBidFeeData,
} from "../../types/forwarding/adminBid";
import { ForwardingAdminUserAuthority } from "../../types/forwarding/adminUser";
import {
  BidProductInfo,
  BidProjectStatus,
  BidServiceType,
  ContainerSize,
} from "../../types/forwarding/bid";
import { PartnerBusinessArea } from "../../types/forwarding/partner";
import { AccountPayable, ExchangeRate } from "../../types/forwarding/trello";

import {
  ADMIN_BID_QUOTATION_CONSOLIDATION_COMMENT,
  ADMIN_BID_QUOTATION_FCL_COMMENT,
  ADMIN_BID_QUOTATION_LCL_AND_AIR_COMMENT,
  ADMIN_BID_QUOTATION_OCEANTICKET_COMMENT,
  ADMIN_BID_QUOTATION_SG_COMMENT,
  ADMIN_BID_SCHEDULE_CIF_CFR_COMMENT,
} from "../../constants/forwarding/adminBid";
import { checkEqualObject } from "../common/etc";
import { omitWithEllipsis } from "../common/string";
import { findExchangeRate, getInvoiceDataCbm } from "./tradingStatement";

const getCustomsPartnerName = (accountPayables: AccountPayable[]) => {
  const customsAccountPayable = accountPayables.find((v) => {
    return v.domain === "customs";
  });

  if (customsAccountPayable?.partner) {
    return customsAccountPayable.partner.name;
  }
  return "화주발송";
};

const getTransportMode = (
  freightType: FreightType,
  serviceType: BidServiceType
) => {
  if (freightType === "FCL") {
    return "FCL";
  }
  if (freightType === "LCL" && serviceType === "consolidation") {
    return "EXPRESS";
  }

  if (freightType === "AIR") {
    return "AIR";
  }
  return "LCL";
};

const getProductsInfoNames = (productsInfo: BidProductInfo[]) => {
  return productsInfo
    .map((productItem) => {
      return productItem.name;
    })
    .toString();
};

const checkIsMasterAuthority = (
  authority: ForwardingAdminUserAuthority | undefined
) => {
  if (authority === "master") {
    return true;
  }
  return false;
};

const checkCanEditBidItem = (
  projectStatus: BidProjectStatus,
  authority: ForwardingAdminUserAuthority | undefined
) => {
  if (projectStatus === "settlementComplete") {
    if (checkIsMasterAuthority(authority)) {
      return true;
    }
    return false;
  }
  return false;
};

const getQuotationComment = (bidDetail: AdminBidDetail | undefined) => {
  if (!bidDetail?.isImport) {
    return "";
  }
  if (bidDetail?.locale !== "KR") {
    return ADMIN_BID_QUOTATION_SG_COMMENT;
  }

  if (bidDetail.freightType === "FCL") {
    if (bidDetail.incoterms === "CIF" || bidDetail.incoterms === "CFR") {
      return ADMIN_BID_SCHEDULE_CIF_CFR_COMMENT;
    }
    return ADMIN_BID_QUOTATION_FCL_COMMENT;
  }

  if (bidDetail.serviceType === "oceanTicket") {
    return ADMIN_BID_QUOTATION_OCEANTICKET_COMMENT;
  }

  if (bidDetail.serviceType === "consolidation") {
    return ADMIN_BID_QUOTATION_CONSOLIDATION_COMMENT;
  }

  return ADMIN_BID_QUOTATION_LCL_AND_AIR_COMMENT;
};

const getLinerId = (linerList: Liner[] | undefined, linerName: string) => {
  if (!linerList) {
    return 0;
  }

  return (
    linerList.find((liner) => {
      return liner.name === linerName;
    })?.id || 0
  );
};

const getInlandType = (
  inlandFares: ApplyBidFeeData[],
  freightType: FreightType
) => {
  const findTruckingChargeType = inlandFares.find((v) => {
    return v.itemUnitMeasurement;
  });

  if (findTruckingChargeType) {
    if (findTruckingChargeType.note.includes("독차")) {
      return "sole";
    }

    if (findTruckingChargeType.note.includes("합차")) {
      return "consol";
    }

    if (findTruckingChargeType.note.includes("화물 택배")) {
      return "parcel";
    }
  }

  return null;
};

/**
 * 기존 의뢰 물품 정보와 수정된 물품 정보를 비교 후 이름만 변경했는지 확인해주는 함수
 * @returns 물품 수정 시 이름만 변경했는지에 대한 boolean값
 */
const checkAdminBidItemOnlyRenamed = ({
  originItemData,
  newItemData,
}: {
  originItemData: AdminBidContainerInfo[] | BidProductInfo[];
  newItemData: AdminBidContainerInfo[] | BidProductInfo[];
}) => {
  const originItemDataWithNameRemoved = originItemData.map((item) => {
    return {
      ...item,
      name: "",
    };
  });

  const newItemDataWithNameRemoved = newItemData.map((item) => {
    return {
      ...item,
      name: "",
    };
  });

  return checkEqualObject(
    originItemDataWithNameRemoved,
    newItemDataWithNameRemoved
  );
};

/**
 * 의뢰 상세에 해당 도메인 컨택 파트너가 있는지 확인해준다.
 * @param bidDetail 의뢰 상세 데이터
 * @param domain 파트너의 도메인
 * @returns 도메인 파트너가 의뢰 존재 여부값
 */
const checkIfBidHaveDomainPartner = (
  bidDetail: AdminBidDetail,
  domain: PartnerBusinessArea
) => {
  const domainPartner = bidDetail.accountPayables.find((accountPayable) => {
    return (
      // 어카운트만 추가하고 파트너를 등록하지 않는 경우가 있음
      accountPayable.partnerCompanyId && accountPayable.domain === domain
    );
  });

  if (domainPartner) {
    return true;
  }
  return false;
};

/**
 * 의뢰 파트너가 가지고 있는 language가 en인지 확인
 * @param bidDetail 의뢰 상세 데이터
 * @param domain 파트너의 도메인
 * @returns boolean
 */
const checkBidPartnerLanguageIsEn = (
  bidDetail: AdminBidDetail,
  domain: PartnerBusinessArea
) => {
  const domainPartner = bidDetail.accountPayables.find((accountPayable) => {
    return (
      // 어카운트만 추가하고 파트너를 등록하지 않는 경우가 있음
      accountPayable.partnerCompanyId && accountPayable.domain === domain
    );
  });

  if (domainPartner) {
    return domainPartner.partner.language === "en";
  }
  return false;
};

/**
 * containersInfo에서 중복을 제거한 containersType들이 있는 배열을 리턴해준다.
 */
const getUniqueContainerTypeArr = (containersInfo: AdminBidContainerInfo[]) => {
  const containerTypeArray = containersInfo?.map((v) => {
    return v.containerType;
  });
  // 컨테이너 타입의 중복을 제거
  const uniqueContainerTypeArr = [...new Set(containerTypeArray)];

  return uniqueContainerTypeArr;
};

const getAmountByContainerType = (
  containersInfo: AdminBidContainerInfo[],
  containerType: ContainerSize
) => {
  return containersInfo.reduce((acc, cur) => {
    if (cur.containerType === containerType) {
      acc = acc + cur.quantity;
    }

    return acc;
  }, 0);
};

const getContainerTypeAndAmountArr = (
  containersInfo: AdminBidContainerInfo[]
) => {
  const uniqueContainerTypeArr = getUniqueContainerTypeArr(containersInfo);

  return uniqueContainerTypeArr.map((v) => {
    return {
      containersType: v,
      amount: getAmountByContainerType(containersInfo, v),
    };
  });
};

const getFeeDataAmountByItemUnitMeasurement = ({
  amount,
  itemUnitMeasurement,
  containersInfo,
  serviceType,
  wareHouseCbm,
  totalWeight,
  totalCBM,
  unipassTotalCBM,
}: {
  amount: number;
  itemUnitMeasurement: ItemUnitMeasurement;
  containersInfo: AdminBidContainerInfo[];
  serviceType: BidServiceType | undefined;
  wareHouseCbm: number | undefined;
  totalWeight: number | undefined;
  totalCBM: number | undefined;
  unipassTotalCBM: string | null;
}) => {
  if (serviceType === "consolidation" && itemUnitMeasurement === "R.TON") {
    // 유니패스 연동 시엔 의뢰 데이터가 아닌 유니패스 CBM 데이터를 보여준다.
    if (unipassTotalCBM) {
      return Number(getInvoiceDataCbm(unipassTotalCBM));
    }

    // 창고 계산기준에 따라서 1CBM/250KG인 경우 총 무게에 * 4를 한 뒤 총 CBM과 비교한다.
    if (
      wareHouseCbm === 250 &&
      totalCBM &&
      totalWeight &&
      totalWeight * 4 > totalCBM &&
      totalWeight * 4 > 1
    ) {
      return totalWeight * 4;
    }
  }

  if (
    itemUnitMeasurement === "B/L" ||
    itemUnitMeasurement === "TRUCK" ||
    itemUnitMeasurement === "ENTRY"
  ) {
    return 1;
  }

  if (itemUnitMeasurement === "3R.TON") {
    return amount / 3;
  }

  if (itemUnitMeasurement === "0.1 R.TON") {
    return Math.ceil(amount * 10);
  }

  if (
    itemUnitMeasurement === "CNTR(20DRY)" ||
    itemUnitMeasurement === "CNTR(40DRY)" ||
    itemUnitMeasurement === "CNTR(40HQ)" ||
    itemUnitMeasurement === "20RF" ||
    itemUnitMeasurement === "40RF"
  ) {
    const containerTypeAndAmountArr =
      getContainerTypeAndAmountArr(containersInfo);

    const containerAmount = containerTypeAndAmountArr.find((v) => {
      return itemUnitMeasurement.includes(v.containersType);
    });

    return containerAmount?.amount || 0;
  }

  /**
   * 견적 단위 중 "CNTR" 선택시, 컨테이너 수량의 총 합을 기본 값으로 넣어줌
   * 단, containersInfo가 존재하는 FCL 의뢰에 한함
   */
  if (itemUnitMeasurement === "CNTR" && Boolean(containersInfo)) {
    return containersInfo.reduce((acc, { quantity }) => acc + quantity, 0);
  }

  return amount < 1 ? 1 : amount;
};

const getLabelForCarrier = (freightType: FreightType) => {
  if (freightType === "FCL") {
    return "선사명";
  }

  return "콘솔사명";
};

/**
 * 견적 항목 데이터에 존재하는 currency값만 추출한다.
 * @param feeData 견적 항목 데이터
 * @returns
 */
const extractCurrencyInFeeData = (feeData: ApplyBidFeeData[]) => {
  if (!feeData) {
    return [];
  }
  const currencySet = feeData?.reduce((acc, cur) => {
    // KRW은 필요없음
    if (cur.currency !== "KRW") {
      acc.add(cur.currency);
    }

    return acc;
  }, new Set());

  return Array.from(currencySet);
};

/**
 * 견적 요청을 할 때 필요한 currency payload 값을 만들어주는 함수
 * @param param0
 * @returns
 */
const getQuotationDataCurrency = ({
  localFee,
  freightFee,
  domesticFee,
  inlandFee,
  otherFee,
  taxFee,
  exchangeRateList,
}: {
  localFee: ApplyBidFeeData[];
  freightFee: ApplyBidFeeData[];
  domesticFee: ApplyBidFeeData[];
  inlandFee: ApplyBidFeeData[];
  otherFee: ApplyBidFeeData[];
  taxFee: ApplyBidFeeData[];
  exchangeRateList?: ExchangeRate[];
}) => {
  const domesticFeeCurrency = extractCurrencyInFeeData(domesticFee);
  const localFeeCurrency = extractCurrencyInFeeData(localFee);
  const freightFeeCurrency = extractCurrencyInFeeData(freightFee);
  const inlandFeeCurrency = extractCurrencyInFeeData(inlandFee);
  const otherFeeCurrency = extractCurrencyInFeeData(otherFee);
  const taxFeeCurrency = extractCurrencyInFeeData(taxFee);

  // 각 항목별 중복으로 존재하는 통화를 제거한다.
  const filteredDataByCurrencyDuplicate = [
    ...new Set([
      ...domesticFeeCurrency,
      ...localFeeCurrency,
      ...freightFeeCurrency,
      ...inlandFeeCurrency,
      ...otherFeeCurrency,
      ...taxFeeCurrency,
    ]),
  ];

  const bidQuotationCurrency = exchangeRateList?.reduce(
    (acc, cur) => {
      if (filteredDataByCurrencyDuplicate.includes(cur.currency)) {
        // api로 받는 exchangeRate의 rate값은 string인데 요청 값은 number로 보내야함
        acc = { ...acc, [cur.currency]: Number(cur.rate) };
      }
      return acc;
    },
    // 만약 견적에서 외화가 없는 경우 기본값으로 USD를 담아서 요청
    { USD: findExchangeRate(exchangeRateList, "USD") }
  );

  return bidQuotationCurrency as Record<Currency, number>;
};

/**
 * 정규식으로 <br> 태그를 \n으로 대체
 */
const replaceBrTagsWithNewline = (value: string | undefined) => {
  if (!value) return "";

  return value.replace(/<br\s*\/?>/gi, "\n");
};

// TODO: getTitleAmongItemListName에도 건이 붙는다면 삭제
const getBidTableItemName = ({
  items,
  maxLength,
}: {
  items?: { name: string }[];
  maxLength?: number;
}) => {
  let result = "";

  if (items && items.length > 0) {
    result += items[0].name;

    if (maxLength) {
      result = omitWithEllipsis({
        src: result,
        maxLength,
        ellipsis: "...",
      });
    }

    if (items.length > 1) {
      result += ` 외 ${items.length - 1}건`;
    }
  }

  return result;
};

const getShipmentServiceTypeKr = ({
  serviceType,
  isExpress,
  isImport,
}: {
  serviceType: string;
  isExpress: boolean;
  isImport: boolean;
}) => {
  if (!isImport) return "수출";

  if (isExpress) return "특송";

  if (serviceType === "oceanTicket") return "오션티켓";

  if (serviceType === "general") return "일반의뢰";

  if (serviceType === "consolidation") return "묶음 배송";

  return "-";
};

export {
  getCustomsPartnerName,
  getTransportMode,
  getProductsInfoNames,
  checkCanEditBidItem,
  getQuotationComment,
  getLinerId,
  getInlandType,
  checkAdminBidItemOnlyRenamed,
  checkIfBidHaveDomainPartner,
  checkBidPartnerLanguageIsEn,
  getFeeDataAmountByItemUnitMeasurement,
  getLabelForCarrier,
  getQuotationDataCurrency,
  replaceBrTagsWithNewline,
  getBidTableItemName,
  getShipmentServiceTypeKr,
};
