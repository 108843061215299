import { Dispatch, SetStateAction, useCallback } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { message, Modal } from "antd";

import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";

import Styled from "./index.styles";

const CheckIsPaymentModal = ({
  setShowCheckIsPaymentModal,
  showCheckIsPaymentModal,
  bidId,
  onTrelloDetailOpen,
  endAddress,
}: {
  setShowCheckIsPaymentModal: Dispatch<SetStateAction<boolean>>;
  showCheckIsPaymentModal: boolean;
  bidId: number;
  onTrelloDetailOpen: (bidId: number) => void;
  endAddress: string | null;
}) => {
  const history = useHistory();

  const queryClient = useQueryClient();

  const { mutate: confirmInland } = TRELLO_BID_QUERY.useConfirmInland(bidId);

  const handleInlandConfirmClick = useCallback(() => {
    confirmInland(
      {},
      {
        onSuccess: () => {
          //TODO: 특송의 경우 endAddress를 ""으로 진행하는 경우가 있어서 임시로 null로만 체크 TMS 개발 시 변경될 예정
          if (endAddress === null) {
            message.success("종료로 이동했습니다.");
            setShowCheckIsPaymentModal(false);
            queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.all());
          } else {
            setShowCheckIsPaymentModal(false);
            message.success("내륙운송으로 이동했습니다.");
            queryClient.invalidateQueries(TRELLO_BID_QUERY_KEY_GEN.all());
          }
        },

        onError: () => {
          message.error("에러가 있습니다. 개발자에게 문의해주세요");
        },
      }
    );
  }, [confirmInland, endAddress, queryClient, setShowCheckIsPaymentModal]);

  const handleModalCancel = useCallback(() => {
    setShowCheckIsPaymentModal(false);
  }, [setShowCheckIsPaymentModal]);

  const handleMoveToTradingStatementPageClick = useCallback(() => {
    history.push(`/tradingStatement/warehouseReceipt/${bidId}`);
  }, [bidId, history]);

  const handleTrelloModalOpenClick = useCallback(() => {
    setShowCheckIsPaymentModal(false);
    onTrelloDetailOpen(bidId);
  }, [bidId, onTrelloDetailOpen, setShowCheckIsPaymentModal]);

  return (
    <Modal
      width={428}
      bodyStyle={{ padding: "40px 40px 22px 18px" }}
      title=""
      visible={showCheckIsPaymentModal}
      onCancel={handleModalCancel}
      footer={null}
    >
      <Styled.checkPaymentModalWrapper>
        <div className="header">
          본 케이스는 수입신고 수리후 반출신고 되었습니다. 창고료 등의 정산이
          완료되었습니까 ?
        </div>

        <div className="footer">
          <div className="topButtonWrapper">
            <div className="button" onClick={handleTrelloModalOpenClick}>
              나중에 하기
            </div>
          </div>

          <div className="bottomButtonWrapper">
            <div
              className="button"
              onClick={handleMoveToTradingStatementPageClick}
            >
              <div>거래명세서 이동</div>
            </div>

            <div className="ok-button" onClick={handleInlandConfirmClick}>
              예
            </div>
          </div>
        </div>
      </Styled.checkPaymentModalWrapper>
    </Modal>
  );
};

export default CheckIsPaymentModal;
