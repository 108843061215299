import {
  MallOptions,
  ReturnAcceptWaitDay,
  ShippingMode,
} from "../../types/fulfillment/order";

import { InputSelectOption } from "../../headlessComponents/input/useInputSelect";

const MALL_OPTIONS: {
  [key in MallOptions]: string;
} = {
  smartStore: "스마트스토어",
  coupang: "쿠팡",
  eleven: "11번가",
  imweb: "아임웹",
  cafe24: "카페24",
  godomall: "고도몰",
  kakaoStore: "카카오톡스토어",
  "10x10": "텐바이텐",
  gmarket: "지마켓",
  auction: "옥션",
  Shopify: "쇼피파이",
};

const HOW_TO_USE_LIST: {
  [key in MallOptions]: string;
} = {
  smartStore: "https://www.ship-da.com/support/guide/use/68",
  coupang: "https://www.ship-da.com/support/guide/use/69",
  eleven: "https://www.ship-da.com/support/guide/use/70",
  cafe24: "https://www.ship-da.com/support/guide/use/71",
  imweb: "https://www.ship-da.com/support/guide/use/75",
  godomall: "https://www.ship-da.com/support/guide/use/94",
  kakaoStore: "https://www.ship-da.com/support/guide/use/121",
  "10x10": "https://www.ship-da.com/support/guide/use/127",
  gmarket: "https://www.ship-da.com/support/guide/use/188",
  auction: "https://www.ship-da.com/support/guide/use/187",
  Shopify: "https://www.ship-da.com/support/guide/use/186",
};

const COLLECT_ORDER_RANGE_VALUE_LIST: Record<
  number,
  InputSelectOption<number>
> = {
  0: {
    label: `연동 시작이후 "결제완료" 상태의 주문을 수집`,
    value: 0,
  },
  1: {
    label: `연동 시작으로부터 1 일 전 "결제완료" 상태의 주문을 수집`,
    value: 1,
  },
  2: {
    label: `연동 시작으로부터 2 일 전 "결제완료" 상태의 주문을 수집`,
    value: 2,
  },
  3: {
    label: `연동 시작으로부터 3 일 전 "결제완료" 상태의 주문을 수집`,
    value: 3,
  },
  4: {
    label: `연동 시작으로부터 4 일 전 "결제완료" 상태의 주문을 수집`,
    value: 4,
  },
  5: {
    label: `연동 시작으로부터 5 일 전 "결제완료" 상태의 주문을 수집`,
    value: 5,
  },
};

const SHIPPING_MODE_VALUE_LIST: Record<
  ShippingMode,
  InputSelectOption<ShippingMode>
> = {
  active: {
    label: "평일 08:00~16:00 1시간 마다 출고자동등록",
    value: "active",
  },
  passive: {
    label: "출고중지",
    value: "passive",
  },
};

const RETURN_ACCEPT_WAITING_DAYS_VALUE_LIST: Record<
  ReturnAcceptWaitDay,
  InputSelectOption<ReturnAcceptWaitDay>
> = {
  0: {
    label:
      "반품 자동등록 중지 : 반품 발생시 자동으로 접수하지 않습니다.(기본값)",
    value: 0,
  },
  1: {
    label: "반품요청 발생 D+1 영업일 이후 반품자동등록",
    value: 1,
  },
  2: {
    label: "반품요청 발생 D+2 영업일 이후 반품자동등록",
    value: 2,
  },
  3: {
    label: "반품요청 발생 D+3 영업일 이후 반품자동등록",
    value: 3,
  },
};

const getOmsApiKeyMap = (mall: MallOptions) => {
  switch (mall) {
    case "smartStore":
      return {
        apiSellerId: "API 연동용 판매자 ID",
      };
    case "coupang":
      return {
        vendorId: "업체코드",
        accessKey: "Access Key",
        secretKey: "Secret Key",
      };
    case "eleven":
      return {
        apiKey: "API ID",
      };
    case "imweb":
      return {
        apiKey: "API Key",
        secretKey: "Secret Key",
      };
    case "cafe24":
      return {
        mallId: "쇼핑몰 ID",
      };
    case "godomall":
      return {
        partnerKey: "파트너 키",
        key: "사용자 키",
      };
    case "kakaoStore":
      return {
        sellerAppKey: "API 키",
      };
    case "10x10":
      return {
        apiKey: "인증키",
      };
    case "gmarket":
      return {
        masterId: "마스터 ID",
        siteSellerId: "셀러 사이트 ID",
      };
    case "auction":
      return {
        masterId: "마스터 ID",
        siteSellerId: "셀러 사이트 ID",
      };
    case "Shopify":
      return {
        mallName: "쇼핑몰 도메인",
        adminAccessTokenKey: "관리자 API 액세스 토큰",
      };
    default:
      throw new Error("invalid mall");
  }
};

export type OmsApiKeyMapObjectKeys = keyof ReturnType<typeof getOmsApiKeyMap>;

export {
  MALL_OPTIONS,
  HOW_TO_USE_LIST,
  COLLECT_ORDER_RANGE_VALUE_LIST,
  SHIPPING_MODE_VALUE_LIST,
  RETURN_ACCEPT_WAITING_DAYS_VALUE_LIST,
  getOmsApiKeyMap,
};
