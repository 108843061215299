import { useMemo } from "react";
import { CSVLink } from "react-csv";
import { FileDownloadOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";

import { GroupedHeadRow, TableBodyRow, TableHeadCell } from ".";

export type TableCSVDownloadForSelectedRowsInfo<
  CellId extends string,
  GroupedHeadId
> = {
  filename: string;
  rowsForCSVDownload: TableBodyRow<CellId>[];
  /**
   * 엑셀 다운로드시 제외하고 싶은 데이터의 id들
   */
  idsToExceptForExcelDownload?: CellId[];
  /**
   * 엑셀 다운로드 시 제외하고 싶은 groupedHeadRow의 id들
   */
  groupedHeadRowIdsToExceptForExcelDownload?: GroupedHeadId[];
  /**
   * 표 상단에 표시하고 싶은 데이터가 있을때 사용
   * 자유롭게 사용할 수 있도록 최대한 단순하게 정의함
   */
  metaInfo?: (string | number)[][];
  /**
   * CSV의 컬럼이 화면에 보이는 컬럼과 달라지는 경우 사용
   * headCellsForCSVDownload을 추가할 경우 headCells 보다 우선해서 적용됨
   */
  headCellsForCSVDownload?: TableHeadCell<CellId>[];
  /**
   * CSV의 컬럼이 화면에 보이는 컬럼과 달라지는 경우 사용
   * groupedHeadRowForCSVDownload을 추가할 경우 groupedHeadRow 보다 우선해서 적용됨
   */
  groupedHeadRowForCSVDownload?: GroupedHeadRow<GroupedHeadId, CellId>;
};

export default function CSVDownloadForSelectedRows<
  CellId extends string,
  GroupedHeadId = void
>({
  headCells,
  groupedHeadRow,
  csvDownloadInfo,
}: {
  headCells: TableHeadCell<CellId>[];
  groupedHeadRow?: GroupedHeadRow<GroupedHeadId, CellId>;
  csvDownloadInfo: TableCSVDownloadForSelectedRowsInfo<CellId, GroupedHeadId>;
}) {
  const csvData = useMemo(() => {
    if (!csvDownloadInfo) return [];

    if (!headCells) return [];
    if (!csvDownloadInfo.rowsForCSVDownload) return [];

    const dictToExceptForExcelDownload = (() => {
      const dict: { [K in string]?: boolean } = {};

      csvDownloadInfo.idsToExceptForExcelDownload?.forEach((id) => {
        dict[id] = true;
      });

      return dict;
    })();

    const metaInfoRow = csvDownloadInfo.metaInfo || [];

    const processedGroupedHeadRow = (() => {
      let result: string[] = [];

      (csvDownloadInfo.groupedHeadRowForCSVDownload ?? groupedHeadRow)
        ?.filter(
          (gh) =>
            !csvDownloadInfo.groupedHeadRowIdsToExceptForExcelDownload?.includes(
              gh.id
            )
        )
        .map((gh) => {
          const subResult: string[] = [];

          gh.headCellIds.forEach((c) => {
            if (!dictToExceptForExcelDownload[c]) {
              // colspan을 표현
              subResult.push("");
            }
          });

          subResult[0] = gh.label;

          result = [...result, ...subResult];

          return result;
        });

      return result;
    })();

    const headRow = (csvDownloadInfo.headCellsForCSVDownload ?? headCells)
      .filter((hc) => !dictToExceptForExcelDownload[hc.id])
      .map((hc) => hc.labelForCSV || hc.label);

    const dataRows = (() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result: any[] = [];

      csvDownloadInfo.rowsForCSVDownload.forEach((row) => {
        // 핸들러 함수 등 칼럼 데이터와 관계 없는 정보는 제외한다
        const { handleRowClick, backgroundColor, ...pureRow } = row;

        // idsToExceptForExcelDownload로 다운로드시 제외를 원한필드는 제외한다
        const validRow = Object.fromEntries(
          Object.entries(pureRow).filter(
            ([key]) => !dictToExceptForExcelDownload[key]
          )
        );

        const arrFromRow = Object.values(validRow);

        const processedArrFromRow = arrFromRow.map((v) => {
          if (typeof v === "string") {
            if (v.includes('"')) {
              return v.replace(/"/g, '""');
            }

            // CSV에서 string을 강제로 number로 바꾸는 오류가 있어서 이 작업을 해줌 (ex. 알파벳 E를 exponent로 인식 등)
            return '=""' + v + '""';
          }

          return v;
        });

        result.push(processedArrFromRow);
      });

      return result;
    })();

    return [
      ...metaInfoRow,
      ...(processedGroupedHeadRow.length ? [processedGroupedHeadRow] : []),
      headRow,
      ...dataRows,
    ];
  }, [headCells, groupedHeadRow, csvDownloadInfo]);

  return (
    <>
      {!csvDownloadInfo.rowsForCSVDownload?.length ? (
        <Button
          variant="outlined"
          size="medium"
          startIcon={<FileDownloadOutlined />}
          disabled={!csvDownloadInfo.rowsForCSVDownload?.length}
        >
          목록받기
        </Button>
      ) : (
        <CSVLink data={csvData} filename={csvDownloadInfo.filename}>
          <Button
            variant="outlined"
            size="medium"
            startIcon={<FileDownloadOutlined />}
          >
            목록받기
          </Button>
        </CSVLink>
      )}
    </>
  );
}
