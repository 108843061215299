import { useCallback, useState } from "react";
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { blue } from "@mui/material/colors";

import ADMIN_SETTLEMENT_QUERY from "@sellernote/_shared/src/queries/forwarding/ADMIN_SETTLEMENT_QUERY";
import { Currency } from "@sellernote/_shared/src/types/common/common";
import { Comment } from "@sellernote/_shared/src/types/forwarding/trello";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";
import TextField from "@sellernote/_shared-for-admin/src/components/TextField";
import useUploadResponseSnackBar from "@sellernote/_shared-for-admin/src/hooks/common/useUploadResponseSnackBar";

function DepositCommentModal({
  showsDepositCommentModal,
  handleModalClose,
  paymentInvoiceId,
  amount,
  name,
  currency,
  commentData,
  refetchListData,
}: {
  showsDepositCommentModal: boolean;
  handleModalClose: () => void;
  paymentInvoiceId: number;
  amount: number;
  name: string;
  currency: Currency;
  commentData: Comment[] | null;
  refetchListData: () => void;
}) {
  const [memo, setMemo] = useState("");

  const { mutate: registerFinancialMemo } =
    ADMIN_SETTLEMENT_QUERY.useRegisterFinancialMemo();

  const {
    UploadResponseSnackBar,
    setShowsErrorSnackBar,
    setShowsSuccessSnackBar,
  } = useUploadResponseSnackBar();

  const handleMemoRegister = useCallback(() => {
    registerFinancialMemo(
      {
        paymentInvoiceId,
        body: memo,
      },
      {
        onSuccess: () => {
          setShowsSuccessSnackBar(true);
          setMemo("");
          refetchListData();
        },

        onError: () => {
          setShowsErrorSnackBar(true);
        },
      }
    );
  }, [
    memo,
    paymentInvoiceId,
    refetchListData,
    registerFinancialMemo,
    setShowsErrorSnackBar,
    setShowsSuccessSnackBar,
  ]);

  return (
    <Modal
      isOpened={showsDepositCommentModal}
      handleClose={handleModalClose}
      modalBody={
        <Grid container spacing={2} direction="column">
          <Grid item>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ color: blue[700], textAlign: "center" }}
            >
              메모
            </Typography>
          </Grid>

          <Grid container item justifyContent={"flex-start"}>
            <Grid item>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>입금자명</TableCell>

                      <TableCell>{name}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell>입금액</TableCell>

                      <TableCell>{`${currency} ${toThousandUnitFormat(
                        amount
                      )}`}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>

          <Grid item>
            <TextField
              placeholder="메모를 남겨주세요"
              sx={{ width: 400 }}
              multiline={true}
              size="small"
              value={memo}
              onChange={(e) => setMemo(e.target.value)}
            />

            <Button onClick={handleMemoRegister} variant="contained">
              등록
            </Button>
          </Grid>

          <Grid item>
            <List>
              {commentData?.map((n, index) => {
                return (
                  <ListItem key={index} disablePadding>
                    <ListItemIcon>{n.id}</ListItemIcon>

                    <ListItemText
                      sx={{ marginLeft: "16px" }}
                      primary={n.comment}
                      secondary={n.date}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Grid>

          {UploadResponseSnackBar}
        </Grid>
      }
    />
  );
}

export default DepositCommentModal;
