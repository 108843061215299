/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  EffectCallback,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  DefaultRenderer,
  GridAlgorithm,
  MarkerClusterer,
  SuperClusterAlgorithm,
} from "@googlemaps/markerclusterer";
import { isLatLngLiteral } from "@googlemaps/typescript-guards";
import { createCustomEqual } from "fast-equals";

import { useCheckIsMobile } from "../../../../utils/common/hook";

import { ImageTypeIconInfo, SvgTypeIconInfo } from "..";
import { MapProps } from ".";

// 전체 지도의 렌더링과 구성은 공식문서 참고
// https://developers.google.com/maps/documentation/javascript/react-map
export default function useGoogleMap({
  markerInfoList,
  polylineInfo,
  setMarkerCluster,
  onGoogleApiLoaded,
  isAutoZoom,
  ...options
}: MapProps) {
  const { isMobile } = useCheckIsMobile();

  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();

  // 이전에 열린 infoWindow 가 닫히게 하기 위해 infoWindow 를 저장하는 변수
  let prevInfoWindow: google.maps.InfoWindow | undefined = undefined;

  const setInfoWindow = useCallback(
    ({
      infoWindow,
      content,
      map,
      marker,
    }: {
      infoWindow: google.maps.InfoWindow;
      content: string;
      map?: google.maps.Map;
      marker: google.maps.Marker;
    }) => {
      if (!map) return;

      if (!content) return;

      if (prevInfoWindow) {
        prevInfoWindow.close();
      }

      infoWindow.setContent(content);

      google.maps.event.addListenerOnce(map, "idle", function () {
        infoWindow.open(map, marker);
      });

      prevInfoWindow = infoWindow;
    },
    []
  );

  const markers = useMemo(() => {
    if (!markerInfoList) return;

    return markerInfoList.map(
      (
        { lat, lng, label, iconInfo, infoWindowData, zIndex, pixelOffset },
        i
      ) => {
        const position = { lat, lng };

        const getIcon = (iconInfo: ImageTypeIconInfo | SvgTypeIconInfo) => {
          if (iconInfo.type === "image")
            return {
              url: iconInfo.url,
              scaledSize: new google.maps.Size(iconInfo.width, iconInfo.height),
            };

          if (iconInfo.type === "svg")
            return {
              path: iconInfo.path,
              strokeColor: iconInfo.strokeColor,
              strokeWeight: iconInfo.strokeWeight,
              scale: iconInfo.scale,
              fillColor: iconInfo.fillColor,
              fillOpacity: iconInfo.fillOpacity,
              rotation: iconInfo.rotation,
            };

          return;
        };

        const marker = new google.maps.Marker({
          position,
          label,
          ...(iconInfo ? { icon: getIcon(iconInfo) } : {}),
        });

        // 마커에 나타나는 정보창
        const infoWindow = new google.maps.InfoWindow({
          content: "",
          disableAutoPan: true,
          zIndex,
          pixelOffset,
        });

        // 항시 보이는 타입
        if (infoWindowData?.type === "visible") {
          setInfoWindow({
            infoWindow,
            content: infoWindowData?.content,
            map,
            marker,
          });
        }

        // 클릭 했을 때에만 보이는 타입
        if (infoWindowData?.type === "click") {
          marker.addListener("click", () => {
            setInfoWindow({
              infoWindow,
              content: infoWindowData?.content,
              map,
              marker,
            });

            const markerPosition = marker.getPosition();

            if (map && marker && markerPosition) {
              map.addListener("click", (e) => {
                infoWindow.close();
              });

              map.panTo(markerPosition);

              if (isMobile) {
                map.panBy(0, -70);
              }
            }
          });
        }

        return marker;
      }
    );
  }, [isMobile, map, markerInfoList, setInfoWindow]);

  // 좌표 값으로 깊은 비교
  const checkDeepEqualsForMaps = createCustomEqual(
    // fast-equals 라이브러리 때문에 나는 type error 무시
    // @ts-ignore
    (deepEqual) => (a: any, b: any) => {
      if (
        isLatLngLiteral(a) ||
        a instanceof google.maps.LatLng ||
        isLatLngLiteral(b) ||
        b instanceof google.maps.LatLng
      ) {
        return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
      }

      // @ts-ignore
      return deepEqual(a, b);
    }
  );

  const useDeepCompareMemoize = (value: unknown) => {
    const ref = useRef<unknown>();

    if (!checkDeepEqualsForMaps(value, ref.current)) {
      ref.current = value;
    }

    return ref.current;
  };

  const useDeepCompareEffectForMaps = (
    callback: EffectCallback,
    dependencies: unknown[]
  ) => {
    useEffect(callback, [...dependencies.map(useDeepCompareMemoize), callback]);
  };

  // 깊은 비교를 해서 Map 을 리렌더링.
  // https://developers.google.com/maps/documentation/javascript/react-map#map-component-props
  // https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);

      const defaultRenderer = new DefaultRenderer();

      const defaultClusteringGridAlgorithm = new SuperClusterAlgorithm({
        maxZoom: 6,
        radius: 120,
      });

      // 클러스터링 없이 marker 표기만 할때, grid 옵션의 maxZoom 을 0으로 표기.
      const noClusteringGridAlgorithm = new GridAlgorithm({
        maxZoom: 0,
      });

      // Marker Clustering 설정
      // https://developers.google.com/maps/documentation/javascript/marker-clustering 참고
      new MarkerClusterer({
        markers,
        map,

        algorithm: setMarkerCluster
          ? defaultClusteringGridAlgorithm
          : noClusteringGridAlgorithm,

        // label 등 개별 설정을 위해서 render 를 사용하고, 기본 설정은 유지하기 위해서 위의 defaultRenderer 사용
        // https://googlemaps.github.io/js-markerclusterer/interfaces/MarkerClustererOptions.html
        renderer: {
          render: (cluster, stats) => {
            const markerClusterOptions = setMarkerCluster
              ? setMarkerCluster(cluster)
              : undefined;

            return new google.maps.Marker({
              ...defaultRenderer.render(cluster, stats),
              ...markerClusterOptions,
            });
          },
        },
      });

      // Polyline 설정
      if (polylineInfo) {
        const shipPath = new google.maps.Polyline(polylineInfo);

        shipPath.setMap(map);
      }
    }

    // 마커 자동 줌 설정
    if (isAutoZoom && map && markers && markers.length > 0) {
      const bounds = new google.maps.LatLngBounds();

      markers.forEach((marker) => {
        // 타입스크립트에서 marker.getPosition()을 undefined로 확인해 non-null 단언 연산자 추가
        if (marker && marker.getPosition()) {
          bounds.extend(marker.getPosition()!);
        }
      });

      map.fitBounds(bounds);
    }
  }, [map, options]);

  // 지도 기초정보 렌더링에 필요한 부분
  // https://developers.google.com/maps/documentation/javascript/react-map#add-map
  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [map]);

  return { ref };
}
