import styled from "styled-components";

const container = styled.div`
  > .button-container {
    margin-top: 15px;
    display: flex;
    justify-content: center;

    > .save-button {
      margin-right: 8px;
    }

    > .request-button {
      color: #ffffff;
      background-color: #73d13d;
    }
  }
`;

export default {
  container,
};
