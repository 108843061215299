import { Trans } from "react-i18next";
import { useSetAtom } from "jotai";
import { useSetRecoilState } from "recoil";

import {
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "@sellernote/_shared/src/services/query";
import { FORWARDING_ADMIN_AUTH_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/auth";

import { clearLocalStorageForForwardingShipdaWeb } from "../../services/browserStorage";
import { ResponseFailureInfo } from "../../types/common/common";
import { TransType } from "../../types/common/i18n";
import { makeUserLoginFailedMessage } from "../../utils/forwarding/auth";

import {
  ADMIN_LOGIN_REQ,
  ADMIN_LOGIN_RES,
  CHECK_REGISTERED_EMAIL_REQ,
  CHECK_REGISTERED_EMAIL_REQ_PATH_PARAMS,
  CHECK_REGISTERED_EMAIL_RES,
  CONFIRM_OTP_REQ,
  CONFIRM_OTP_RES,
  GENERATE_OTP_REQ,
  GENERATE_OTP_RES,
  PARTNER_ADMIN_LOGIN_REQ,
  PARTNER_ADMIN_LOGIN_RES,
  REGISTER_USER_REQ,
  REGISTER_USER_RES,
  USER_LOGIN_REQ,
  USER_LOGIN_RES,
} from "../../api-interfaces/shipda-api/auth";
import { APP_NAME } from "../../constants";
import { FORWARDING_AUTH_ATOMS } from "../../states/forwarding/auth";

const AUTH_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/AUTH_QUERY" }] as const,

  checkValidRegion: () =>
    [
      {
        ...AUTH_QUERY_KEY_GEN.all()[0],
        entity: "checkValidRegion",
      },
    ] as const,

  checkDuplicatedEmail: (email: string) =>
    [
      {
        ...AUTH_QUERY_KEY_GEN.all()[0],
        entity: "checkDuplicatedEmail",
        email,
      },
    ] as const,
};

function useAdminLogin() {
  const setLoggedIn = useSetAtom(FORWARDING_ADMIN_AUTH_ATOMS.ADMIN_LOGGED_IN);

  const mutation = useAppMutation<ADMIN_LOGIN_REQ, ADMIN_LOGIN_RES>({
    requestOptions: {
      method: "post",
      path: "/auth/login",
      apiType: "ShipdaAdminDefaultNew",
    },

    onSuccess: (data) => {
      const { accessToken, refreshToken, authority, user } = data;

      const { id, accountId } = user;

      // 보안을 고려하여 선택적으로 저장
      const currentUser = {
        id,
        authority,
        accountId,
      };

      window.localStorage.setItem("currentUser", JSON.stringify(currentUser));
      window.localStorage.setItem("accessToken", accessToken);
      window.localStorage.setItem("refreshToken", refreshToken);

      setLoggedIn(true);
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        let title = "";

        if (failureInfo) {
          const { code, error } = failureInfo;

          switch (code) {
            case 400:
            case 404: {
              title = "ID 혹은 비밀번호가 틀렸습니다.";
              break;
            }

            default: {
              title = "로그인 중 오류가 발생했습니다";
              break;
            }
          }
        }

        return {
          title,
          messageType: "titleOnly",
        };
      },
    },
  });

  return { ...mutation };
}

function usePartnerAdminLogin() {
  const setLoggedIn = useSetAtom(
    FORWARDING_ADMIN_AUTH_ATOMS.PARTNER_ADMIN_LOGGED_IN
  );

  const mutation = useAppMutation<
    PARTNER_ADMIN_LOGIN_REQ,
    PARTNER_ADMIN_LOGIN_RES
  >({
    requestOptions: {
      method: "post",
      path: "/auth/customs/login", // 아직은 customs로 관세사 로그인 전용으로 처리 (추후 다른 type의 파트너가 추가되는 경우 /auth/:partnerType/login 로그인을 지원할 예정)
      apiType: "ShipdaAdminDefaultNew",
    },

    onSuccess: async (data) => {
      const { accessToken, refreshToken, user } = data;

      // 보안을 고려하여 선택적으로 저장
      const currentUser = {
        id: user.id,
        name: user.name,
        partnerCompanyId: user.partnerCompanyId,
        company: user.company,
      };

      window.localStorage.setItem("currentUser", JSON.stringify(currentUser));
      window.localStorage.setItem("accessToken", accessToken);
      window.localStorage.setItem("refreshToken", refreshToken);

      setLoggedIn(true);
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        let title = "";

        if (failureInfo) {
          const { code, error } = failureInfo;

          switch (code) {
            case 400:
            case 404: {
              title = "ID 혹은 비밀번호가 틀렸습니다.";
              break;
            }

            default: {
              title = "로그인 중 오류가 발생했습니다";
              break;
            }
          }
        }

        return {
          title,
          messageType: "titleOnly",
        };
      },
    },
  });

  return { ...mutation };
}

function useUserLogin({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: USER_LOGIN_RES) => void;
  onError?: (failureInfo: ResponseFailureInfo | undefined) => void;
}) {
  const setLoggedIn = useSetRecoilState(FORWARDING_AUTH_ATOMS.USER_LOGGED_IN);

  const mutation = useAppMutation<USER_LOGIN_REQ, USER_LOGIN_RES>({
    requestOptions: {
      method: "post",
      path: "/auth",
      apiType: "ShipdaDefaultNew",
    },

    onSuccess: (data, req) => {
      setLoggedIn(true);

      const { accessToken, refreshToken, userId } = data;

      if (req._autoLogin) {
        window.localStorage.setItem("accessToken", accessToken);
        window.localStorage.setItem("refreshToken", refreshToken);
      } else {
        window.sessionStorage.setItem("accessToken", accessToken);
        window.sessionStorage.setItem("refreshToken", refreshToken);
      }

      if (onSuccess) onSuccess(data);
    },

    onError: (failureInfo) => {
      if (onError) {
        onError(failureInfo);
      }

      // 로그인에 실패하는 경우 이후의 UI오류가능상을 방지하기 위해 로그인 관련 data를 초기화
      if (APP_NAME === "shipda-kr" || APP_NAME === "shipda-sg") {
        clearLocalStorageForForwardingShipdaWeb();
      } else {
        window.localStorage.clear();
      }

      window.sessionStorage.clear();
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        return {
          title: makeUserLoginFailedMessage({ failureInfo }),
          messageType: "titleOnly",
        };
      },
    },

    loadingLabel: <Trans i18nKey="auth-new:로그인_로그인중" />,
  });

  return { ...mutation };
}

function useCheckRegisteredEmail({
  enabled,
  Trans,
  onSuccess,
}: {
  enabled?: boolean;
  Trans: TransType;
  onSuccess: (res: CHECK_REGISTERED_EMAIL_RES) => void;
}) {
  const mutation = useAppMutation<
    CHECK_REGISTERED_EMAIL_REQ,
    CHECK_REGISTERED_EMAIL_RES,
    CHECK_REGISTERED_EMAIL_REQ_PATH_PARAMS
  >({
    requestOptions: {
      method: "get",
      path: ({ email }) => `/users/${email}/registration`,
      apiType: "ShipdaDefaultNew",
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),

    onSuccess,

    failureModalInfo: {
      customizeMessage: () => ({
        title: <Trans i18nKey="main:subscription.failureTitle" />,
      }),
    },
  });

  return { ...mutation };
}

function useCheckDuplicatedEmail({
  email,
  enabled = false,
  onSuccess,
  onError,
  onSettled,
}: {
  email: string;
  enabled?: boolean;
  onSuccess?: () => void;
  onError?: (error: ResponseFailureInfo | undefined) => void;
  onSettled?: () => void;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof AUTH_QUERY_KEY_GEN.checkDuplicatedEmail>,
    unknown
  >({
    queryKey: AUTH_QUERY_KEY_GEN.checkDuplicatedEmail(email),
    requestOptions: {
      method: "get",
      path: "/auth/register",
      params: {
        email,
      },
      apiType: "ShipdaDefaultNew",
    },

    enabled,

    onSuccess,

    onError: (error) => {
      onError?.(error);
    },

    onSettled,
  });

  return { ...queryResult };
}

function useRegisterUser({
  onSuccess,
  onError,
  Trans,
}: {
  onSuccess?: (res: REGISTER_USER_RES) => void;
  onError?: () => void;
  Trans: TransType;
}) {
  const mutation = useAppMutation<REGISTER_USER_REQ, REGISTER_USER_RES>({
    requestOptions: {
      method: "post",
      path: "/auth/register",
      apiType: "ShipdaDefaultNew",
    },

    onSuccess,
    onError,

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.error === "phone already in use") {
          return {
            title: (
              <Trans i18nKey="auth-new:API_회원가입_등록된_번호_서버_에러_다시_시도" />
            ),
            messageType: "titleOnly",
          };
        }

        if (failureInfo?.error === "email already in use") {
          return {
            title: (
              <Trans i18nKey="auth-new:API_회원가입_등록된_이메일_에러_다시_시도" />
            ),
            messageType: "titleOnly",
          };
        }

        return {
          title: "회원가입 중 오류가 발생했습니다.",
          messageType: "titleOnly",
        };
      },
    },
  });

  return { ...mutation };
}

function useGenerateOTP({
  onSuccess,
  onError,
}: {
  onSuccess?: (res: GENERATE_OTP_RES) => void;
  onError?: (error: ResponseFailureInfo | undefined) => void;
}) {
  const mutation = useAppMutation<GENERATE_OTP_REQ, GENERATE_OTP_RES>({
    requestOptions: {
      method: "post",
      path: "/verification",
      apiType: "ShipdaDefaultNew",
    },

    onSuccess,

    onError: (failureInfo) => {
      onError?.(failureInfo);
    },

    failureModalInfo: {
      customizeMessage: (failureInfo) => {
        if (failureInfo?.errorCode === "E212") {
          return {
            title: "이미 가입한 전화번호 입니다.",
            messageType: "titleOnly",
          };
        }

        return {
          title: "전화번호 인증 중 오류가 발생했습니다.",
          messageType: "titleOnly",
        };
      },
    },
  });

  return { ...mutation };
}

function useConfirmOTP({
  onSuccess,
  onError,
}: {
  onSuccess?: (res: CONFIRM_OTP_RES) => void;
  onError?: (failureInfo: ResponseFailureInfo | undefined) => void;
}) {
  const mutation = useAppMutation<CONFIRM_OTP_REQ, CONFIRM_OTP_RES>({
    requestOptions: {
      method: "post",
      path: "/verification/confirm",
      apiType: "ShipdaDefaultNew",
    },

    onSuccess,
    onError,
  });

  return { ...mutation };
}

function useCheckValidRegion({ enabled }: { enabled: boolean }) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof AUTH_QUERY_KEY_GEN.checkValidRegion>,
    boolean
  >({
    queryKey: AUTH_QUERY_KEY_GEN.checkValidRegion(),
    requestOptions: {
      method: "get",
      path: "/auth/validate/region",
      apiType: "ShipdaDefaultNew",
    },

    ...(typeof enabled === "boolean" ? { enabled } : {}),
  });

  return { ...queryResult };
}

const AUTH_QUERY = {
  useAdminLogin,
  usePartnerAdminLogin,
  useUserLogin,
  useCheckRegisteredEmail,
  useCheckDuplicatedEmail,
  useRegisterUser,
  useGenerateOTP,
  useConfirmOTP,
  useCheckValidRegion,
};

export default AUTH_QUERY;
