import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { RecoilRoot } from "recoil";
import Router from "Router";

import {
  IS_READY_FOR_MSW_TOOL,
  IS_UNDER_LOCAL_DEVELOPMENT,
} from "@sellernote/_shared/src/constants";
import {
  I18N_NAMESPACE_LIST_FOR_DESIGN_SYSTEM,
  initI18nInstanceForDesignSystem,
} from "@sellernote/_shared/src/i18n/i18nForDesignSystem";
import MSWTool from "@sellernote/_shared/src/mocks/MSWTool";
import { JotaiDevtools } from "@sellernote/_shared/src/services/jotai";
import { REACT_QUERY_CLIENT_CONFIG } from "@sellernote/_shared/src/services/query";
import { printBuildInfo } from "@sellernote/_shared/src/utils/common/etc";
import GlobalStyle from "@sellernote/_sds-v1/src/styles/global";

function App() {
  useEffect(() => {
    printBuildInfo();
  }, []);

  const [queryClient] = useState(
    () => new QueryClient(REACT_QUERY_CLIENT_CONFIG)
  );

  const { t } = useTranslation(I18N_NAMESPACE_LIST_FOR_DESIGN_SYSTEM);
  initI18nInstanceForDesignSystem({ Trans, t });

  return (
    <>
      <RecoilRoot>
        {IS_READY_FOR_MSW_TOOL && <MSWTool type="web" />}

        <QueryClientProvider client={queryClient}>
          {IS_UNDER_LOCAL_DEVELOPMENT && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}

          <JotaiDevtools>
            <Router>
              <GlobalStyle />
            </Router>
          </JotaiDevtools>
        </QueryClientProvider>
      </RecoilRoot>
    </>
  );
}

export default App;
