import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import {
  ExportTrelloList,
  PartnerTrelloList,
  TrelloBidList,
} from "@sellernote/_shared/src/types/forwarding/trello";

import CustomStatusChip from "./components/CustomStatusChip";

import koreaFlag from "../../images/regionFlag/korea.webp";
import singaporeFlag from "../../images/regionFlag/singapore.webp";

const getTrelloCardTitle = (
  cardData: TrelloBidList | ExportTrelloList | PartnerTrelloList
) => {
  return (
    <Box>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          sx={{
            fontSize: 14,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            fontWeight: "bold",
          }}
        >
          {cardData.team.company ?? "-"}
        </Typography>

        <img
          src={cardData.region === "KR" ? koreaFlag : singaporeFlag}
          alt={cardData.region === "KR" ? "KR" : "SG"}
          style={{ width: "22px", height: "15px" }}
        />
      </div>

      <Typography
        sx={{
          fontSize: 12,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        ({cardData.team.name ?? "-"})
      </Typography>
    </Box>
  );
};

const getTrelloCardCommonBody = (
  trelloListData: TrelloBidList | ExportTrelloList | PartnerTrelloList
) => {
  return (
    <>
      <Typography sx={{ fontSize: 12 }} variant="body2">
        {trelloListData.management.memo}
      </Typography>

      <Typography
        variant="body2"
        component={"div"}
        sx={{ fontWeight: "bold", color: grey[700] }}
      >
        {trelloListData.id}{" "}
        {trelloListData?.poNumber &&
          trelloListData.poNumber.length > 0 &&
          `${
            trelloListData.poNumber.length > 1
              ? `/ ${trelloListData.poNumber[0]} 외 ${trelloListData.poNumber.length}`
              : `/ ${trelloListData.poNumber[0]}`
          }`}
      </Typography>

      {trelloListData.management.ETA && trelloListData.management.ETD && (
        <Typography
          variant="body2"
          sx={{ fontWeight: "bold", color: grey[700] }}
        >
          {trelloListData.management.ETD} ~ {trelloListData.management.ETA}
        </Typography>
      )}
    </>
  );
};

const getTrelloCardCustomsChip = (
  cardData: TrelloBidList | PartnerTrelloList
) => {
  return <CustomStatusChip status={cardData.management.customsStatus} />;
};

const getServiceType = ({ pathname }: { pathname: string }) => {
  if (pathname.includes("consolidation")) return "consolidation";

  if (pathname.includes("export")) return "export";

  return "import";
};

export {
  getTrelloCardTitle,
  getTrelloCardCommonBody,
  getTrelloCardCustomsChip,
  getServiceType,
};
