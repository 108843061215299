import { useState } from "react";

/**
 * components > ResponseSnackbar와 함께 사용
 */
function useHandleResponseSnackbar() {
  const [showsSnackbar, setShowsSnackbar] = useState(false);

  const handleSnackbarOpen = () => setShowsSnackbar(true);
  const handleSnackbarClose = () => setShowsSnackbar(false);

  return {
    showsSnackbar,
    setShowsSnackbar,
    handleSnackbarOpen,
    handleSnackbarClose,
  };
}

export default useHandleResponseSnackbar;
