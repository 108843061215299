import {
  Children,
  Dispatch,
  isValidElement,
  ReactNode,
  SetStateAction,
} from "react";

import { SellernoteAPIType } from "../../types/common/common";

import {
  APP_BUILD_INFO,
  APP_TYPE,
  IS_UNDER_PRODUCTION,
  LOCAL_PRINTER_URL,
  NETWORK_PRINTER_FOR_CJ_URL,
  NETWORK_PRINTER_FOR_HANJIN_AND_OVERSEAS_URL,
} from "../../constants";
import { toFormattedDate } from "./date";
import regEx from "./regEx";

/**
 * nested key로 object를 업데이트하기 위해 만든 함수
 * object와 key받고, 해당 key(nested key지원)의 value가 수정된 object를 반환함
 * (원본 object를 수정하고 반환함에 유의)
 * @param obj 객체
 * @param keyPath string. nested key의 경우 '.'으로 구분
 * @param value key에 할당하고자하는 value
 */
export function getUpdatedObject(obj: any, keyPath: string, value: any) {
  const keyArr = keyPath.split(".");
  let current: any = obj;
  const lastIndex = keyArr.length - 1;

  for (let i = 0; i <= lastIndex; i++) {
    if (!current) return obj;

    if (i === lastIndex) {
      current[keyArr[i]] = value;
      return obj;
    }

    if (typeof current[keyArr[i]] !== "number" && !current[keyArr[i]]) {
      current[keyArr[i]] = {};
    }
    current = current[keyArr[i]];
  }

  return obj;
}

/**
 * 브라우저에서 uri를 통해 다운로드할 때사용
 *
 * 결과파일 이름 셋팅은 안 되는 경우도 있음
 */
export function downloadFromURI(
  document: Document,
  uri: string,
  fileName?: string
) {
  const a = document.createElement("a");
  a.href = uri;
  if (fileName) {
    a.download = fileName;
  }
  document.body.appendChild(a); // IE때문에 body에 추가해줘야함
  a.click();
  a.remove();
}

/**
 * 외부 script를 로드한 후, 그 script에 의존하는 코드(callback)를 실행한다.
 * 이미 로드되있다면, 새로 로드하지 않고 코드(callback)를 실행한다.
 * @param scriptUrl
 * @param callback
 */
export function loadExternalScriptAndRunDependentCallback(
  scriptUrl: string,
  callback: () => void
) {
  const allScripts = document.getElementsByTagName("script");

  let targetScript: any = null;
  for (let i = 0; i < allScripts.length; i++) {
    const item = allScripts.item(i);
    if (item && item.src === scriptUrl) {
      targetScript = item;
    }
  }

  if (targetScript) {
    callback();
  } else {
    targetScript = document.createElement("script");
    targetScript.setAttribute("src", scriptUrl);
    document.head.appendChild(targetScript);
    targetScript.onload = callback;
  }
}

export function setOneIfHaveNoChoice<T>(
  optionList: T[] | undefined,
  setCallback: (v: T) => void
) {
  if (!optionList || !optionList.length) {
    return;
  }

  if (optionList.length === 1) {
    setCallback(optionList[0]);
  }
}

/**
 * 총 페이지 개수를 계산하여 반환
 * @param pageUnit - 페이지 당 아이템 개수
 * @param totalSize - 총 아이템 개수
 * @returns 총 페이지 개수
 */
export function getPageSize(pageUnit: number, totalSize?: number) {
  if (pageUnit && totalSize) {
    let pageSize = totalSize / pageUnit;

    if (pageSize % 1 > 0) {
      pageSize = Math.floor(totalSize / pageUnit) + 1;
    }

    return pageSize;
  }

  return 1;
}

/**
 * 빈 객체나 빈 배열 체크.
 */
export function isEmptyObjectOrArray(
  param: Array<any> | { [key: string]: any }
) {
  return Object.keys(param).length === 0;
}

export function isNotEmptyObjectOrArray(
  param: Array<any> | { [key: string]: any }
) {
  return !isEmptyObjectOrArray(param);
}

/**
 * 타입을 문자열로 반환
 * 'String', 'Number', 'Boolean', 'Object'.. 등등 앞글자 대문자로 반환
 */
export function getTypeToString(value: any) {
  return Object.prototype.toString.call(value).slice(8, -1);
}

/**
 * 객체의 빈 속성 제거
 */
export function removeEmptyPropertiesOfObject(object: { [key: string]: any }) {
  Object.keys(object).forEach((key) => {
    getTypeToString(object[key]) === "Object"
      ? isEmptyObjectOrArray(object[key]) && delete object[key]
      : !object[key] && delete object[key];
  });

  return object;
}

/**
 * 현재는 Contents의 경우만 baseUrl이 구분되지만, 추후 더 늘아늘 것이라고 함
 */
export function getBaseURLByAPIType(apiType?: SellernoteAPIType) {
  if (apiType === "LocalPrinter") {
    return LOCAL_PRINTER_URL;
  }

  if (apiType === "NetworkPrinterForHanjinAndOverseas") {
    return NETWORK_PRINTER_FOR_HANJIN_AND_OVERSEAS_URL;
  }

  if (apiType === "NetworkPrinterForCJ") {
    return NETWORK_PRINTER_FOR_CJ_URL;
  }

  if (APP_TYPE === "ShipDa") {
    switch (apiType) {
      case "Contents": {
        return process.env.NEXT_PUBLIC_CONTENTS_API_URL;
      }
      case "BofulDefault": {
        return process.env.NEXT_PUBLIC_BOFUL_API_URL;
      }
      case "BofulDashboard": {
        return process.env.NEXT_PUBLIC_BOFUL_DASHBOARD_API_URL;
      }
      case "ShipdaDefaultNew": {
        return process.env.NEXT_PUBLIC_API_URL_NEW;
      }
      case "ShipdaAdminDefault": {
        return process.env.REACT_APP_BASE_URL;
      }
      case "ShipdaAdminDefaultNew": {
        return process.env.REACT_APP_ADMIN_URL;
      }
      default: {
        return process.env.NEXT_PUBLIC_API_URL;
      }
    }
  }

  if (APP_TYPE === "Boful" || APP_TYPE === "BofulMobile") {
    return process.env.REACT_APP_API_URL;
  }

  if (APP_TYPE === "ContentAdmin") {
    switch (apiType) {
      case "Contents": {
        return process.env.REACT_APP_CONTENT_API_URL;
      }
      case "ShipdaAdminDefaultNew": {
        return process.env.REACT_APP_ADMIN_URL;
      }
      default: {
        return process.env.REACT_APP_CONTENT_API_URL;
      }
    }
  }
}

/**
 * 객체 값 비교.
 *
 */
export function checkEqualObject(
  obj1: { [key: string]: any },
  obj2: { [key: string]: any }
) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

/**
 * 두 배열이 동일 요소들을 가지고 있는지 체크 (배열 내 요소 순서 무관)
 */
export function checkEqualArray(arr1: unknown[], arr2: unknown[]) {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  return sortedArr1.every((val, index) => val === sortedArr2[index]);
}

/**
 * 객체리스트 배열에서 특정 속성으로 분류된 객체 얻기
 * const arr = [ { name: 'a', age: 21 }, { name: 'b', age: 21 }, { name: 'c', age: 20}]
 * => getGroupedObjectByProperty(arr, 'age');
 * {
 *   21: [ { name: 'a', age: 21 }, { name: 'b', age: 21 } ],
 *   20: [ { name: 'c', age: 20} ]
 * }
 */
export function getGroupedObjectByProperty(
  objectArray: any[],
  property: string | symbol
) {
  return objectArray.reduce(function (acc, obj) {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);

    return acc;
  }, {});
}

export const setFalsyValueToNull = (value?: string | number | null) =>
  !value ? null : value;

// TODO : 적용되어 있는 부분 handleInputFalsyValueToNullChange 함수로 변경하고 삭제할 것.
export function handleChangeInputState<T>({
  key,
  value,
  state,
  setState,
}: {
  key: keyof T;
  value?: string | number | null;
  state: T;
  setState: Dispatch<SetStateAction<T>>;
}) {
  return setState({ ...state, [key]: setFalsyValueToNull(value) });
}

export const checkIsOfficeFile = (fileExtension: string | undefined) => {
  switch (fileExtension) {
    case "doc":
    case "docx":
    case "ppt":
    case "pptx":
    case "xls":
    case "xlsx":
    case "rtf":
    case "txt":
      return true;
    default:
      return false;
  }
};

/**
 * find 등 배열 메서드 사용 시, undefined 나 null 을 반환할 가능성이 전혀 없음에도
 * 반환 타입이 T | undefined 으로 고정되어 있으므로, undefined를 미리 체크하여 T 만 반환하는 것을 보장하는 헬퍼 함수.
 * @param argument
 * @param message
 * @returns
 */
export function ensureResult<T>(
  argument: T | undefined | null,
  message = "This value was promised to be there."
): T {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }

  return argument;
}

/**
 * data(object) 내의 value 의 타입으로 key 이름을 찾는 함수.
 * @param data
 * @param typeOfValue
 * @returns
 */
export function findKeyNameAsTypeOfValue<T extends object>(
  data: T,
  typeOfValue: string
) {
  return ensureResult(
    (Object.keys(data) as Array<keyof T>).find(
      (key: keyof T) => typeof data[key] === typeOfValue
    )
  );
}

export const sanitizeSpecialCharacters = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  setState: (value: React.SetStateAction<string>) => void
) => {
  const regExp = regEx.special;

  if (regExp.test(event.target.value)) {
    return setState(event.target.value.replace(regExp, ""));
  } else {
    setState(event.target.value);
  }
};

/**
 * Form에 InputText가 여러 개 있는 경우 setValue할 때 사용하기 위한 유틸함수
 */
export const handleFormChange =
  <FormState, Key extends keyof FormState>(
    setFormState: Dispatch<SetStateAction<FormState>>
  ) =>
  (key: Key) =>
  (value: FormState[Key]) =>
    setFormState((prevFormState) => ({ ...prevFormState, [key]: value }));

/**
 * InputText 상태에 falsy 값을 null 처리하기 위한 유틸함수
 */
export const handleInputFalsyValueToNullChange =
  <InputState, Key extends keyof InputState>(
    setInputState: Dispatch<SetStateAction<InputState>>
  ) =>
  (key: Key) =>
  (value: string | number | undefined | null) =>
    setInputState((prevInputState) => ({
      ...prevInputState,
      [key]: setFalsyValueToNull(value),
    }));

export const noop = () => {};

export function setUndefinedToNull<Value extends string | number>(
  value: Value | undefined
): Value | null {
  if (value === undefined) {
    return null;
  }

  return value;
}

/**
 *
 * @param value boolean 조건 값
 * boolean 조건을 예, 아니오, "-"로 리턴해준다.
 */
export function changeBooleanValueToKr(
  value: boolean | null | undefined,
  showsOx?: boolean
) {
  if (value === undefined || value === null) {
    return "-";
  }
  if (value) {
    return showsOx ? "O" : "예";
  }
  return showsOx ? "X" : "아니오";
}

export function getGroupedSearchListByProperty({
  pageType,
  searchList,
}: {
  pageType: "singleSku" | "groupSku" | "material" | "receiving" | "shipping";
  searchList: {
    searchKind: string;
    searchTerm: string;
  }[];
}) {
  return searchList.reduce<{
    [key: string]: string[];
  }>((acc, obj) => {
    const searchKind = (() => {
      if (obj.searchKind === "company") {
        return "companyNames";
      }

      if (pageType === "receiving" && obj.searchKind === "itemName") {
        return "productNames";
      }

      return `${obj.searchKind}s`;
    })();

    if (!acc[searchKind]) {
      return {
        ...acc,
        [searchKind]: [obj.searchTerm],
      };
    }

    return {
      ...acc,
      [searchKind]: [...acc[searchKind], obj.searchTerm],
    };
  }, {});
}

/**
 * 빌드정보를 console로 출력한다.
 * - (보안상) production 환경에서는 출력하지 않는다.
 */
export function printBuildInfo() {
  if (IS_UNDER_PRODUCTION) return;

  if (!APP_BUILD_INFO) return;

  console.debug("####################### BUILD INFO #######################");
  console.debug(
    `####### BuiltAt: ${toFormattedDate(
      APP_BUILD_INFO.builtAt,
      "YYYY.MM.DD HH:mm:ss Z"
    )}`
  );
  console.debug(`####### Branch: ${APP_BUILD_INFO.gitBranch}`);
  console.debug(`####### Commit ${APP_BUILD_INFO.gitCommitSha}`);
  console.debug("##########################################################");
}

export const delay = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const downloadFile = async (url: string) => {
  const a = document.createElement("a");
  a.href = url;
  a.style.display = "none";

  document.body.append(a);
  a.click();

  // multi-download 라이브러리 기준 크롬 이슈로 인해 딜레이를 줌
  await delay(100);

  a.remove();
};

/**
 * source) https://sindresorhus.com/multi-download/
 */
export const downloadMultiFile = async (urlList: string[]) => {
  for (const [index, url] of urlList.entries()) {
    // 다중 파일 다운로드 시 가장 마지막 요청 이외에는 취소되는 이슈로 인해 딜레이를 줌
    await delay(index * 1000);
    downloadFile(url);
  }
};

/**
 * children prop에 있는 특정 컴포넌트를 찾아주는 함수
 * @param children 부모 컴포넌트의 children prop
 * @param targetComponent children에서 찾으려고 하는 특정 컴포넌트
 * @returns children에서 targetComponent와 일치하는 특정 컴포넌트를 리턴.
 */
export function getTargetComponentFromChildren(
  children: ReactNode,
  targetComponent: JSX.Element
) {
  const targetComponentType = targetComponent.type;

  const childrenArray = Children.toArray(children);

  return childrenArray
    .filter(
      (child) => isValidElement(child) && child.type === targetComponentType
    )
    .slice(0, 2);
}

/**
 * 두개의 object를 합칩니다
 *
 * @param target 합쳐칠 object
 * @param source 합칠 object
 * @returns 합쳐진 object
 */
export function mergeObjects<TObject extends object, TSource extends object>(
  target: TObject,
  source: TSource
): TObject & TSource {
  const output: Partial<TObject> = { ...target };

  for (const [key, value] of Object.entries(source)) {
    if (value && typeof value === "object" && !Array.isArray(value)) {
      output[key as keyof TObject] = mergeObjects(
        target[key as keyof TObject] || {},
        value
      );
    } else {
      output[key as keyof TObject] = value;
    }
  }

  return output as TObject & TSource;
}
