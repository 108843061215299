import Styled from "./index.styles";

export type BorderType = "filled" | "stroked";

export type BadgeColorInfo = {
  background: string;
  border?: string;
  text: string;
};

export default function Badge({
  borderType,
  colorInfo,
  label,
  className,
}: {
  borderType: BorderType;
  colorInfo: BadgeColorInfo;
  label: string;
  className?: string;
}) {
  return (
    <Styled.container
      borderType={borderType}
      colorInfo={colorInfo}
      className={`${className ? className : ""} badge`}
    >
      {label}
    </Styled.container>
  );
}
