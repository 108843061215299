import React from "react";
import { Descriptions, Divider } from "antd";

import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";

interface Props {
  bidDetail: AdminBidDetail;
}

const ConsolidationTotalDesc = ({
  bidDetail,
}: {
  bidDetail: AdminBidDetail;
}) => {
  return (
    <div>
      <Divider />

      <Descriptions
        column={3}
        style={{ fontWeight: "bold" }}
        title={
          <div style={{ fontWeight: "bold", fontSize: 20 }}>물품 총합</div>
        }
      >
        <Descriptions.Item label="총 CBM">
          {bidDetail.totalCBM}
        </Descriptions.Item>
        <Descriptions.Item label="총 무게">
          {toThousandUnitFormat(bidDetail.totalWeight)}TON
        </Descriptions.Item>
        <Descriptions.Item label="총 R.TON">
          {bidDetail.supply}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default ConsolidationTotalDesc;
