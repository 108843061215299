import { Card, Descriptions, Divider } from "antd";

import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { calculateAdminBidCbm } from "@sellernote/_shared/src/utils/forwarding/bid";

interface Props {
  bidDetail: AdminBidDetail;
}

const ProductsItemsDesc = (props: Props) => {
  const { bidDetail } = props;

  const { productsInfo, freightType } = bidDetail;

  return (
    <div>
      <Card
        type="inner"
        title={
          freightType === "LCL" ? (
            <div>총 R.TON: {bidDetail.supply}</div>
          ) : (
            <div>총 C.W: {bidDetail.supply}</div>
          )
        }
        headStyle={{ color: "#1890FF" }}
      >
        {productsInfo.map((v, index) => {
          return (
            <div key={index.toString()}>
              <Descriptions contentStyle={{ fontWeight: "bold" }}>
                <Descriptions.Item label="품명">{v.name}</Descriptions.Item>

                <Descriptions.Item span={2} label="포장유형">
                  {v.packingType}
                </Descriptions.Item>

                <Descriptions.Item label="부피">
                  {v.horizontal
                    ? `${calculateAdminBidCbm(v)}CBM(${v.horizontal}x${
                        v.vertical
                      }x${v.height}) ${v.volumeUnit}`
                    : `${v.cbm}CBM`}
                </Descriptions.Item>

                <Descriptions.Item label="중량">
                  {v.weight}
                  {v.weightUnit}
                </Descriptions.Item>

                {v.horizontal && (
                  <Descriptions.Item label="수량">
                    {v.quantity}
                  </Descriptions.Item>
                )}

                <Descriptions.Item label="위험물">
                  {v.isDangerous ? "예" : "아니오"}
                </Descriptions.Item>

                {freightType === "AIR" && (
                  <Descriptions.Item label="냉동/냉장여부">
                    {v.needRefrigeration ? "예" : "아니오"}
                  </Descriptions.Item>
                )}

                <Descriptions.Item label="2단적재">
                  {v.isDouble ? "가능" : "불가능"}
                </Descriptions.Item>
              </Descriptions>
              <Divider />
            </div>
          );
        })}
      </Card>
    </div>
  );
};

export default ProductsItemsDesc;
