import BigNumber from "bignumber.js";

import { SellernoteAppRegion, VolumeSizeUnit } from "../../types/common/common";

import { APP_REGION } from "../../constants";

/**
 * 숫자 천단위마다 ','로 구분된 문자열을 반환
 * 소수점은 처리 안 되는 것에 유의할 것
 */
function toThousandUnitFormat(val?: number | string | null) {
  if (!val) {
    return "0";
  }

  if (typeof val === "number") {
    val = removeUnnecessaryDecimalPlaces(val);
  }

  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 * (주의: 결과시 불필요한 소수점을 삭제하는데만 사용할 것. 계산 자체의 정밀도를 위해서는 bignumber등의 계산전용 라이브러리 사용을 고려해야함)
 *
 * 2진 산술연산의 한계로 발생하는 불필요한 소수점을 버림
 * - 특정 10진수들은(ex. 0.1) 2진수로는 무한으로밖에 표현되지 않는데, 프로그래밍 언어의 최대 표현숫자에 한계가 있으므로 긴 소수점으로 표시됨 (ex. 0.1 + 0.2의 결과가 0.3이아닌 0.30000000000000004으로 나옴)
 */
function removeUnnecessaryDecimalPlaces(val: number) {
  return Number(val.toFixed(12));
}

/**
 * 부동소숫점 에러(ex. 1500 * 1368.9의 결과가 2053350이 아닌 2053350.0000000002으로 나온다 )가 생기지 않는 곱하기를 하고싶을 때 사용
 */
function multiplyByBigNumber(x: number, y: number) {
  return Number(new BigNumber(x).multipliedBy(new BigNumber(y)));
}

/**
 * 특정 통화단위로 표시.
 * 통화 종류를 선택하지 않을경우, 어플리케이션의 LOCALE 기준 통화로 결정됨.
 * @param fractionDigits 표기하고자 하는 소수점 자릿수
 */
function toCurrency({
  val,
  currencyUnit,
  fractionDigits,
}: {
  val?: number | string;
  currencyUnit?: string;
  fractionDigits?: number;
}) {
  if (!currencyUnit) {
    currencyUnit = getCurrencyUnitFromLocale(APP_REGION);
  }

  const valAsNumber = Number(val);

  if (!val || !valAsNumber) {
    return "0";
  }

  if (fractionDigits)
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencyUnit,
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
    }).format(valAsNumber);

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currencyUnit,
  }).format(valAsNumber);
}

function getCurrencyUnitFromLocale(locale: string): string {
  switch (locale) {
    case "KR": {
      return "KRW";
    }
    case "SG": {
      return "SGD";
    }
    default: {
      return "";
    }
  }
}

/**
 * 숫자를 만단위 기준으로 간략하게 표현함 (75,000이면 7.5로)
 */
function toMillionShortFormat(val?: number) {
  if (!val) {
    return 0;
  }

  return Math.ceil(val / 1000) / 10;
}

/**
 * 소수점 특정 자리수까지 자름 (자리수 이하는 반올림이 아니라 버림)
 * 결과가 0이 나온다면, 해당 유효자리 수에 1을 할당
 * @param val
 * @param position 마지막 자리수(소수점 x째자리)
 */
function toFixedFloat(val: number | string, position: number) {
  let valAsNum = Number(val);
  if (isNaN(valAsNum)) {
    return;
  }

  // js의 float계산오류를 교정하기 위해 정밀도 조정
  const precision = position >= 20 ? 20 : position + 1;
  valAsNum = Number(valAsNum.toFixed(precision));

  const exponent = Math.pow(10, position);

  // 결과가 0이 나온다면, 해당 유효자리 수에 1을 할당 (toFixed(10)을 하여 곱셈 정밀도 오류를 방지)
  const validNumber =
    Math.floor(Number((valAsNum * exponent).toFixed(10))) || 1;

  const result = validNumber / exponent;

  return Number(result);
}

/**
 * sequence 생성기
 * 배열 요소의 unique key가 없는 상황에서 동적으로 조작해야할 경우 사용할 수 있음
 */
function getSequenceGen(initialNumber = 0) {
  let sequence = initialNumber - 1;
  return function () {
    sequence++;
    return sequence;
  };
}

/**
 * 중량을 ton 으로 변환
 * @param {*} weight 무게 값
 * @param {*} originWeightUnit 무게 단위 TON, KG
 */
function toTon(
  weight: number | string,
  originWeightUnit: "TON" | "KG" | "ton" | "kg"
) {
  if (originWeightUnit === "TON" || originWeightUnit === "ton") {
    return Number(weight);
  }

  if (originWeightUnit === "KG" || originWeightUnit === "kg") {
    let weightAsNumber = Number(weight);
    weightAsNumber /= 1000;

    return weightAsNumber;
  }

  return 0;
}

/**
 * 중량을 kg 로 변환
 * @param {*} weight 무게 값
 * @param {*} originWeightUnit 무게 단위 TON, KG
 */
function toKg(
  weight: number | string,
  originWeightUnit: "TON" | "KG" | "ton" | "kg"
) {
  if (originWeightUnit === "KG" || originWeightUnit === "kg") {
    return Number(weight);
  }

  if (originWeightUnit === "TON" || originWeightUnit === "ton") {
    return Number(weight) * 1000;
  }

  return 0;
}

/**
 * 사이즈를 cm 로 변환
 * @param size 사이즈 값
 * @param originSizeUnit 사이즈 단위 cm, m
 */
function toCm(size: number | string, originSizeUnit: "CM" | "M" | "cm" | "m") {
  if (originSizeUnit === "cm" || originSizeUnit === "CM") {
    return Number(size);
  }

  if (originSizeUnit === "m" || originSizeUnit === "M") {
    return Number(size) * 100;
  }

  return 0;
}

/**
 * 사이즈를 m 로 변환
 * @param {*} size 사이즈 값
 * @param {*} originSizeUnit 사이즈 단위 cm, m
 */
function toM(size: number | string, originSizeUnit: "CM" | "M" | "cm" | "m") {
  if (originSizeUnit === "cm" || originSizeUnit === "CM") {
    return toFixedFloat(Number(size) / 100, 2);
  }

  if (originSizeUnit === "m" || originSizeUnit === "M") {
    return Number(size);
  }
}

/**
 * RTon을 계산
 * @param {*} volume // cbm과 volume은 같은 값이다 cbm처럼 m단위로 계산된 volume이어야 함
 * @param {*} weight // ton단위여야함
 */
function calculateRTon(volume: number | string, weight: number | string) {
  if (volume && typeof volume !== "number") {
    volume = Number(volume);
  }
  if (weight && typeof weight !== "number") {
    weight = Number(weight);
  }

  return volume > weight ? Number(volume) : Number(weight);
}

/**
 * CBM을 계산 (단순 Volume계산과 달리 air타입에 대해 처리한 값을 반환)
 * @param type
 * @param width
 * @param height
 * @param depth
 * @param sizeUnit m(default), cm
 */
function calculateCBM({
  type = "lcl",
  width,
  height,
  depth,
  sizeUnit = "m",
}: {
  // airAsContainer의 경우는 특별히 변환하지 않고 변환하지 않고 m기준으로 단순변환한다.
  type?: "lcl" | "air" | "airAsContainer";
  width: number;
  height: number;
  depth: number;
  sizeUnit?: VolumeSizeUnit;
}) {
  if (!sizeUnit) {
    return;
  }

  // sizeUnit은 대문자로 올 수도 있어서 소문자로 정규화
  const normalizedSizeUnit = sizeUnit.toLocaleLowerCase();

  width = Number(width);
  height = Number(height);
  depth = Number(depth);

  if (type !== "air" && normalizedSizeUnit === "cm") {
    width /= 100;
    height /= 100;
    depth /= 100;
  }

  if (type === "air" && normalizedSizeUnit === "m") {
    width *= 100;
    height *= 100;
    depth *= 100;
  }

  let cbm = width * height * depth;

  if (type === "air") {
    cbm /= 6000;
  }

  return Number(cbm);
}

/**
 * CW값을 계산함
 *
 * CW는 "실제중량"과 "부피중량" 중 더 무거운 쪽을 의미.
 *
 * 주의: CW의 부피중랑은 cm단위로, 중량은 kg단위로 입력되어야한다.
 * @param width
 * @param height
 * @param depth
 * @param weight 실제중량
 */
function calculateCW(
  params:
    | {
        type: "length";
        width: number;
        height: number;
        depth: number;
        weight: number;
      }
    | {
        type: "cbm";
        cbm: number;
        weight: number;
      }
) {
  let volumetricWeight = 0;

  if (params.type === "cbm") {
    volumetricWeight = getVolumeWithCBMForAir(params.cbm);
  }

  if (params.type === "length") {
    volumetricWeight = getVolumetricWeightForAir(
      params.width,
      params.height,
      params.depth
    );
  }

  const cw =
    volumetricWeight > params.weight ? volumetricWeight : params.weight;

  return Number(cw);
}

/**
 * air의 부피중량을 계산.
 * - 주의: 부피중랑은 cm단위로 와야함
 * @param width
 * @param height
 * @param depth
 */
function getVolumetricWeightForAir(
  width: number,
  height: number,
  depth: number
) {
  return (Number(width) * Number(height) * Number(depth)) / 6000;
}

/**
 * (항공 전용) CBM을 volume으로 환산
 */
function getVolumeWithCBMForAir(cbm: number) {
  if (!cbm) return 0;

  return (cbm * 1000000) / 6000;
}

/**
 * 서수를 반환
 * locale을 안 보내면 'APP_REGION'기준으로 반환
 * @param number
 * @param locale
 */
function getOrdinalString(
  number: number,
  locale: SellernoteAppRegion = APP_REGION
) {
  if (locale === "KR") {
    return `${number}번째`;
  }

  switch (number) {
    case 1: {
      return "1st";
    }
    case 2: {
      return "2nd";
    }
    case 3: {
      return "3rd";
    }
    default: {
      return `${number}th`;
    }
  }
}

/**
 * 1 <= random <= 100000 까지의 랜덤 수 반환
 */
function getRandomNumber() {
  const randomNumber = Math.floor(Math.random() * 100000) + 1;
  return randomNumber;
}

/** 값의 차이를 계산 */
const calculateDifference = (a?: number | null, b?: number | null) => {
  return Math.abs((a ?? 0) - (b ?? 0));
};

/** 값의 차이에 의한 미만/초과 문자열을 반환 */
const getStringForValueRange = (a?: number | null, b?: number | null) => {
  const resultValue = (a ?? 0) - (b ?? 0);

  return resultValue > 0 ? "부족" : "초과";
};

/**
 * 소수점을 유지하면서 숫자 천단위마다 ','로 구분된 문자열을 반환
 */
function toThousandUnitFormatWithDecimal(value?: number | string | null) {
  if (!value) {
    return "0";
  }

  let numberValue: number;

  if (typeof value === "number") {
    numberValue = value;
  } else {
    // 문자열이면 숫자로 변경
    numberValue = parseFloat(value);
    if (isNaN(numberValue)) {
      // 변환에 문제가 있을 경우 기본값 반환
      return "0";
    }
  }

  // 숫자를 소수점 기준으로 분리
  const separatedNumberByDecimal = numberValue.toString().split(".");
  const integer = separatedNumberByDecimal[0];
  const decimal = separatedNumberByDecimal[1] || "";

  // 정수에 천단위 , 추가
  const thousandUnitFormattedInteger = integer.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ","
  );

  // 소숫점이 있을 때 정수와 소수점을 합친다.
  return decimal
    ? `${thousandUnitFormattedInteger}.${decimal}`
    : thousandUnitFormattedInteger;
}

export {
  toThousandUnitFormat,
  toCurrency,
  getCurrencyUnitFromLocale,
  toMillionShortFormat,
  toFixedFloat,
  getSequenceGen,
  toTon,
  toKg,
  toCm,
  toM,
  calculateRTon,
  calculateCBM,
  calculateCW,
  getVolumetricWeightForAir,
  getVolumeWithCBMForAir,
  getOrdinalString,
  removeUnnecessaryDecimalPlaces,
  multiplyByBigNumber,
  getRandomNumber,
  calculateDifference,
  getStringForValueRange,
  toThousandUnitFormatWithDecimal,
};
