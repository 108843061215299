import React from "react";
import { useParams } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { useRecoilState } from "recoil";

import BID_EXPORTER_QUERY from "@sellernote/_shared/src/queries/forwarding/BID_EXPORTER_QUERY";
import { FORWARDING_ADMIN_BID_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminBid";
import { bidExporterProps } from "@sellernote/_shared/src/types/forwarding/bidExporter";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";
import useSearchWithTerm, {
  TermSearchType,
} from "@sellernote/_shared-for-admin/src/hooks/common/useSearchWithTerm";
import useGetObjectWithTermSearchTypeKey from "@sellernote/_shared-for-forwarding-admin/src/hooks/useGetObjectWithTermSearchTypeKey";

import ModalContents from "./ModalContents";

const ExporterDescModal = ({
  isImport,
  teamId,
  showsFindExportersModal,
  setShowsFindExportersModal,
}: {
  isImport?: boolean;
  teamId: number;
  showsFindExportersModal: boolean;
  setShowsFindExportersModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { id }: { id: string } = useParams();

  const [exporterId, setExporterId] = useRecoilState(
    FORWARDING_ADMIN_BID_ATOMS.ADMIN_BID_EXPORTER_ID
  );

  const [exporterInfo, setExporterInfo] = useRecoilState(
    FORWARDING_ADMIN_BID_ATOMS.ADMIN_BID_EXPORTER_INFO
  );

  const termSearchTypeOptions: TermSearchType<"query">[] = [
    {
      label: isImport ? "수출자 정보" : "수입자 정보",
      value: "query",
    },
  ];

  const resetModal = () => {
    setExporterId(0);

    setShowsFindExportersModal(false);
  };

  const handleExporterInfoCancel = () => {
    resetModal();
  };

  const { debouncedSearchTerm, termSearchType, TermSearchPanel } =
    useSearchWithTerm({
      termSearchTypeOptions,
    });

  const { objectWithTermSearchTypeKey } = useGetObjectWithTermSearchTypeKey({
    termSearchType,
    debouncedSearchTerm,
  });

  const { data: exportersInfoList } = BID_EXPORTER_QUERY.useGetExportersInfo({
    teamId,
    ...objectWithTermSearchTypeKey,
  });

  const handleExporterInfoUpdate = () => {
    const matchInfo =
      exportersInfoList &&
      exportersInfoList.list.find(
        (item: bidExporterProps) => item.id === exporterId
      );

    if (matchInfo) {
      setExporterInfo({
        companyName: matchInfo.companyName,
        personName: matchInfo.personName,
        personEmail: matchInfo.personEmail,
        personPhone: matchInfo.personPhone,
      });
    }
    resetModal();
  };

  return (
    <Modal
      isOpened={showsFindExportersModal}
      handleClose={handleExporterInfoCancel}
      modalBody={
        <div>
          <Box
            sx={{
              width: "100%",
              minWidth: 500,
              maxWidth: 500,
              maxHeight: 700,
              minHeight: 600,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              display={"flex"}
              justifyContent={"center"}
              mt={-4}
              ml={1}
              gutterBottom
            >
              불러오기
            </Typography>

            <Box ml={2} display={"flex"} flexDirection={"column"} gap={3}>
              {TermSearchPanel}

              <Typography variant="caption">
                {isImport
                  ? "저장된 수출자 정보입니다."
                  : "저장된 수입자 정보입니다."}
              </Typography>

              {exportersInfoList?.list && (
                <ModalContents
                  list={exportersInfoList.list}
                  isImport={isImport}
                />
              )}
              <Box
                padding={4}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Button onClick={handleExporterInfoCancel}>취소</Button>

                <Button
                  variant="contained"
                  disabled={exporterId === 0}
                  onClick={handleExporterInfoUpdate}
                >
                  선택
                </Button>
              </Box>
            </Box>
          </Box>
        </div>
      }
      showsCloseIcon={true}
    />
  );
};

export default ExporterDescModal;
