import { ChangeEvent } from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";

import { Comment } from "@sellernote/_shared/src/types/forwarding/trello";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";

function CommentDataList({
  comment,
  onCommentInputChange,
  onCommentUpdate,
  commentData,
  UploadResponseSnackBar,
}: {
  comment: string;
  onCommentInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onCommentUpdate: () => void;
  commentData: Comment[] | null;
  UploadResponseSnackBar: JSX.Element;
}) {
  return (
    <Box>
      <Box>
        <Typography variant="h6" component="div">
          댓글
        </Typography>
      </Box>

      {commentData && (
        <List>
          {commentData.map((n, index) => {
            return (
              <ListItem
                key={index}
                sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}
                disablePadding
              >
                <ListItemText
                  sx={{ flex: "0 0 auto" }}
                  secondary={
                    // Date인지 문자열인지 확인을 한다 ISO string으로 요청/응답을 정하기 전 사용하고 있던 toLocaleString와 구별
                    isNaN(Date.parse(n.date))
                      ? n.date
                      : toFormattedDate(n.date, "YYYY.MM.DD a hh:mm:ss", false)
                  }
                >
                  {n.id}
                </ListItemText>

                <ListItemText
                  sx={{
                    color: n.type === "admin" ? "#f44336" : "inherit",
                    flex: 1,
                  }}
                  primary={n.comment}
                />
              </ListItem>
            );
          })}
        </List>
      )}

      <Box sx={{ display: "flex", gap: 1 }}>
        <TextField
          sx={{ flex: 1 }}
          value={comment}
          label="댓글 입력"
          fullWidth
          size="small"
          multiline={true}
          onChange={onCommentInputChange}
        />

        <Button variant="contained" onClick={onCommentUpdate}>
          저장
        </Button>
      </Box>

      {UploadResponseSnackBar}
    </Box>
  );
}

export default CommentDataList;
