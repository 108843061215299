import { Dispatch, SetStateAction, useCallback } from "react";
import { useQueryClient } from "react-query";
import { message, Modal, Select } from "antd";

import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import { InlnadTransportType } from "@sellernote/_shared/src/types/common/common";
import { PartnerListItem } from "@sellernote/_shared/src/types/forwarding/partner";
import { TrelloBidDetail } from "@sellernote/_shared/src/types/forwarding/trello";
import { INLAND_PARTNER_OPTION_LIST } from "@sellernote/_shared/src/utils/common/options";

import InlandPartnerForm from "./InlandPartnerForm";
import Styled from "./index.styles";

const { Option } = Select;

const RegisterInlandPartnerModal = ({
  setShowRegisterInlandInfoModal,
  showRegisterInlandInfoModal,
  trelloDetail,
  partnerList,
  bidAccountPayableId,
}: {
  setShowRegisterInlandInfoModal: Dispatch<SetStateAction<boolean>>;
  showRegisterInlandInfoModal: boolean;
  trelloDetail: TrelloBidDetail;
  partnerList?: PartnerListItem[];
  bidAccountPayableId: number;
}) => {
  const queryClient = useQueryClient();

  const {
    mutate: cancelInlandTransport,
    ResponseHandler: ResponseHandlerOfUseCancelInlandTransport,
  } = TRELLO_BID_QUERY.useCancelInlandTransport(trelloDetail.id);

  const { mutate: registerInlandTransport } =
    TRELLO_BID_QUERY.useRegisterInlandTransport(trelloDetail.id);

  const handleInlandTransportUpdateSelect = useCallback(
    (selectValue: InlnadTransportType | "cancel") => {
      if (selectValue === "cancel") {
        cancelInlandTransport(
          {
            inlandFlag: false,
            bidAccountPayableId,
          },
          {
            onSuccess: () => {
              message.success("내륙 운송을 취소했습니다.");
              queryClient.invalidateQueries(
                TRELLO_BID_QUERY_KEY_GEN.trelloDetail()
              );
            },
          }
        );
      } else {
        registerInlandTransport(
          {
            detailFlag: false,
            inlandType: selectValue,
            bidAccountPayableId,
          },
          {
            onSuccess: () => {
              message.success("내륙 운송 유형을 등록했습니다.");
              queryClient.invalidateQueries(
                TRELLO_BID_QUERY_KEY_GEN.trelloDetail()
              );
            },

            onError: () => {
              message.error("에러가 있습니다 개발자에게 문의해주세요.");
            },
          }
        );
      }
    },
    [
      bidAccountPayableId,
      cancelInlandTransport,
      queryClient,
      registerInlandTransport,
    ]
  );

  const handleRegisterInlandInfoModalCancel = useCallback(() => {
    setShowRegisterInlandInfoModal(false);
  }, [setShowRegisterInlandInfoModal]);

  return (
    <Modal
      width={328}
      title=""
      visible={showRegisterInlandInfoModal}
      onCancel={handleRegisterInlandInfoModalCancel}
      footer={null}
    >
      <Styled.modalBody>
        <div className="select-wrapper">
          <Select
            className="select"
            onSelect={handleInlandTransportUpdateSelect}
            value={trelloDetail.inlandType}
          >
            {INLAND_PARTNER_OPTION_LIST.map((v) => {
              return (
                <Option key={v.value} value={v.value}>
                  {v.label}
                </Option>
              );
            })}
          </Select>
        </div>

        {bidAccountPayableId && trelloDetail.inlandType && (
          <InlandPartnerForm
            inlandType={trelloDetail.inlandType}
            setShowRegisterInlandInfoModal={setShowRegisterInlandInfoModal}
            bidId={trelloDetail.id}
            management={trelloDetail.management}
            partnerList={partnerList}
            trelloDetail={trelloDetail}
            bidAccountPayableId={bidAccountPayableId}
          />
        )}
      </Styled.modalBody>

      {ResponseHandlerOfUseCancelInlandTransport}
    </Modal>
  );
};

export default RegisterInlandPartnerModal;
