/**
 * B/L Tracking 정책
 * B/L 입력시 갱신 가능 유형인지, 갱신 시점이 언제인지 안내 (ResponseHandler)
 */
const BL_TRACKING_POLICY_RECORD = {
  FCL: "FCL 운송은 B/L 데이터 연동을 통해 Tracking 정보가 업데이트되며, 매 정시 20분 경 갱신됩니다.",
  LCL: "LCL 운송은 B/L 데이터 연동을 통해 Tracking 을 제공하지 않습니다.",
  AIR: "항공 운송은 AWB 데이터 연동을 통해 Tracking 을 제공하지 않습니다.",
};

export default BL_TRACKING_POLICY_RECORD;
