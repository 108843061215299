import { Alert, AlertColor, Slide, Snackbar } from "@mui/material";

/**
 * hooks > useHandleResponseSnackbar와 함께 사용
 */
function ResponseSnackbar({
  showsSnackbar,
  onSnackbarClose,
  title,
  color,
}: {
  showsSnackbar: boolean;
  onSnackbarClose: () => void;
  title: string;
  color?: AlertColor;
}) {
  return (
    <Snackbar
      open={showsSnackbar}
      autoHideDuration={5000}
      onClose={onSnackbarClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      TransitionComponent={Slide}
    >
      <Alert
        onClose={onSnackbarClose}
        severity={color ? color : "success"}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {title}
      </Alert>
    </Snackbar>
  );
}

export default ResponseSnackbar;
