import styled from "styled-components";

const basicDescContainer = styled.div`
  background-color: #ffffff;
  padding: 24px 32px 32px 32px;

  > .create-date {
    text-align: right;

    > .date-label {
      margin-right: 16px;
    }ㄴ
  }
`;

const otherDescContainer = styled.div`
  padding: 24px;

  > .white-background {
    padding: 32px;
    background-color: #ffffff;

    > .consolidation-item {
      margin-bottom: 24px;

      > .title {
        margin-bottom: 14px;
        font-weight: bold;
        font-size: 20px;
        color: #212121;
      }
    }
  }
`;

export default {
  basicDescContainer,
  otherDescContainer,
};
