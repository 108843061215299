import { useQueryClient } from "react-query";

import TRELLO_BID_QUERY, {
  TRELLO_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";

import useHandleResponseSnackbar from "../../../../../../hooks/useHandleResponseSnackbar";

function useHandleOpCheckPoint({ shipmentId }: { shipmentId: number }) {
  const queryClient = useQueryClient();

  const { showsSnackbar, handleSnackbarOpen, handleSnackbarClose } =
    useHandleResponseSnackbar();

  const {
    mutate: updateCheckPoint,
    ResponseHandler: ResponseHandlerOfUpdateOpCheckPoint,
  } = TRELLO_BID_QUERY.useUpdateOpCheckPoint();

  const handleCheckPointUpdate = (BLType: string) => {
    updateCheckPoint(
      {
        typeBL: BLType,
        pathParams: {
          shipmentId,
        },
      },
      {
        onSuccess: () => {
          handleSnackbarOpen();

          queryClient.invalidateQueries(
            TRELLO_BID_QUERY_KEY_GEN.getTrelloBidDetail({
              bidId: shipmentId,
            })
          );
        },
      }
    );
  };

  return {
    handleCheckPointUpdate,
    ResponseHandlerOfUpdateOpCheckPoint,
    showsSnackbar,
    handleSnackbarClose,
  };
}

export default useHandleOpCheckPoint;
