const checkFileExtension = ({
  allowedFileExtensionList,
  fileName,
}: {
  allowedFileExtensionList: string[] | undefined;
  fileName: string;
}) => {
  const extension = fileName.split(".").pop() || "";

  return allowedFileExtensionList
    ? allowedFileExtensionList.includes(extension)
    : true;
};

const checkFileSize = ({
  allowedFileSize,
  fileSize,
}: {
  allowedFileSize: number | undefined;
  fileSize: number;
}) => (allowedFileSize ? fileSize <= allowedFileSize : true);

const downloadFile = ({
  fileName,
  fileContent,
  type = "application/octet-stream",
}: {
  fileName: string;
  fileContent: BlobPart;
  type?: string;
}) => {
  const blob = new Blob([fileContent], { type });

  const url = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);

  window.URL.revokeObjectURL(url);
};

export { checkFileExtension, checkFileSize, downloadFile };
