import { Cluster } from "@googlemaps/markerclusterer";

import { MarkerClustererInfo, MarkerInfo } from "..";
import useGoogleMap from "./useGoogleMap";

export interface MapProps extends google.maps.MapOptions {
  markerInfoList?: MarkerInfo[];
  polylineInfo?: google.maps.PolylineOptions;
  setMarkerCluster?: (cluster: Cluster) => MarkerClustererInfo;
  onGoogleApiLoaded?: (maps: { map: any }) => void;
  isAutoZoom?: boolean;
}

export default function Map({
  markerInfoList,
  polylineInfo,
  setMarkerCluster,
  onGoogleApiLoaded,
  style,
  isAutoZoom,
  ...options
}: MapProps & { style: { [key: string]: string } }) {
  const { ref } = useGoogleMap({
    markerInfoList,
    polylineInfo,
    setMarkerCluster,
    onGoogleApiLoaded,
    isAutoZoom,
    ...options,
  });

  return <div ref={ref} style={style} />;
}
