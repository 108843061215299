import { BidConsolidationSellerItemDetails } from "../../types/forwarding/bid";

import { calculateRTon, toFixedFloat } from "../common/number";

/**
 * 이우, 위해창고는 1000kg당 1RTon. 외의 창고에는 250kg당 1RTon으로 계산됨
 */
export const WAREHOUSE_LIST_USING_NORMAL_RTON = ["이우", "위해"];

/**
 * 창고를 고려한 RTon을 계산
 * (ex. 이우, 위해 외의 창고는 250kg이 1RTon으로 계산되는 등 창고마다 계산법이 다름)
 * @param param0
 * @returns
 */
export function calculateRTonByWarehouse({
  totalCbm,
  totalWeightAsTon,
  warehouseNameKR,
}: {
  totalCbm: number;
  totalWeightAsTon: number;
  /**
   * 창고이름(국문). (ex. "이우")
   */
  warehouseNameKR: string;
}) {
  const isUsingNormalRTon =
    WAREHOUSE_LIST_USING_NORMAL_RTON.includes(warehouseNameKR);

  // 이우, 위해 외의 창고에는 250kg당 1RTon으로 계산됨
  if (warehouseNameKR && !isUsingNormalRTon) {
    totalWeightAsTon = totalWeightAsTon * 4;
  }

  return calculateRTon(totalCbm, totalWeightAsTon);
}

export const calculateConsolidationCbm = (
  detail: BidConsolidationSellerItemDetails
) => {
  if (detail.height && detail.vertical && detail.horizontal) {
    if (detail.sizeUnit === "cm") {
      const totalCBM =
        (detail.height / 100) *
        (detail.vertical / 100) *
        (detail.horizontal / 100);

      return toFixedFloat(totalCBM * detail.quantity, 2);
    }

    if (detail.sizeUnit === "m") {
      const totalCBM = detail.height * detail.vertical * detail.horizontal;
      return toFixedFloat(totalCBM * detail.quantity, 2);
    }
  }
  if (detail.cbm) {
    return toFixedFloat(detail.cbm * detail.quantity, 2);
  }

  return 0;
};
