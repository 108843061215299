import { useCallback, useMemo, useState } from "react";
import { Alert, Portal, Snackbar } from "@mui/material";

export default function useUploadResponseSnackBar(
  callBackAfterSuccessSnackBarClose?: () => void
) {
  const [showsSuccessSnackBar, setShowsSuccessSnackBar] = useState(false);
  const [showsErrorSnackBar, setShowsErrorSnackBar] = useState(false);
  const [successMessage, setSuccessMessage] = useState("요청에 성공했습니다.");
  const [errorMessage, setErrorMessage] = useState("요청에 실패했습니다.");

  const handleSuccessSnackBarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }

      setShowsSuccessSnackBar(false);
      // 변경된 성공 메세지를 초기화시켜준다.
      setSuccessMessage("요청에 성공했습니다.");
      callBackAfterSuccessSnackBarClose && callBackAfterSuccessSnackBarClose();
    },
    [callBackAfterSuccessSnackBarClose]
  );

  const handleErrorSnackBarClose = useCallback(
    (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }

      setShowsErrorSnackBar(false);
      // 변경된 실패 메세지를 초기화시켜준다.
      setErrorMessage("요청에 실패했습니다.");
    },
    []
  );

  const UploadResponseSnackBar = useMemo(() => {
    return (
      // 모달 오픈 시 불투명에 들어가 흐리게 보여서 Portal로 감싼다.
      <Portal>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showsErrorSnackBar}
          autoHideDuration={2000}
          onClose={handleErrorSnackBarClose}
        >
          <Alert
            severity="error"
            sx={{ width: "100%" }}
            onClose={handleErrorSnackBarClose}
          >
            {errorMessage}
          </Alert>
        </Snackbar>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showsSuccessSnackBar}
          autoHideDuration={1500}
          onClose={handleSuccessSnackBarClose}
        >
          <Alert
            severity="success"
            sx={{ width: "100%" }}
            onClose={handleSuccessSnackBarClose}
          >
            {successMessage}
          </Alert>
        </Snackbar>
      </Portal>
    );
  }, [
    showsErrorSnackBar,
    handleErrorSnackBarClose,
    errorMessage,
    showsSuccessSnackBar,
    handleSuccessSnackBarClose,
    successMessage,
  ]);

  return {
    UploadResponseSnackBar,
    setShowsSuccessSnackBar,
    setShowsErrorSnackBar,
    setSuccessMessage,
    setErrorMessage,
  };
}
