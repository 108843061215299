import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { Button, Grid, Typography } from "@mui/material";

import TRELLO_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/TRELLO_BID_QUERY";
import Modal from "@sellernote/_shared-for-admin/src/components/Modal";

function DeleteBidAccountPayableModal({
  opensDeleteBidAccountPayableModal,
  setOpensDeleteBidAccountPayableModal,
  bidAccountPayableId,
  processSuccessResponse,
  setShowsErrorSnackBar,
}: {
  opensDeleteBidAccountPayableModal: boolean;
  setOpensDeleteBidAccountPayableModal: Dispatch<SetStateAction<boolean>>;
  bidAccountPayableId: number;
  processSuccessResponse: (message: string) => void;
  setShowsErrorSnackBar: Dispatch<SetStateAction<boolean>>;
}) {
  const { mutate: deleteAccountPayable } =
    TRELLO_BID_QUERY.useDeleteAccountPayable();

  const handleModalClose = useCallback(() => {
    setOpensDeleteBidAccountPayableModal(false);
  }, [setOpensDeleteBidAccountPayableModal]);

  const handleBidAccountPayableModalDelete = useCallback(() => {
    return () => {
      deleteAccountPayable(
        {
          pathParams: { bidAccountPayableId },
        },
        {
          onSuccess: () => {
            processSuccessResponse("삭제했습니다.");
            handleModalClose();
          },

          onError: () => {
            setShowsErrorSnackBar(true);
          },
        }
      );
    };
  }, [
    bidAccountPayableId,
    deleteAccountPayable,
    handleModalClose,
    setShowsErrorSnackBar,
    processSuccessResponse,
  ]);

  const ModalBody = useMemo(() => {
    return (
      <>
        <Typography variant="h6" component="div">
          삭제하시겠습니까?
        </Typography>

        <Grid container spacing={2} sx={{ marginTop: 1, maxWidth: 400 }}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleBidAccountPayableModalDelete()}
            >
              예
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button
              variant="contained"
              color="success"
              onClick={handleModalClose}
            >
              아니오
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }, [handleBidAccountPayableModalDelete, handleModalClose]);

  return (
    <Modal
      isOpened={opensDeleteBidAccountPayableModal}
      handleClose={handleModalClose}
      modalBody={ModalBody}
    />
  );
}

export default DeleteBidAccountPayableModal;
