import styled from "styled-components";

const container = styled.div`
  margin-left: 70%;

  > .input {
    margin-right: 10px;
  }

  > .apply-button {
    margin-right: 8px;
  }
`;

export default {
  container,
};
