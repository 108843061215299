import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Box,
  Button,
  Divider,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { AutoComplete, Input, message, Modal, Select, Switch } from "antd";
import { BaseOptionType, DefaultOptionType } from "antd/lib/select";
import { useAtomValue } from "jotai";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import {
  Country,
  Port,
  Zone,
} from "@sellernote/_shared/src/types/common/common";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { getCountryCodeKR } from "@sellernote/_shared/src/utils/common/country";
import {
  getCustomsPartnerName,
  getShipmentServiceTypeKr,
} from "@sellernote/_shared/src/utils/forwarding/adminBid";
import { getAdminBidPortName } from "@sellernote/_shared/src/utils/forwarding/bid";
import {
  changeInlandTypeToNameKr,
  changeTransPortTypeToKr,
} from "@sellernote/_shared/src/utils/forwarding/trello";

import ZoneAddressTextFiled from "../../../../../containers/ZoneAddressTextField";

import { FORWARDING_ADMIN_AUTH_SELECTORS } from "../../../../../jotaiStates/auth";

const { Option } = Select;

const { confirm } = Modal;

const boxLayoutStyle: SxProps<Theme> = {
  display: "flex",
  alignItems: "flex-start",
  gap: "4px",
};

const boxLabelStyle: SxProps<Theme> = {
  flex: "0 0 auto",
};

// TODO: 수출 MVP 배포 후 의뢰 상세 인터페이스 작업 시 MUI로 리팩토링
const TransportationInfo = ({
  bidDetail,
  portData,
  zoneData,
  countryList,
}: {
  bidDetail: AdminBidDetail;
  portData: Port[];
  zoneData: Zone[];
  countryList: Country[];
}) => {
  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const queryClient = useQueryClient();

  const [changeBid, setChangeBid] = useState(false);
  const [updateData, setUpdateData] = useState({});
  const [incoterms, setIncoterms] = useState(false);
  const [startType, setStartType] = useState(bidDetail.startType);
  const [endType, setEndType] = useState(bidDetail.endType);

  const {
    mutate: changeBidDetail,
    ResponseHandler: ResponseHandlerOfChangeBidDetail,
  } = ADMIN_BID_QUERY.useChangeBidDetail(bidDetail.id);

  const {
    mutate: changeExportBidDetail,
    ResponseHandler: ResponseHandlerOfChangeExportBidDetail,
  } = ADMIN_BID_QUERY.useChangeExportBidDetail(bidDetail.id);

  const { mutate: checkPriceReset } = ADMIN_BID_QUERY.useCheckPriceReset(
    bidDetail.id
  );

  const isExport = !bidDetail.isImport;

  const handleTransportationInfoChangeClick = useCallback(() => {
    const responseHandler = {
      onSuccess: () => {
        message.success("의뢰 정보를 변경했습니다.");
        setIncoterms(false);
        setChangeBid(false);
        setUpdateData({});
        queryClient.invalidateQueries(
          ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
            bidId: bidDetail.id,
          })
        );
      },

      onError: () => {
        message.error("에러가 있습니다. 개발자에게 문의해주세요.");
      },
    };
    confirm({
      title: "가격 재계산 혹은 리셋이 될 가능성이 있습니다.",
      icon: <ExclamationCircleOutlined />,
      content: "수정하시겠습니까??",
      onOk() {
        if (bidDetail.isImport) {
          checkPriceReset(updateData, {
            onSuccess: () => {
              changeBidDetail(updateData, {
                ...responseHandler,
              });
            },

            onError: () => {
              message.error("에러가 있습니다. 개발자에게 문의해주세요.");
            },
          });

          return;
        }

        changeExportBidDetail(updateData, {
          ...responseHandler,
        });

        return;
      },
    });
  }, [
    bidDetail.id,
    bidDetail.isImport,
    changeBidDetail,
    changeExportBidDetail,
    checkPriceReset,
    queryClient,
    updateData,
  ]);

  const checkDisabledAuthority = useCallback(() => {
    if (bidDetail.projectStatus === "settlementComplete") {
      if (currentAdminAuthInfo?.authority !== "master") {
        return true;
      }
      return false;
    }
    return false;
  }, [bidDetail.projectStatus, currentAdminAuthInfo?.authority]);

  const portNameListForAutoCompleteOptions = useMemo(
    () =>
      portData.map((v) => {
        return { value: v.nameEN };
      }),
    [portData]
  );

  const countryNameListForAutoCompleteOptions = useMemo(
    () =>
      countryList.map((v) => {
        return { value: v.nameKR };
      }),
    [countryList]
  );

  const endAddress = useMemo(() => {
    if (bidDetail.endAddressDetail) {
      return bidDetail.endAddress;
    }
    return bidDetail.zone?.name || "";
  }, [bidDetail.endAddress, bidDetail.endAddressDetail, bidDetail.zone?.name]);

  const getCountryName = useCallback(
    (countryName: string) => {
      return (
        countryList.find((v) => {
          return v.nameKR === countryName;
        })?.name || ""
      );
    },
    [countryList]
  );

  const changePortNameToId = useCallback(
    (portName: string) => {
      return (
        portData.find((v) => {
          return v.nameEN === portName;
        })?.id || null
      );
    },
    [portData]
  );

  const changeCheckpointValueToKr = useCallback((value: boolean | null) => {
    switch (value) {
      case true:
        return "포함";
      case false:
        return "미포함";
      default:
        return "-";
    }
  }, []);

  const changeContainerAccessableValueToKr = useCallback(
    (value: boolean | null) => {
      switch (value) {
        case true:
          return "가능";
        case false:
          return "불가능";
        default:
          return "-";
      }
    },
    []
  );

  const StartingPointDescriptionsItem = useMemo(() => {
    if (changeBid) {
      if (startType === "sea" || startType === "air") {
        return (
          <Box sx={boxLayoutStyle}>
            <Typography sx={boxLabelStyle}>출발지 항구/공항:</Typography>
            <Box sx={{ flex: 1 }}>
              <AutoComplete
                allowClear
                style={{ width: "100%", maxWidth: 237 }}
                onChange={(e) => {
                  setUpdateData({
                    ...updateData,
                    startPortId: changePortNameToId(e),
                    startAddress: null,
                    startViaPortId: null,
                  });
                }}
                defaultValue={getAdminBidPortName(bidDetail, "startPort")}
                options={portNameListForAutoCompleteOptions}
                filterOption={(
                  inputValue: string,
                  option: DefaultOptionType | BaseOptionType | undefined
                ) =>
                  !!option?.value
                    .toUpperCase()
                    .includes(inputValue.toUpperCase())
                }
              />
            </Box>
          </Box>
        );
      }
      if (startType === "inland") {
        return (
          <>
            {!isExport && (
              <Box sx={boxLayoutStyle}>
                <Typography sx={boxLabelStyle}>출발지 내륙:</Typography>
                <Box sx={{ flex: 1 }}>
                  <Input
                    style={{ maxWidth: 266 }}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        startAddress: e.target.value,
                        startPortId: null,
                      });
                    }}
                    defaultValue={bidDetail.startAddress}
                  />
                </Box>
              </Box>
            )}

            {isExport && (
              <Box sx={boxLayoutStyle}>
                <Typography sx={boxLabelStyle}>출발지 내륙:</Typography>
                <Box sx={{ flex: 1 }}>
                  <ZoneAddressTextFiled
                    zoneName={bidDetail.startAddress}
                    pageType="bid"
                    setBidUpdateState={setUpdateData}
                    updateData={updateData}
                    isImport={true}
                  />
                </Box>
              </Box>
            )}

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>출발지 경유지:</Typography>
              <Box sx={{ flex: 1 }}>
                <AutoComplete
                  allowClear
                  style={{ width: "100%", maxWidth: 254 }}
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      startViaPortId: changePortNameToId(e),
                    });
                  }}
                  defaultValue={bidDetail.startViaPort?.nameEN}
                  options={portNameListForAutoCompleteOptions}
                  filterOption={(
                    inputValue: string,
                    option: DefaultOptionType | BaseOptionType | undefined
                  ) =>
                    !!option?.value
                      .toUpperCase()
                      .includes(inputValue.toUpperCase())
                  }
                />
              </Box>
            </Box>

            {isExport && (
              <Box sx={boxLayoutStyle}>
                <Typography sx={boxLabelStyle}>출발지 상세주소:</Typography>
                <Box sx={{ flex: 1 }}>
                  <Input
                    allowClear
                    style={{ width: "100%", maxWidth: 266 }}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        startAddressDetail: e.target.value,
                      });
                    }}
                    defaultValue={bidDetail.startAddressDetail}
                  />
                </Box>
              </Box>
            )}
          </>
        );
      }
    }
    if (startType === "sea" || startType === "air") {
      return (
        <Box sx={boxLayoutStyle}>
          <Typography sx={boxLabelStyle}>출발지 항구/공항:</Typography>
          <Typography>{getAdminBidPortName(bidDetail, "startPort")}</Typography>
        </Box>
      );
    }
    return (
      <>
        <Box sx={boxLayoutStyle}>
          <Typography sx={boxLabelStyle}>출발지 경유지:</Typography>
          <Typography>{bidDetail.startViaPort?.nameEN}</Typography>
        </Box>

        <Box sx={boxLayoutStyle}>
          <Typography sx={boxLabelStyle}>출발지 내륙:</Typography>
          <Typography>{getAdminBidPortName(bidDetail, "startPort")}</Typography>
        </Box>

        {isExport && (
          <Box sx={boxLayoutStyle}>
            <Typography sx={boxLabelStyle}>출발지 상세주소:</Typography>
            <Typography>{bidDetail.startAddressDetail}</Typography>
          </Box>
        )}
      </>
    );
  }, [
    bidDetail,
    changeBid,
    changePortNameToId,
    isExport,
    portNameListForAutoCompleteOptions,
    startType,
    updateData,
  ]);

  const DestinationDescription = useMemo(() => {
    if (changeBid) {
      if (endType === "sea" || endType === "air") {
        return (
          <Box sx={boxLayoutStyle}>
            <Typography sx={boxLabelStyle}>도착지 항구/공항:</Typography>
            <Box sx={{ flex: 1 }}>
              <AutoComplete
                allowClear
                style={{ width: "100%", maxWidth: 237 }}
                onChange={(e) => {
                  setUpdateData({
                    ...updateData,
                    endPortId: changePortNameToId(e),
                    endAddress: null,
                    endViaPortId: null,
                  });
                }}
                defaultValue={getAdminBidPortName(bidDetail, "endPort")}
                options={portNameListForAutoCompleteOptions}
                filterOption={(
                  inputValue: string,
                  option: DefaultOptionType | BaseOptionType | undefined
                ) =>
                  !!option?.value
                    .toUpperCase()
                    .includes(inputValue.toUpperCase())
                }
              />
            </Box>
          </Box>
        );
      }
      if (endType === "inland") {
        return (
          <>
            {isExport ? (
              <Box sx={boxLayoutStyle}>
                <Typography sx={boxLabelStyle}>도착지 내륙:</Typography>
                <Box sx={{ flex: 1 }}>
                  <Input
                    style={{ maxWidth: 266 }}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        endAddress: e.target.value,
                        startPortId: null,
                      });
                    }}
                    defaultValue={bidDetail.endAddress}
                  />
                </Box>
              </Box>
            ) : (
              <Box sx={boxLayoutStyle}>
                <Typography sx={boxLabelStyle}>도착지 내륙:</Typography>
                <Box sx={{ flex: 1 }}>
                  <ZoneAddressTextFiled
                    zoneName={endAddress}
                    pageType="bid"
                    setBidUpdateState={setUpdateData}
                    updateData={updateData}
                    isImport={bidDetail.isImport}
                  />
                </Box>
              </Box>
            )}

            {!isExport && (
              <Box sx={boxLayoutStyle}>
                <Typography sx={boxLabelStyle}>도착지 상세주소:</Typography>
                <Box sx={{ flex: 1 }}>
                  <Input
                    allowClear
                    style={{ width: "100%", maxWidth: 266 }}
                    onChange={(e) => {
                      setUpdateData({
                        ...updateData,
                        endAddressDetail: e.target.value,
                      });
                    }}
                    defaultValue={bidDetail.endAddressDetail}
                  />
                </Box>
              </Box>
            )}

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>도착지 경유지:</Typography>
              <Box sx={{ flex: 1 }}>
                <AutoComplete
                  allowClear
                  style={{ width: "100%", maxWidth: 254 }}
                  onChange={(e) => {
                    setUpdateData({
                      ...updateData,
                      endViaPortId: changePortNameToId(e),
                    });
                  }}
                  defaultValue={bidDetail.endViaPort?.nameEN}
                  options={portNameListForAutoCompleteOptions}
                  filterOption={(
                    inputValue: string,
                    option: DefaultOptionType | BaseOptionType | undefined
                  ) =>
                    !!option?.value
                      .toUpperCase()
                      .includes(inputValue.toUpperCase())
                  }
                />
              </Box>
            </Box>
          </>
        );
      }
    }

    if (endType === "sea" || endType === "air") {
      return (
        <Box sx={boxLayoutStyle}>
          <Typography sx={boxLabelStyle}>도착지 항구/공항:</Typography>
          <Typography>{getAdminBidPortName(bidDetail, "endPort")}</Typography>
        </Box>
      );
    }

    return (
      <>
        <Box sx={boxLayoutStyle}>
          <Typography sx={boxLabelStyle}>도착 경유지 항구:</Typography>
          <Typography>{bidDetail.endViaPort?.nameEN}</Typography>
        </Box>

        <Box sx={boxLayoutStyle}>
          <Typography sx={boxLabelStyle}>도착지 주소:</Typography>
          <Typography>{getAdminBidPortName(bidDetail, "endPort")}</Typography>
        </Box>

        <Box sx={boxLayoutStyle}>
          <Typography sx={boxLabelStyle}>도착지 상세주소:</Typography>
          <Typography>{bidDetail.endAddressDetail}</Typography>
        </Box>
      </>
    );
  }, [
    changeBid,
    endType,
    bidDetail,
    portNameListForAutoCompleteOptions,
    updateData,
    changePortNameToId,
    isExport,
    endAddress,
  ]);

  const EditButton = useMemo(() => {
    if (APP_NAME === "shipda-admin") {
      return changeBid ? (
        <Box sx={{ display: "flex", gap: "4px" }}>
          <Button
            variant="outlined"
            onClick={handleTransportationInfoChangeClick}
          >
            완료
          </Button>

          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              setChangeBid(false);
              setIncoterms(false);
              setUpdateData({});
            }}
          >
            취소
          </Button>
        </Box>
      ) : (
        <Button
          variant="contained"
          sx={{}}
          onClick={() => {
            if (checkDisabledAuthority()) {
              message.warning("정산완료에서는 수정할 수 없습니다.");
            } else {
              setChangeBid(true);
            }
          }}
        >
          수정
        </Button>
      );
    }
    return;
  }, [changeBid, checkDisabledAuthority, handleTransportationInfoChangeClick]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">운송정보</Typography>
        {EditButton}
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)",
          columnGap: 1,
          rowGap: "4px",
        }}
      >
        <Box sx={boxLayoutStyle}>
          <Typography sx={boxLabelStyle}>유형:</Typography>
          <Typography>
            {getShipmentServiceTypeKr({
              serviceType: bidDetail.serviceType,
              isExpress: bidDetail.isExpress,
              isImport: bidDetail.isImport,
            })}
          </Typography>
        </Box>

        <Box sx={boxLayoutStyle}>
          <Typography sx={boxLabelStyle}>국가:</Typography>

          <Box sx={{ flex: 1 }}>
            {changeBid ? (
              <AutoComplete
                style={{ width: "100%" }}
                allowClear
                onChange={(e) => {
                  setUpdateData({
                    ...updateData,
                    startCountry: getCountryName(e),
                  });
                }}
                defaultValue={getCountryCodeKR(bidDetail.startCountry)}
                options={countryNameListForAutoCompleteOptions}
                filterOption={(
                  inputValue: string,
                  option: DefaultOptionType | BaseOptionType | undefined
                ) =>
                  !!option?.value
                    .toUpperCase()
                    .includes(inputValue.toUpperCase())
                }
              />
            ) : (
              <Typography>
                {getCountryCodeKR(bidDetail.startCountry)}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)",
          columnGap: 1,
          rowGap: "4px",
        }}
      >
        <Box sx={boxLayoutStyle}>
          <Typography sx={boxLabelStyle}>출발지 유형:</Typography>
          <Box sx={{ flex: 1 }}>
            {incoterms ? (
              <Select
                style={{
                  width: "100%",
                  maxWidth: 266,
                  marginTop: -5,
                  fontWeight: "normal",
                }}
                defaultValue={bidDetail.startType}
                onChange={(e) => {
                  setUpdateData({ ...updateData, startType: e });
                  setStartType(e);
                }}
              >
                <Option value="sea">해상</Option>
                <Option value="air">항공</Option>
                <Option value="inland">내륙</Option>
              </Select>
            ) : (
              <Typography>
                {changeTransPortTypeToKr(bidDetail.startType)}
              </Typography>
            )}
          </Box>
        </Box>

        {StartingPointDescriptionsItem}
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)",
          columnGap: 1,
          rowGap: "4px",
        }}
      >
        <Box sx={boxLayoutStyle}>
          <Typography sx={boxLabelStyle}>도착지 유형:</Typography>
          <Box>
            {incoterms ? (
              <Select
                style={{
                  width: "100%",
                  maxWidth: 266,
                  marginTop: -5,
                  fontWeight: "normal",
                }}
                defaultValue={bidDetail.endType}
                onChange={(e) => {
                  setUpdateData({ ...updateData, endType: e });
                  setEndType(e);
                }}
              >
                <Option value="sea">해상</Option>
                <Option value="air">공항</Option>
                <Option value="inland">내륙</Option>
              </Select>
            ) : (
              <Typography>
                {changeTransPortTypeToKr(bidDetail.endType)}
              </Typography>
            )}
          </Box>
        </Box>

        {DestinationDescription}
      </Box>

      <Divider sx={{ mt: 1, mb: 1 }} />

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(5, 1fr)",
          columnGap: 1,
          rowGap: "4px",
        }}
      >
        {!bidDetail.isImport && (
          <>
            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>수출통관:</Typography>
              <Box>
                {changeBid ? (
                  <Switch
                    defaultChecked={bidDetail.containExportCustoms}
                    onChange={(checked) => {
                      setUpdateData({
                        ...updateData,
                        containExportCustoms: checked,
                      });
                    }}
                  />
                ) : (
                  <Typography>
                    {changeCheckpointValueToKr(bidDetail.containExportCustoms)}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>적하보험:</Typography>
              <Box>
                {changeBid ? (
                  <Switch
                    defaultChecked={bidDetail.hopeCargoInsurance}
                    onChange={(checked) => {
                      setUpdateData({
                        ...updateData,
                        hopeCargoInsurance: checked,
                      });
                    }}
                  />
                ) : (
                  <Typography>
                    {changeCheckpointValueToKr(bidDetail.hopeCargoInsurance)}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>VGM:</Typography>
              <Box>
                {changeBid ? (
                  <Switch
                    defaultChecked={bidDetail.useVGM}
                    onChange={(checked) => {
                      setUpdateData({ ...updateData, useVGM: checked });
                    }}
                  />
                ) : bidDetail.useVGM ? (
                  <Typography>포함</Typography>
                ) : bidDetail.freightType === "FCL" ? (
                  <Typography>불포함</Typography>
                ) : (
                  <Typography>-</Typography>
                )}
              </Box>
            </Box>

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>수입통관:</Typography>
              <Box>
                {changeBid ? (
                  <Switch
                    defaultChecked={bidDetail.containCustoms}
                    onChange={(checked) => {
                      setUpdateData({ ...updateData, containCustoms: checked });
                    }}
                  />
                ) : (
                  <Typography>
                    {changeCheckpointValueToKr(bidDetail.containCustoms)}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>컨테이너 적입여부:</Typography>
              <Box>
                {changeBid ? (
                  <Switch
                    defaultChecked={bidDetail.containerStuffing}
                    onChange={(checked) => {
                      setUpdateData({
                        ...updateData,
                        containerStuffing: checked,
                      });
                    }}
                  />
                ) : bidDetail.containerStuffing ? (
                  <Typography>직접(고객사)</Typography>
                ) : bidDetail.freightType !== "FCL" ? (
                  <Typography>-</Typography>
                ) : (
                  <Typography>미신청</Typography>
                )}
              </Box>
            </Box>

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>CFS 입고:</Typography>
              <Box>
                {changeBid ? (
                  <Select
                    style={{
                      width: "100%",
                      maxWidth: 266,
                      marginTop: -5,
                      fontWeight: "normal",
                    }}
                    defaultValue={bidDetail.cfsReceiving}
                    onChange={(e) => {
                      if (!e) {
                        setUpdateData({
                          ...updateData,
                          cfsReceiving: undefined,
                        });
                      }
                      setUpdateData({ ...updateData, cfsReceiving: e });
                    }}
                  >
                    <Option value="direct">직접(고객사)</Option>
                    <Option value="shipda">대행(쉽다)</Option>
                    <Option value="none">-</Option>
                  </Select>
                ) : bidDetail.cfsReceiving === "direct" ? (
                  <Typography>직접</Typography>
                ) : bidDetail.cfsReceiving === "shipda" ? (
                  <Typography>대행</Typography>
                ) : (
                  <Typography>-</Typography>
                )}
              </Box>
            </Box>

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>운송방식:</Typography>
              <Box>
                {changeBid ? (
                  <Select
                    // 수출에서는 originInland를 사용한다.
                    defaultValue={
                      isExport ? bidDetail.originInland : bidDetail.inlandType
                    }
                    onChange={(e) => {
                      if (isExport) {
                        setUpdateData({
                          ...updateData,
                          originInland: e ?? undefined,
                        });
                      } else {
                        setUpdateData({
                          ...updateData,
                          inlandType: e ?? undefined,
                        });
                      }
                    }}
                  >
                    <Option value="sole">독차</Option>
                    <Option value="consol">합차</Option>
                    <Option value="none">-</Option>
                  </Select>
                ) : (
                  <Typography>
                    {changeInlandTypeToNameKr(
                      isExport ? bidDetail.originInland : bidDetail.inlandType
                    )}
                  </Typography>
                )}
              </Box>
            </Box>
          </>
        )}

        {bidDetail.isImport && (
          <>
            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>국내부대비용 포함:</Typography>
              <Box>
                {changeBid ? (
                  <Switch
                    defaultChecked={bidDetail.containDomesticFee}
                    onChange={(checked) => {
                      setUpdateData({
                        ...updateData,
                        containDomesticFee: checked,
                      });
                    }}
                  />
                ) : (
                  <Typography>
                    {changeCheckpointValueToKr(bidDetail.containDomesticFee)}
                  </Typography>
                )}
              </Box>
            </Box>

            {bidDetail.freightType !== "AIR" && (
              <Box sx={boxLayoutStyle}>
                <Typography sx={boxLabelStyle}>
                  OCEAN SURCHARGE 포함:
                </Typography>
                <Box>
                  {changeBid ? (
                    <Switch
                      defaultChecked={bidDetail.containOceanSurcharge}
                      onChange={(checked) => {
                        setUpdateData({
                          ...updateData,
                          containOceanSurcharge: checked,
                        });
                      }}
                    />
                  ) : (
                    <Typography>
                      {changeCheckpointValueToKr(
                        bidDetail.containOceanSurcharge
                      )}
                    </Typography>
                  )}
                </Box>
              </Box>
            )}

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>LSS:</Typography>
              <Box>
                {changeBid ? (
                  <Switch
                    defaultChecked={bidDetail.containLss}
                    onChange={(checked) => {
                      setUpdateData({ ...updateData, containLss: checked });
                    }}
                  />
                ) : (
                  <Typography>
                    {changeCheckpointValueToKr(bidDetail.containLss)}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>적하보험:</Typography>
              <Box>
                {changeBid ? (
                  <Switch
                    defaultChecked={bidDetail.hopeCargoInsurance}
                    onChange={(checked) => {
                      setUpdateData({
                        ...updateData,
                        hopeCargoInsurance: checked,
                      });
                    }}
                  />
                ) : (
                  <Typography>
                    {changeCheckpointValueToKr(bidDetail.hopeCargoInsurance)}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>통관의뢰:</Typography>
              <Box>
                {changeBid ? (
                  <Switch
                    defaultChecked={bidDetail.containCustoms}
                    onChange={(checked) => {
                      setUpdateData({ ...updateData, containCustoms: checked });
                    }}
                  />
                ) : (
                  <Typography>
                    {`${
                      bidDetail.containCustoms
                        ? changeCheckpointValueToKr(bidDetail.containCustoms)
                        : `미포함 (${getCustomsPartnerName(
                            bidDetail.accountPayables
                          )})`
                    }`}
                  </Typography>
                )}
              </Box>
            </Box>

            {bidDetail.freightType === "FCL" && (
              <Box sx={boxLayoutStyle}>
                <Typography sx={boxLabelStyle}>
                  도착지 컨테이너 진입여부:
                </Typography>
                <Box>
                  {changeBid ? (
                    <Switch
                      defaultChecked={bidDetail.containerAccessable}
                      onChange={(checked) => {
                        setUpdateData({
                          ...updateData,
                          containerAccessable: checked,
                        });
                      }}
                    />
                  ) : (
                    <Typography>
                      {changeContainerAccessableValueToKr(
                        bidDetail.containerAccessable
                      )}
                    </Typography>
                  )}
                </Box>
              </Box>
            )}

            {bidDetail.locale === "SG" && (
              <Box sx={boxLayoutStyle}>
                <Typography sx={boxLabelStyle}>IOR:</Typography>
                <Box>
                  {changeBid ? (
                    <Switch
                      defaultChecked={bidDetail.needIORAgency}
                      onChange={(checked) => {
                        setUpdateData({
                          ...updateData,
                          needIORAgency: checked,
                        });
                      }}
                    />
                  ) : (
                    <Typography>
                      {changeCheckpointValueToKr(bidDetail.needIORAgency)}
                    </Typography>
                  )}
                </Box>
              </Box>
            )}

            <Box sx={boxLayoutStyle}>
              <Typography sx={boxLabelStyle}>FTA C/O:</Typography>
              <Box>
                {changeBid ? (
                  <Switch
                    defaultChecked={bidDetail.needFTACertificateAgency}
                    onChange={(checked) => {
                      setUpdateData({
                        ...updateData,
                        needFTACertificateAgency: checked,
                      });
                    }}
                  />
                ) : (
                  <Typography>
                    {changeCheckpointValueToKr(
                      bidDetail.needFTACertificateAgency
                    )}
                  </Typography>
                )}
              </Box>
            </Box>

            {bidDetail.freightType !== "FCL" && (
              <Box sx={boxLayoutStyle}>
                <Typography sx={boxLabelStyle}>합차/독차:</Typography>
                <Typography>
                  {changeInlandTypeToNameKr(bidDetail.inlandType)}
                </Typography>
              </Box>
            )}

            <Box
              sx={{
                ...boxLayoutStyle,
                gridColumn: "3 / 6",
              }}
            >
              <Typography sx={boxLabelStyle}>유저 코멘트:</Typography>
              <Typography>{bidDetail.userNote}</Typography>
            </Box>
          </>
        )}

        {isExport && (
          <Box sx={boxLayoutStyle}>
            <Typography sx={boxLabelStyle}>컨테이너 적출여부:</Typography>
            <Box>
              {changeBid ? (
                <Switch
                  defaultChecked={bidDetail.containerDevanning}
                  onChange={(checked) => {
                    setUpdateData({
                      ...updateData,
                      containerDevanning: checked,
                    });
                  }}
                />
              ) : bidDetail.containerDevanning ? (
                <Typography>신청</Typography>
              ) : endType !== "inland" || bidDetail.freightType !== "FCL" ? (
                <Typography>-</Typography>
              ) : (
                <Typography>미신청</Typography>
              )}
            </Box>
          </Box>
        )}
      </Box>

      {ResponseHandlerOfChangeBidDetail}

      {ResponseHandlerOfChangeExportBidDetail}
    </Box>
  );
};

export default TransportationInfo;
