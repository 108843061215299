import { Descriptions, Tabs, Typography } from "antd";

import { GET_ADMIN_BID_DETAIL_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/adminBid";
import {
  getBidTitle,
  getSupplyValueInUnit,
} from "@sellernote/_shared/src/utils/forwarding/bid";

import ContainerItemsDesc from "./ContainerItemsDesc";
import MarginQuotationDesc from "./MarginQuotationDesc";
import ProductsItemsDesc from "./ProductsItemsDesc";

const { Title } = Typography;

const { TabPane } = Tabs;

const BidDetailTabs = ({
  bidDetail,
}: {
  bidDetail: GET_ADMIN_BID_DETAIL_RES;
}) => {
  if (bidDetail.serviceType === "consolidation") {
    return (
      <Tabs defaultActiveKey="1">
        {bidDetail.quotationsUser.length > 0 && (
          <TabPane tab="확정 견적" key="2">
            <MarginQuotationDesc quotations={bidDetail.quotationsUser[0]} />
          </TabPane>
        )}
      </Tabs>
    );
  }
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="화물정보 및 상세" key="1">
        <Descriptions
          title={
            <div style={{ fontWeight: "bold", fontSize: 20 }}>화물정보</div>
          }
          colon={false}
          style={{ fontWeight: "bold" }}
        >
          <Descriptions.Item label="품명 :">
            {getBidTitle({
              serviceType: bidDetail.serviceType,
              freightType: bidDetail.freightType,
              containersInfo: bidDetail.containersInfo,
              productsInfo: bidDetail.productsInfo,
            })}
          </Descriptions.Item>

          <Descriptions.Item label="운송수단 :">
            {bidDetail.freightType}
          </Descriptions.Item>

          <Descriptions.Item label="총 물동량 :">
            {getSupplyValueInUnit(bidDetail.freightType, bidDetail.supply)}
          </Descriptions.Item>
        </Descriptions>

        <Title
          level={4}
          style={{
            fontSize: "20px",
            marginTop: "20px",
            fontWeight: "bold",
          }}
        >
          물품 상세
        </Title>

        {bidDetail.containersInfo && (
          <ContainerItemsDesc bidDetail={bidDetail} />
        )}

        {bidDetail.productsInfo?.length > 0 && (
          <ProductsItemsDesc bidDetail={bidDetail} />
        )}
      </TabPane>

      {bidDetail.quotationsUser.length > 0 && (
        <TabPane tab="확정 견적" key="2">
          <MarginQuotationDesc quotations={bidDetail.quotationsUser[0]} />
        </TabPane>
      )}
    </Tabs>
  );
};

export default BidDetailTabs;
