import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { Button, InputNumber, Popover, Select } from "antd";

import { Currency } from "@sellernote/_shared/src/types/common/common";
import { ExchangeRate } from "@sellernote/_shared/src/types/forwarding/trello";
import { getWithdrawalExchangeRate } from "@sellernote/_shared/src/utils/forwarding/trello";

import Styled from "./index.styles";

const { Option } = Select;

const CurrencyFilter = ({
  currency,
  exchangeRateList,
  setCurrency,
  changeCurrency,
  handleWithdrawalUpdateCurrencyClick,
  exchangeRate,
  saveExchangeRate,
  isNewCurrency,
  setIsInputCurrency,
  inputCurrency,
  setInputCurrency,
  isInputCurrency,
  handleWithdrawalCurrencyClick,
}: {
  handleWithdrawalCurrencyClick: () => void;
  isInputCurrency: boolean;
  changeCurrency: (e: Currency) => void;
  currency: Currency;
  exchangeRateList: ExchangeRate[];
  setCurrency: Dispatch<SetStateAction<Currency>>;
  handleWithdrawalUpdateCurrencyClick: () => void;
  exchangeRate: number;
  saveExchangeRate: number;
  isNewCurrency: boolean;
  setIsInputCurrency: Dispatch<SetStateAction<boolean>>;
  inputCurrency: number;
  setInputCurrency: Dispatch<SetStateAction<number>>;
}) => {
  const content = useMemo(() => {
    return (
      <div>
        {exchangeRateList.map((v: ExchangeRate) => {
          return (
            <p key={v.currency}>
              <span>{v.currency} : </span>
              <span>{v.rate}</span>
            </p>
          );
        })}
      </div>
    );
  }, [exchangeRateList]);

  const handleCurrencySelectChange = useCallback(
    (e: Currency) => {
      setCurrency(e);
      changeCurrency(e);
    },
    [changeCurrency, setCurrency]
  );

  const handleCurrencyInputNumberChange = useCallback(
    (e: number) => {
      setInputCurrency(e);
    },
    [setInputCurrency]
  );

  return (
    <Styled.container style={{ marginLeft: "70%" }}>
      <div>
        <Select
          value={currency}
          style={{ width: "140px" }}
          onChange={handleCurrencySelectChange}
        >
          {exchangeRateList.map((v: ExchangeRate) => {
            return (
              <Option key={v.id} value={v.currency}>
                {v.currency}
              </Option>
            );
          })}
        </Select>

        <span className="input" style={{ marginRight: 10 }}>
          <InputNumber
            style={{ width: 140 }}
            value={getWithdrawalExchangeRate({
              isNewCurrency,
              inputCurrency,
              isInputCurrency,
              saveExchangeRate,
              exchangeRate,
            })}
            onChange={handleCurrencyInputNumberChange}
          />
        </span>

        <Button
          type="primary"
          size="small"
          className="apply-button"
          style={{ marginRight: 8 }}
          onClick={() => handleWithdrawalCurrencyClick()}
        >
          적용
        </Button>

        <Popover content={content} placement="rightBottom" title={"당일 환율"}>
          <Button
            type="primary"
            size="small"
            onClick={() => handleWithdrawalUpdateCurrencyClick()}
          >
            갱신
          </Button>
        </Popover>
      </div>
    </Styled.container>
  );
};

export default CurrencyFilter;
