import { Box, Button, OutlinedInput, Typography } from "@mui/material";

import Modal from "../../../../../../components/Modal";

export default function MoveToFinishedStatusConfirmModal({
  showsConfirmModal,
  setShowsConfirmModal,
  confirmMessage,
  setConfirmMessage,
  moveTrelloToFinishedStatus,
}: {
  showsConfirmModal: boolean;
  setShowsConfirmModal: (val: boolean) => void;
  confirmMessage: string;
  setConfirmMessage: (val: string) => void;
  moveTrelloToFinishedStatus: (val: { message: string }) => void;
}) {
  return (
    <Modal
      isOpened={showsConfirmModal}
      handleClose={() => {
        setShowsConfirmModal(false);
      }}
      modalBody={
        <Box
          sx={{
            display: "flex",
            width: "280px",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="button"
            fontSize="medium"
            sx={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 5,
            }}
          >
            강제 완료 이동 사유
          </Typography>

          <Box sx={{ marginBottom: 5 }}>
            <OutlinedInput
              autoFocus
              value={confirmMessage}
              error={confirmMessage.length > 28}
              placeholder="사유를 작성해주세요."
              onChange={(e) => setConfirmMessage(e.currentTarget.value)}
              sx={{
                width: "100%",
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
            />

            <Typography
              fontSize="small"
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                color: confirmMessage.length > 28 ? "#f44336" : "#2196f3",
              }}
            >
              {confirmMessage.length}/28
            </Typography>
          </Box>

          <Button
            variant="contained"
            color="error"
            sx={{
              width: "fit-content",
            }}
            disabled={confirmMessage.length > 28 || !confirmMessage.length}
            onClick={() => {
              moveTrelloToFinishedStatus({ message: confirmMessage });
            }}
          >
            변경
          </Button>
        </Box>
      }
    />
  );
}
