import { useCallback } from "react";
import { useHistory } from "react-router-dom";

import PageNotFound from "../PageNotFound";

export default function PageNotFoundForCRA() {
  const history = useHistory();

  const goBackToMainPage = useCallback(() => history.push("/"), [history]);

  return <PageNotFound goBackToMainPage={goBackToMainPage} />;
}
