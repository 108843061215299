import {
  AIRLINE,
  VESSEL_CARRIER,
  VESSEL_DESTINATION,
} from "../../types/forwarding/vesselSchedule";

/**
 * 선사코드별 사용자에게 표시할 선사 레이블
 * 참고: https://www.notion.so/shipda/9515d5a58d1047918f47644f6cfeecb9
 */
const LABEL_BY_VESSEL_CARRIER: Record<VESSEL_CARRIER | "SHIPDA", string> = {
  KMTC: "KMTC",
  SINOKOR: "SINOKOR",
  CK: "CK LINE",
  ANL: "ANL",
  APL: "APL Ltd",
  CMACGM: "CMA-CGM",
  COSCO: "COSCO SHIPPING LINES",
  CNC: "CNC",
  DONGJIN: "DONGJIN SHIPPING",
  DONGYOUNG: "DONGYOUNG",
  DOOWOO: "DOOWOO",
  ESL: "ESL",
  EAS: "EAS",
  EVERGREEN: "EVERGREEN",
  HS: "HANSUNG LINE",
  "HEUNG-A": "HEUNG-A",
  "HAPAG-LLOYD": "Hapag-Lloyd",
  MAERSK: "MAERSK",
  MSC: "MSC",
  NAMSUNG: "NAMSUNG",
  ONE: "ONE",
  WEIDONG: "WEIDONG FERRY",
  YANGMING: "YANGMING",
  ZIM: "ZIM",
  OOCL: "OOCL",
  PANOCEAN: "PAN OCEAN",
  PANCON: "PANCON",
  PIL: "PIL",
  SEALAND: "SEALAND",
  SITC: "SITC",
  TAIYOUNG: "TAIYOUNG SHIPPING",
  TS: "T.S. LINES",
  WANHAI: "WANHAI LINES",
  HMM: "HMM",
  SHIPDA: "쉽다",
};

const VESSEL_DESTINATION_SELECT_OPTION_LIST: {
  value: VESSEL_DESTINATION;
  label: VESSEL_DESTINATION;
}[] = [
  {
    value: "KRINC",
    label: "KRINC",
  },
  {
    value: "KRPUS",
    label: "KRPUS",
  },
  {
    value: "KRICN",
    label: "KRICN",
  },
  {
    value: "KRBNP",
    label: "KRBNP",
  },
];

const VESSEL_DESTINATION_SELECT_OPTION_LIST_FOR_AIR: {
  value: VESSEL_DESTINATION;
  label: VESSEL_DESTINATION;
}[] = [
  {
    value: "ICN",
    label: "ICN",
  },
];

/**
 * 항공사코드별 사용자에게 표시할 선사 레이블
 * 참고: https://www.notion.so/shipda/6fe740e0062748deaff6ec81d172a339
 */
const LABEL_BY_AIRLINE: Record<AIRLINE, string> = {
  KE: "대한항공",
  OZ: "아시아나항공",
  "7C": "제주항공",
  LJ: "진에어",
  KJ: "에어인천",
  JL: "일본항공",
  CV: "카고룩스",
  C8: "카고룩스 이탈리아",
  NH: "전일본항공",
  YP: "에어프레미아",
  TK: "터키항공",
  MH: "말레이시아항공",
  ZH: "심천항공",
  PO: "폴라에어카고",
  CX: "케세이 퍼시픽 항공",
  TG: "타이항공",
  SQ: "싱가포르항공",
  AY: "핀에어",
  CK: "중국화물항공",
};

const CHANGE_SCHEDULE_REASON_OPTION_LIST = [
  "수출자 화물준비일정 지연",
  "이전 항차로부터 누적된 딜레이로 인한 지연",
  "선적지 포트 혼잡으로 인한 출항 지연",
  "도착지 포트 혼잡으로 인한 입항 지연",
  "환적 스케줄 변동",
  "모선명(혹은 항차) 변경",
];

export {
  LABEL_BY_VESSEL_CARRIER,
  LABEL_BY_AIRLINE,
  VESSEL_DESTINATION_SELECT_OPTION_LIST,
  CHANGE_SCHEDULE_REASON_OPTION_LIST,
  VESSEL_DESTINATION_SELECT_OPTION_LIST_FOR_AIR,
};
