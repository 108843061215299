import { useMemo } from "react";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { IconButton, Tooltip } from "@mui/material";
import { useAtomValue } from "jotai";

import { FORWARDING_ADMIN_BID_JOTAI_ATOMS } from "../../jotaiStates/bid";
import useForwardingManagerIdFilter from "../useForwardingManagerIdFilter";
import useFreightAndIncotermsHeadFilter from "./useFreightAndIncotermsHeadFilter";

export default function useTableHeadPanel() {
  const filterData = useAtomValue(
    FORWARDING_ADMIN_BID_JOTAI_ATOMS.SHIPMENT_TABLE_FILTER_LIST
  );

  const {
    ForwardingManagerFilter,
    headFilterData: ForwardingManagerIdFilterData,
    handleReset: ForwardingManagerIdReset,
  } = useForwardingManagerIdFilter(filterData.forwardingManagerIdList);

  const {
    FreightAndIncotermsHeadFilter,
    headFilterData: FreightAndIncotermsFilterData,
    handleReset: FreightAndIncotermsReset,
  } = useFreightAndIncotermsHeadFilter(filterData);

  const tableHeadFilterData = useMemo(() => {
    return {
      ...ForwardingManagerIdFilterData,
      ...FreightAndIncotermsFilterData,
    };
  }, [FreightAndIncotermsFilterData, ForwardingManagerIdFilterData]);

  const AllResetButton = useMemo(() => {
    return (
      <Tooltip title="테이블 헤드 필터 전체 리셋">
        <IconButton
          onClick={() => {
            FreightAndIncotermsReset();
            ForwardingManagerIdReset();
          }}
        >
          <RestartAltIcon />
        </IconButton>
      </Tooltip>
    );
  }, [ForwardingManagerIdReset, FreightAndIncotermsReset]);

  const ShipmentTableHeadFilter = {
    ForwardingManagerFilter: ForwardingManagerFilter,
    FreightAndIncotermsHeadFilter: FreightAndIncotermsHeadFilter,
    AllResetButton: AllResetButton,
  };

  return {
    ShipmentTableHeadFilter,
    tableHeadFilterData,
  };
}
