import { Descriptions, Divider } from "antd";

import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";

const ConsolidationEndAddress = ({
  bidDetail,
}: {
  bidDetail: AdminBidDetail;
}) => {
  return (
    <div>
      <Divider />

      <Descriptions
        column={3}
        style={{ fontWeight: "bold" }}
        title={
          <div style={{ fontWeight: "bold", fontSize: 20 }}>도착지 정보</div>
        }
      >
        <Descriptions.Item label="도착지" labelStyle={{ marginTop: 5 }}>
          <div style={{ marginTop: 5 }}>
            {bidDetail.endAddressDetail
              ? bidDetail.endAddress
              : bidDetail.zone?.name}
          </div>
        </Descriptions.Item>

        <Descriptions.Item label="상세주소" labelStyle={{ marginTop: 5 }}>
          <div style={{ marginTop: 5 }}>
            {bidDetail.endAddressDetail
              ? bidDetail.endAddressDetail
              : bidDetail.endAddress}
          </div>
        </Descriptions.Item>

        <Descriptions.Item span={2} label="zoneId :">
          {bidDetail.zoneId || "없음"}
        </Descriptions.Item>
      </Descriptions>

      <Divider />
    </div>
  );
};

export default ConsolidationEndAddress;
