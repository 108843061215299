import {
  useAppMutation,
  useAppQueryWithQueryKeyFactory,
} from "../../services/query";

import {
  QueryResponseHandlerFailureModalInfo,
  QueryResponseHandlerSuccessModalInfo,
} from "../../components/QueryResponseHandler";

import {
  CONFIRM_SHIP_SCHEDULE_REQ,
  CONFIRM_SHIP_SCHEDULE_RES,
  GET_SHIP_LIST_RES,
} from "../../api-interfaces/shipda-api/ship";

export const SHIP_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/SHIP_QUERY" }] as const,

  getShipList: () =>
    [
      {
        ...SHIP_QUERY_KEY_GEN.all()[0],
        entity: "shipList",
      },
    ] as const,
};

function useGetShipList() {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof SHIP_QUERY_KEY_GEN.getShipList>,
    GET_SHIP_LIST_RES
  >({
    queryKey: SHIP_QUERY_KEY_GEN.getShipList(),
    requestOptions: {
      method: "get",
      path: "/ships",
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

function useConfirmShipSchedule(
  bidId: number,
  options?: {
    successModalInfo?: QueryResponseHandlerSuccessModalInfo;
    failureModalInfo?: QueryResponseHandlerFailureModalInfo;
  }
) {
  const mutation = useAppMutation<
    CONFIRM_SHIP_SCHEDULE_REQ,
    CONFIRM_SHIP_SCHEDULE_RES
  >({
    requestOptions: {
      method: "put",
      path: `/management/bid/${bidId}/shipSchedule/confirm`,
      apiType: "ShipdaAdminDefaultNew",
    },
    successModalInfo: options?.successModalInfo,
    failureModalInfo: options?.failureModalInfo,
  });

  return { ...mutation };
}

const SHIP_QUERY = {
  useGetShipList,
  useConfirmShipSchedule,
};

export default SHIP_QUERY;
