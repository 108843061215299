import {
  MallOptions,
  OrderDetailState,
  OrderDetailStateReason,
  OrderState,
  SellerSkuItem,
} from "../../../types/fulfillment/order";

import {
  GET_LINKED_SHIPPING_MALL_LIST_RES,
  GET_SELLER_ORDER_DETAIL_RES,
  GET_SELLER_ORDER_LIST_RES,
  GET_SELLER_SKU_LIST_RES,
} from "../../../api-interfaces/boful-api/order";
import { CONFIRM_INLAND_RES } from "../../../api-interfaces/shipda-api/trello";
import { MALL_OPTIONS } from "../../../constants/fulfillment/order";

export const GET_SELLER_CREDENTIAL_LIST = {
  total: 2,
  list: [
    {
      id: 1,
      mall: "cafe24",
      integratedAt: null,
      shippingMode: "active",
    },
    {
      id: 2,
      mall: "smartStore",
      integratedAt: "2023-05-30 09:00:00",
      shippingMode: "passive",
    },
  ],
};

export const GET_SELLER_CREDENTIAL_UNLINKED_DETAIL = {
  mall: "cafe24",
  integratedAt: null,
  authKeys: {
    vendorId: "",
    apiKey: "",
    secretKey: "",
  },
  collectOrderRange: 3,
  shippingMode: "active",
};

export const GET_SELLER_CREDENTIAL_LINKED_DETAIL = {
  mall: "smartStore",
  integratedAt: "2023-05-30 09:00:00",
  authKeys: {
    vendorId: "A00002346",
    apiKey: "KFJKD*&8565",
    secretKey: "",
  },
  collectOrderRange: 3,
  shippingMode: "active",
};

export const UNLINK_SELLER_CREDENTIAL = {
  id: 2,
  userId: 6746,
  teamId: 630,
  mall: "cafe24",
  status: "ok",
  integratedAt: "2023-05-25T09:44:33.000Z",
};

export const LINK_SELLER_CREDENTIAL = {
  id: 3,
  userId: 6746,
  teamId: 630,
  mall: "smartStore",
  status: "ok",
  integratedAt: "2023-05-31T02:59:38.943Z",
};

export const UPDATE_SELLER_CREDENTIAL = {
  id: 2,
  userId: 6746,
  teamId: 630,
  mall: "smartStore",
  status: "ok",
  integratedAt: "2023-05-31T02:59:38.943Z",
};

export const GET_SELLER_SKU_LIST: GET_SELLER_SKU_LIST_RES = {
  total: 16,
  meta: {
    lastUpdatedAt: "2023-05-31T02:59:38.943Z",
    updateBlockUntil: "2023-06-01T00:00:00.000Z",
  },
  list: Object.entries(MALL_OPTIONS).reduce(
    (acc, [key, value], index) => [
      ...acc,
      {
        id: index * 2,
        connected: false,
        mall: key,
        productName: `${value}-연동X`,
        optionName: "옵션1",
        productCode: `product-${index * 2}`,
        sellerProductCode: `seller-product-${index * 2}`,
        optionManagementCode: `option-management-${index * 2}`,
        skuId: null,
        barcode: "",
        bofulProductCode: "",
        bofulOptionManagementCode: "",
        integratedAt: "",
      } as SellerSkuItem,
      {
        id: index * 2 + 1,
        connected: true,
        mall: key,
        productName: `${value}-연동O`,
        optionName: "옵션1",
        productCode: `product-${index * 2 + 1}`,
        sellerProductCode: `seller-product-${index * 2 + 1}`,
        optionManagementCode: `option-management-${index * 2 + 1}`,
        skuId: `S${index * 2 + 1}0000000`,
        barcode: `${index * 2 + 1}0000000000`,
        bofulProductCode: `${index * 2 + 1}0000000000`,
        bofulOptionManagementCode: `${index * 2 + 1}0000000000`,
        integratedAt: "2023-06-01T00:00:00.000Z",
      } as SellerSkuItem,
    ],
    [] as SellerSkuItem[]
  ),
};

export const GET_LINKED_SHIPPING_MALL_LIST_TEST: GET_LINKED_SHIPPING_MALL_LIST_RES =
  [
    {
      id: 1,
      mall: "cafe24",
      accountId: "ASDFASDFASDFASDF",
      createdAt: "2022/03/22",
      deletedAt: "2023/03/22",
    },
    {
      id: 2,
      mall: "smartStore",
      accountId: "123123123123123",
      createdAt: "2022/03/22",
    },
    {
      id: 3,
      mall: "auction",
      accountId: "ASDFASDFASDFASDFASDFASDFASDF",
    },
  ];

export const CONFIRM_OAUTH_CAFE24_RESULT: CONFIRM_INLAND_RES = {
  result: 200,
};

// 주문 연동
export const GET_SELLER_ORDER = (
  page: number,
  perPage: number
): GET_SELLER_ORDER_LIST_RES => ({
  total: 30,
  meta: {
    lastUpdatedAt: "2023-05-31T02:59:38.943Z",
  },
  list: (
    [
      ["정상", "미출고", ["출고요청전"]],
      ["정상", "출고", ["요청확인중"]],
      ["정상", "출고", ["작업중"]],
      ["정상", "출고", ["출고마감"]],
      ["정상", "출고", ["취소"]],
      ["정상", "반품", ["접수중"]],
      ["정상", "반품", ["도착전"]],
      ["정상", "반품", ["검수대기중"]],
      ["정상", "반품", ["검수중"]],
      ["정상", "반품", ["검수완료(이슈)"]],
      ["정상", "반품", ["입고중"]],
      ["정상", "반품", ["입고완료"]],
      ["오류", "미출고", ["재고부족"]],
      ["오류", "미출고", ["SKU누락"]],
      ["오류", "미출고", ["주소오류"]],
      ["오류", "미출고", ["재고부족", "주소오류", "SKU누락"]],
      ["정상", "미출고", ["출고요청전"]],
      ["정상", "미출고", ["출고요청전"]],
      ["정상", "미출고", ["출고요청전"]],
      ["정상", "미출고", ["출고요청전"]],
      ["정상", "미출고", ["출고요청전"]],
      ["정상", "미출고", ["출고요청전"]],
      ["정상", "미출고", ["출고요청전"]],
      ["정상", "미출고", ["출고요청전"]],
      ["정상", "미출고", ["출고요청전"]],
      ["정상", "미출고", ["출고요청전"]],
      ["정상", "미출고", ["출고요청전"]],
      ["정상", "미출고", ["출고요청전"]],
      ["정상", "미출고", ["출고요청전"]],
      ["정상", "미출고", ["출고요청전"]],
    ] as [OrderState, OrderDetailState, OrderDetailStateReason[]][]
  )
    .map(([state, detailState, detailStateReason], index) => ({
      id: String(index),
      state,
      detailState,
      detailStateReason,
      orderedDate: new Date("2021-05-31T02:59:38.943Z"),
      shippedDate: new Date("2021-05-31T02:59:38.943Z"),
      mall: "cafe24" as MallOptions,
      orderId: "123456789",
      requestNo: "123456789",
      productName: `${state}-${detailState}-${detailStateReason.join("+")}`,
      hasError: state === "정상" ? false : true,
      orderStatusNames: ["결제완료", "배송중", "배송완료"],
    }))
    .slice(page * perPage, (page + 1) * perPage),
});

export const GET_SELLER_ORDER_ID = (
  id: string
): GET_SELLER_ORDER_DETAIL_RES => {
  const item = GET_SELLER_ORDER(0, Infinity).list.find(
    (item) => item.id === id
  );

  if (!item) throw new Error("Not Found");

  const { state, detailState, mall, orderId, orderedDate, requestNo } = item;

  return {
    state,
    detailState,
    orderedDate,
    shippedDate: new Date("2021-05-31T02:59:38.943Z"),
    mall,
    orderId,
    requestNo,
    receiver: "누군가",
    receiverPhone: "010-1234-5678",
    receiverAddress: "서울시 종로구 새문안로 89 정우빌딩 9층 셀러노트",
    items: (
      [
        "출고요청전",
        "요청확인중",
        "작업중",
        "작업중",
        "출고마감",
        "취소",
        "접수중",
        "도착전",
        "검수대기중",
        "검수중",
        "검수완료(이슈)",
        "입고중",
        "입고완료",
        "재고부족",
        "SKU누락",
        "주소오류",
      ] as OrderDetailStateReason[]
    ).map((item, index) => ({
      detailStateReason: item,
      id: index,
      mall: "cafe24",
      prodOrderNo: "1234567890",
      productName: item,
      optionName: "test",
      productCode: "test",
      sellerProductCode: "test",
      optionManagementCode: "test",
      skuId: item === "SKU누락" ? null : "S1234567890",
      orderCount: 1,
      shippingCount: 1,
      returnCount: 1,
      returnRequestCount: 1,
      hasError: ["재고부족", "SKU누락", "주소오류"].includes(item),
      countryCode: "KR",
      orderStatusName: "결제완료",
    })),
  };
};
