import { useCallback } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Box, Button, SxProps, Theme, Typography } from "@mui/material";
import { List, message, Modal, Select } from "antd";
import { useSetRecoilState } from "recoil";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/ADMIN_BID_QUERY";
import { FORWARDING_INVOICE_ATOMS } from "@sellernote/_shared/src/states/forwarding/invoice";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { ForwardingAdminUserListItem } from "@sellernote/_shared/src/types/forwarding/adminUser";
import { BidStatus } from "@sellernote/_shared/src/types/forwarding/bid";
import { toFormattedDate } from "@sellernote/_shared/src/utils/common/date";
import { BID_STATUS_OPTION_LIST } from "@sellernote/_shared/src/utils/common/options";
import {
  changeBidProjectStatusNameToKr,
  changeExportProjectStatusNameToKr,
  checkHasTrelloCard,
} from "@sellernote/_shared/src/utils/forwarding/bid";
import { checkIsSettlement } from "@sellernote/_shared/src/utils/forwarding/trello";

import Styled from "./index.styles";

const { Option } = Select;
const { confirm } = Modal;

const boxLayoutStyle: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  gap: "4px",
};

const BasicDesc = ({
  bidDetail,
  adminUserList,
}: {
  bidDetail: AdminBidDetail;
  adminUserList: ForwardingAdminUserListItem[];
}) => {
  const setTrelloCardId = useSetRecoilState(
    FORWARDING_INVOICE_ATOMS.ADMIN_TRELLO_CARD_ID
  );

  const history = useHistory();

  const queryClient = useQueryClient();

  const { data: forwardingManagerHistory } =
    ADMIN_BID_QUERY.useGetForwardingManagerHistory({
      teamId: bidDetail.team.id,
      bidId: bidDetail.id,
    });

  const {
    mutate: changeBidDetail,
    ResponseHandler: ResponseHandlerOfChangeBidDetail,
  } = ADMIN_BID_QUERY.useChangeBidDetail(bidDetail.id);

  const {
    mutate: changeExportBidDetail,
    ResponseHandler: ResponseHandlerOfChangeExportBidDetail,
  } = ADMIN_BID_QUERY.useChangeExportBidDetail(bidDetail.id);

  const { mutate: changeBidStatusToWaitingForExporterInfo } =
    ADMIN_BID_QUERY.useChangeBidStatusToWaitingForExporterInfo(bidDetail.id);

  const { mutate: acceptExportShipment } =
    ADMIN_BID_QUERY.useAcceptExportShipment(bidDetail.id);

  const getAdminName = useCallback(
    (adminId: number) => {
      return (
        adminUserList.find((n) => {
          return n.id === adminId;
        })?.name || "-"
      );
    },
    [adminUserList]
  );

  const handleForwardingManagerHistoryModalOpenClick = useCallback(() => {
    Modal.info({
      title: "담당자 변경 기록",
      content: (
        <List
          itemLayout="horizontal"
          dataSource={forwardingManagerHistory}
          renderItem={(item: {
            changedAt: Date;
            forwardingManagerId: number;
          }) => (
            <List.Item>
              <List.Item.Meta
                title={getAdminName(item.forwardingManagerId)}
                description={toFormattedDate(
                  item.changedAt,
                  "YYYY.MM.DD hh:mm:ss a"
                )}
              />
            </List.Item>
          )}
        />
      ),
    });
  }, [forwardingManagerHistory, getAdminName]);

  const openBidStatusToCanceledConfirmModal = useCallback(
    (status: BidStatus) => {
      const responseHandler = {
        onSuccess: () => {
          message.success("의뢰 취소로 변경했습니다.");
          queryClient.invalidateQueries(
            ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
              bidId: bidDetail.id,
            })
          );
        },

        onError: () => {
          message.error("에러가 있습니다 개발자에게 문의해주세요");
        },
      };
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: "이 운임을 완전종료하시겠습니까?",
        onOk() {
          if (bidDetail.isImport) {
            changeBidDetail(
              {
                status,
                projectStatus: "canceled",
              },
              {
                ...responseHandler,
              }
            );
            return;
          }

          changeExportBidDetail(
            {
              status,
              projectStatus: "canceled",
            },
            {
              ...responseHandler,
            }
          );
        },
      });
    },
    [
      bidDetail.id,
      bidDetail.isImport,
      changeBidDetail,
      changeExportBidDetail,
      queryClient,
    ]
  );

  const openBidStatusToWaitingForExporterInfoModal = useCallback(() => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: "제출한 견적서를 수락 처리 하시겠습니까?",
      onOk() {
        changeBidStatusToWaitingForExporterInfo(
          {},
          {
            onSuccess: () => {
              message.success("수출자 정보 입력 대기중으로 변경했습니다.");
              queryClient.invalidateQueries(
                ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
                  bidId: bidDetail.id,
                })
              );
            },

            onError: (error) => {
              if (error.code === "E086") {
                message.error("이미 견적이 수락된 의뢰입니다.");
                return;
              }
              message.error("에러가 있습니다 개발자에게 문의해주세요");
            },
          }
        );
      },
    });
  }, [bidDetail.id, changeBidStatusToWaitingForExporterInfo, queryClient]);

  const openBidStatusToInProgressModal = useCallback(() => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: "제출한 견적서를 수락 처리 하시겠습니까?",
      onOk() {
        acceptExportShipment(
          {},
          {
            onSuccess: () => {
              message.success("의뢰를 진행 중으로 변경했습니다..");
              queryClient.invalidateQueries(
                ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
                  bidId: bidDetail.id,
                })
              );
            },

            onError: () => {
              message.error("에러가 있습니다 개발자에게 문의해주세요");
            },
          }
        );
      },
    });
  }, [acceptExportShipment, bidDetail.id, queryClient]);

  const handleBidStatusChangeSelect = useCallback(
    (status: BidStatus) => {
      if (status === "inProgress") {
        openBidStatusToInProgressModal();
        return;
      }
      if (status === "waitingForExporterInfo") {
        openBidStatusToWaitingForExporterInfoModal();
        return;
      }

      if (status === "finished") {
        openBidStatusToCanceledConfirmModal(status);
        return;
      }

      const responseHandler = {
        onSuccess: () => {
          message.success("의뢰 상태를 변경했습니다.");
          queryClient.invalidateQueries(
            ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
              bidId: bidDetail.id,
            })
          );
        },

        onError: () => {
          message.error("에러가 있습니다 개발자에게 문의해주세요");
        },
      };

      if (bidDetail.isImport) {
        changeBidDetail(
          {
            status,
          },
          {
            ...responseHandler,
          }
        );

        return;
      }

      changeExportBidDetail(
        {
          status,
        },
        {
          ...responseHandler,
        }
      );

      return;
    },
    [
      bidDetail.id,
      bidDetail.isImport,
      changeBidDetail,
      changeExportBidDetail,
      openBidStatusToCanceledConfirmModal,
      openBidStatusToInProgressModal,
      openBidStatusToWaitingForExporterInfoModal,
      queryClient,
    ]
  );

  const handleMoveToTrelloPageClick = () => {
    if (checkHasTrelloCard(bidDetail.projectStatus)) {
      setTrelloCardId(bidDetail.id);

      if (
        checkIsSettlement(bidDetail.projectStatus) &&
        APP_NAME === "shipda-admin"
      ) {
        history.push("/settlement");
        return;
      }

      if (bidDetail.serviceType === "consolidation") {
        history.push("/trello/consolidation");
        return;
      }

      // 수출의뢰 일때는 수출 트렐로로 이동
      if (!bidDetail.isImport) {
        history.push("/trello/export");
        return;
      }

      history.push("/trello/general");
      return;
    }
    message.warning("트렐로 카드가 없는 의뢰입니다.");
    return;
  };

  const handleMoveToUserDetailPageClick = useCallback(() => {
    if (bidDetail.team.isPrivate) {
      history.push(`/users/list/${bidDetail.userId}`);
      return;
    }
    history.push(`/team/${bidDetail.team.id}`);
  }, [bidDetail.team.id, bidDetail.team.isPrivate, bidDetail.userId, history]);

  const getExportShipmentStatusDisabled = useCallback(
    (selectStatus: BidStatus | "all") => {
      const defaultDisabledStatus =
        selectStatus === "committed" ||
        selectStatus === "waiting" ||
        selectStatus === "waitingForExporterInfo";

      if (
        bidDetail.status === "committed" ||
        bidDetail.status === "estimating"
      ) {
        if (defaultDisabledStatus || selectStatus === "inProgress") {
          return true;
        }
        return false;
      }

      if (defaultDisabledStatus) {
        return true;
      }
      return false;
    },
    [bidDetail.status]
  );

  const statusSelectDisabled = useCallback(
    (selectStatus: BidStatus | "all") => {
      const defaultDisabledStatus =
        selectStatus === "committed" ||
        selectStatus === "waiting" ||
        selectStatus === "inProgress";

      if (
        bidDetail.status === "committed" ||
        bidDetail.status === "estimating"
      ) {
        if (
          defaultDisabledStatus ||
          selectStatus === "waitingForExporterInfo"
        ) {
          return true;
        }
        return false;
      }

      if (defaultDisabledStatus) {
        return true;
      }
      return false;
    },
    [bidDetail.status]
  );

  return (
    <Styled.basicDescContainer>
      <Box sx={boxLayoutStyle}>
        <Typography>생성일:</Typography>
        <Typography>
          {new Date(bidDetail.createdAt).toLocaleDateString()}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography>의뢰번호:</Typography>
        <Button variant="text" onClick={handleMoveToTrelloPageClick}>
          {bidDetail.id}
        </Button>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography>회사명:</Typography>
        {bidDetail.team.company ? (
          <Button
            variant="text"
            className="user-company"
            onClick={handleMoveToUserDetailPageClick}
          >
            {bidDetail.team.company}
          </Button>
        ) : (
          <Typography>-</Typography>
        )}
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography>팀명:</Typography>

        {bidDetail?.team.name ? (
          <Button
            variant="text"
            className="user-company"
            onClick={handleMoveToUserDetailPageClick}
          >
            {bidDetail.team.name}
          </Button>
        ) : (
          <Typography>-</Typography>
        )}
      </Box>

      <Box sx={boxLayoutStyle}>
        <Typography>담당자 이름:</Typography>
        <Typography>{bidDetail.user.name}</Typography>
      </Box>

      <Box sx={boxLayoutStyle}>
        <Typography>연락처:</Typography>
        <Typography>{bidDetail.user.phone}</Typography>
      </Box>

      <Box sx={boxLayoutStyle}>
        <Typography>이메일:</Typography>
        <Typography>{bidDetail.user.email}</Typography>
      </Box>

      <Box sx={boxLayoutStyle}>
        <Typography>상태:</Typography>
        <Select
          disabled={APP_NAME === "partner-admin"}
          value={bidDetail.status}
          className="status-select"
          onSelect={handleBidStatusChangeSelect}
        >
          {BID_STATUS_OPTION_LIST.map((v) => {
            return (
              <Option
                disabled={
                  bidDetail.isImport
                    ? statusSelectDisabled(v.value)
                    : getExportShipmentStatusDisabled(v.value)
                }
                hidden={v.value === "all"}
                key={v.value}
                value={v.value}
              >
                {v.label}
              </Option>
            );
          })}
        </Select>
      </Box>

      <Box sx={boxLayoutStyle}>
        <Typography>세부 상태:</Typography>
        <Typography>
          {bidDetail.isImport
            ? changeBidProjectStatusNameToKr(bidDetail.projectStatus) || "-"
            : changeExportProjectStatusNameToKr(bidDetail.projectStatus) || "-"}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography className="forwarding-manager">포워딩 담당자:</Typography>
        <Button
          variant="text"
          onClick={handleForwardingManagerHistoryModalOpenClick}
        >
          {getAdminName(
            !bidDetail.team ? 0 : bidDetail.team.forwardingManagerId
          )}
        </Button>
      </Box>

      <Box sx={boxLayoutStyle}>
        <Typography>서비스 지역:</Typography>
        <Typography>
          {bidDetail.locale === "KR" ? "한국" : "싱가포르"}
        </Typography>
      </Box>

      {ResponseHandlerOfChangeBidDetail}

      {ResponseHandlerOfChangeExportBidDetail}
    </Styled.basicDescContainer>
  );
};

export default BasicDesc;
